import { useState } from "react"
import { ADD_EDIT_SITES, CUSTOMER_ADMIN_SITES, SITE_GROUPS, SITE_TREE } from "src/constants"
import { getRequest, REQUEST_HEADERS } from "src/services"
import { parseSiteData } from "src/services/api/swrHooks/useSites"
import { keysToCamelCase } from "src/utils"
import useSWR from "swr"
import { getExternalApiRdc } from "../Monitor/Alerts/Alerts.service"

export type SiteTreeType = {
  id: string
  name: string
  uniqueName: string
  type: string
  applications: string[]
  children: SiteTreeType[]
  address?: string
  hostsCount?: number
  proxiesCount?: number
  group: boolean
}

export type SiteGroupType = {
  id: string
  parentId: string
  name: string
  type: string
}

type SiteGroupPayloadType = {
  siteGroups: SiteGroupType[] | null
  total: number
  from?: number
  to?: number
}

/**
 * Fetches ZTNA sites data from the server.
 *
 * @param {string} dateTimeFormat - The format to be used for date and time.
 * @returns {Promise<{ sites: any, from: string, to: string, total: number }>} An object containing the parsed sites data, and metadata including the range and total count.
 *
 * @throws Will throw an error if the network request fails.
 */
export const getZtnaSitesData = async (dateTimeFormat: string) => {
  const url = `${CUSTOMER_ADMIN_SITES}?limit=500`

  const response = await getRequest(url)

  const payload = response?.data?.payload

  return {
    sites: parseSiteData(payload?.sites, dateTimeFormat),
    from: payload?.from,
    to: payload?.to,
    total: payload?.total,
  }
}

/**
 * Fetches XIQ sites data from the external API.
 *
 * @param {number | string} [id] - Optional ID to fetch specific site data. If not provided, fetches a list of sites with a limit of 500.
 * @returns {Promise<any>} - A promise that resolves to the JSON response from the API.
 * @throws {Error} - Throws an error if the response is not ok.
 */
export const getXiqSitesData = async (id?: number | string) => {
  const API_URL = getExternalApiRdc("cls")

  const url = `${API_URL}${ADD_EDIT_SITES}${id ? "/" + id : "?limit=500"}`

  const response = await fetch(url, {
    credentials: "include",
    headers: REQUEST_HEADERS,
  })

  if (!response.ok) throw new Error()
  return response.json()
}

/**
 * Fetches the site tree data from the API.
 *
 * This function retrieves the environment details from the session storage
 * and constructs the API URL accordingly. It then makes a fetch request to
 * the constructed URL with the necessary credentials and headers.
 *
 * @returns {Promise<any>} A promise that resolves to the JSON response from the API.
 * @throws {Error} Throws an error if the response is not ok.
 */
export const getSiteTreeData = async () => {
  const envDetails = sessionStorage.getItem("environmentDetails")
  const API_URL = envDetails ? getExternalApiRdc("cls") : ""

  const url = `${API_URL}${SITE_TREE}`

  const response = await fetch(url, {
    credentials: "include",
    headers: REQUEST_HEADERS,
  })

  if (!response.ok) throw new Error()
  return response.json()
}

/**
 * Fetches site groups from the API.
 *
 * @param {number | string} [id] - Optional ID of the site group to fetch. If not provided, fetches a list of site groups with a limit of 100.
 * @returns {Promise<any>} A promise that resolves to the JSON response from the API.
 * @throws {Error} If the response is not ok.
 */
export const getSiteGroups = async (id?: number | string) => {
  const envDetails = sessionStorage.getItem("environmentDetails")
  const API_URL = envDetails ? getExternalApiRdc("cls") : ""

  const url = `${API_URL}${SITE_GROUPS}${id ? "/" + id : "?limit=100"}`

  const response = await fetch(url, {
    credentials: "include",
    headers: REQUEST_HEADERS,
  })

  if (!response.ok) throw new Error()
  return response.json()
}

/**
 * Custom hook to fetch and manage site groups data.
 *
 * @param {Object} options - Options for the hook.
 * @param {boolean} [options.shouldFetch=true] - Flag to determine if data should be fetched.
 * @param {number} [options.refreshInterval=30000] - Interval in milliseconds to refresh the data.
 *
 * @returns {Object} - The hook returns an object containing:
 *   - `data` {SiteGroupPayloadType | undefined}: The fetched site groups data.
 *   - `isLoading` {boolean}: Flag indicating if the data is currently being loaded.
 *   - `error` {any}: Error object if an error occurred during fetching.
 *   - `isValidating` {boolean}: Flag indicating if the data is being revalidated.
 *   - `getSiteGroups` {Function}: Function to manually trigger data revalidation.
 */
export const useSiteGroups = ({ shouldFetch = true, refreshInterval = 30000 }) => {
  const [siteGroupsData, setSiteGroupsData] = useState<SiteGroupPayloadType | undefined>()
  const [error, setError] = useState<any>(null)

  const fetchData = async (swrData: any) => {
    try {
      setSiteGroupsData({
        total: swrData?.pagination?.totalCount,
        siteGroups: swrData?.data ? keysToCamelCase(swrData.data) : undefined,
      })
    } catch (error) {
      setError(error)
    }
  }

  const fetcher = async (url: string, headers: HeadersInit) => {
    const response = await fetch(url, { headers, credentials: "include" })
    if (!response.ok) {
      error.response = response
    }
    return response.json()
  }

  const API_URL = getExternalApiRdc("cls") ?? ""

  const url = `${API_URL}${SITE_GROUPS}?limit=100`

  const {
    error: swrError,
    data: swrData,
    mutate,
    isValidating,
  } = useSWR(shouldFetch ? [url, REQUEST_HEADERS] : null, {
    refreshInterval,
    fetcher,
    onSuccess: (data) => {
      if (!swrError) {
        fetchData(data)
      }
    },
  })

  return {
    data: siteGroupsData,
    isLoading: !swrError && (!siteGroupsData || !swrData),
    error: error || swrError,
    isValidating,
    getSiteGroups: mutate,
  }
}
