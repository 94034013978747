type NetworkDevicesPayloadType = {
  totalPages: number
  number: number
  size: number
  numberOfElements: number
  content: { id: string; hostName: string; ipAddress: string; serialNumber: string; alias: string }[]
}

export const switchesDropdown = (data: NetworkDevicesPayloadType) => {
  return (
    data?.content?.map(({ ipAddress, alias, id }) => ({
      label: ipAddress,
      value: `${id} | ${ipAddress}`,
      id: ipAddress,
      name: ipAddress,
    })) || []
  )
}

export const accessPointDropdown = (data: NetworkDevicesPayloadType) => {
  return (
    data?.content?.map(({ hostName, ipAddress, id }) => ({
      label: ipAddress,
      value: `${id} | ${ipAddress}`,
      id: ipAddress,
      name: ipAddress,
    })) || []
  )
}
