import clsx from "clsx"
import { ENTextarea } from "en-react/dist/src/components/Textarea"
import { ENTextField } from "en-react/dist/src/components/TextField"
import React, { CSSProperties, Fragment, useCallback, useState } from "react"
import { Controller } from "react-hook-form"
import ZtnaIcon from "src/shared/components/Icons"
import CircularLoader from "../../CicularLoader"
import ZtnaTooltip from "../../ZtnaTooltip"
import { useFormComponentsStyles } from "../FormComponents.styles"
import { EnInputType, EnSimpleInputType } from "../FormComponents.types"

const SimpleInput = (props: EnSimpleInputType): JSX.Element => {
  const { name, control, isOptional, isDisabled, onBlur, ...rest } = props

  return (
    <Controller
      control={control}
      name={name}
      render={(props) => (
        <EnInput
          {...rest}
          onChange={(e: any) => {
            props.field.onChange(e?.target?.value)
          }}
          onBlur={(e: any) => {
            props.field.onBlur()
            onBlur?.(e)
          }}
          value={props.field.value}
          isActive={props.field.value !== ""}
          isOptional={isOptional}
          disable={isDisabled}
        />
      )}
    />
  )
}

const EnInput = (props: EnInputType) => {
  const {
    inputId,
    dataTestId,
    value,
    onChange,
    onBlur,
    isActive,
    appendStyles = "",
    label = "",
    placeholder = "",
    isPasswordField,
    error,
    tableError,
    multiline = false,
    hideLabel = false,
    rows = 1,
    disable = false,
    isLoading = false,
    autoComplete = "off",
    isReadOnly,
    preIconName,
    postIconName,
    postIconTooltip,
    postIconTooltipPlacement = "left",
    type,
    units,
    autoFocus,
    onKeydown,
    fieldNote,
    isFullWidth = false,
    isSmall = false,
    isOptional,
    disableResize,
    onKeyUp,
    noLabelOnFocused = false,
    id,
  } = props
  const classes = useFormComponentsStyles()

  const [isVisible, setVisible] = useState(false)
  const [isFocused, setFocused] = useState(autoFocus || false)

  const handleClickShowPassword = useCallback((): void => {
    setVisible(!isVisible)
  }, [isVisible])

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>): void => {
    event.preventDefault()
  }

  return (
    <>
      {multiline ? (
        <ENTextarea
          {...(inputId ? { fieldId: inputId } : {})}
          data-testid={dataTestId}
          isDisabled={disable}
          label={label}
          value={value}
          styleModifier={appendStyles}
          placeholder={placeholder}
          isOptional={isOptional}
          handleOnChange={(e: any) => {
            onChange(e)
          }}
          isActive={isActive !== undefined ? isActive : value !== ""}
          isError={error || tableError}
          errorNote={error ? error.message : tableError}
          isReadonly={isReadOnly}
          isFocused={autoFocus}
          rows={rows}
          fieldNote={fieldNote}
          onBlur={onBlur}
          isDisabledResize={disableResize}
          onKeyUp={onKeyUp}
          onKeyDown={onKeydown}
          id={id}
          hideLabel={hideLabel}
        />
      ) : (
        <ENTextField
          enableSlotAfterClick
          fieldId={inputId}
          data-testid={dataTestId}
          id={inputId}
          isDisabled={disable}
          autoComplete={autoComplete}
          label={noLabelOnFocused && (isFocused || isActive) ? undefined : label}
          isOptional={isOptional}
          autoCorrect="off"
          autoCapitalize="none"
          value={value}
          styleModifier={appendStyles}
          type={type ? type : isVisible || !isPasswordField ? "text" : "password"}
          placeholder={placeholder}
          handleOnChange={(e: any) => {
            onChange(e)
          }}
          onFocus={() => setFocused(true)}
          onBlur={(e) => {
            onBlur(e)
            setFocused(false)
          }}
          onKeyUp={onKeyUp}
          isActive={isActive !== undefined ? isActive : value !== ""}
          isError={error || tableError}
          errorNote={error ? error.message : tableError}
          isReadonly={isReadOnly}
          isFocused={autoFocus}
          onKeyDown={onKeydown}
          fieldNote={fieldNote}
          className={clsx({ [classes.fullWidth]: isFullWidth })}
          style={isSmall ? ({ "--size-base-unit": "0.3rem" } as CSSProperties) : {}}
          hideLabel={hideLabel}
        >
          <span key={1} slot="before" className={classes.prePostIcon}>
            {preIconName && <ZtnaIcon name={preIconName} />}
          </span>
          <span key={2} slot="after" className={classes.prePostIcon}>
            {postIconName && (
              <ZtnaTooltip title={postIconTooltip} placement={postIconTooltipPlacement} cssPosition="absolute">
                <ZtnaIcon name={postIconName} />
              </ZtnaTooltip>
            )}
            {isLoading && <CircularLoader />}
          </span>

          {units && (
            <span key={3} slot="after">
              {units}
            </span>
          )}
          {isPasswordField && (
            <span
              key={4}
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              className={classes.passwordIcon}
              onMouseDown={handleMouseDownPassword}
              slot="after"
            >
              {isVisible ? <ZtnaIcon name="eyeOutlineSlash" /> : <ZtnaIcon name="eyeOutline" />}
            </span>
          )}
        </ENTextField>
      )}
    </>
  )
}

export default SimpleInput
