import { ENTextPassage } from "en-react/dist/src/components/TextPassage"
import RemoveModal from "src/shared/components/RemoveModal"

interface StopPacketCaptureModalProps {
  onModalClose?: () => void
  onConfirmation: () => void
  checkType?: string
}
const StopPacketCaptureModal: React.FC<StopPacketCaptureModalProps> = ({ onModalClose, onConfirmation }) => {
  return (
    <RemoveModal
      size="sm"
      title="Packet Capture"
      onOk={() => {
        onModalClose && onModalClose()
        onConfirmation()
      }}
      onCancel={() => {
        onModalClose && onModalClose()
      }}
      onOkProps={{
        title: "Confirm",
        buttonType: "primary",
      }}
    >
      <ENTextPassage>Are you sure you want to stop Packet Capture?</ENTextPassage>
    </RemoveModal>
  )
}

export default StopPacketCaptureModal
