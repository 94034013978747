import { TemplateResult, unsafeCSS } from 'lit';
import { property, state } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';
import { ifDefined } from 'lit/directives/if-defined.js';
import { repeat } from 'lit/directives/repeat.js';
import { html, unsafeStatic } from 'lit/static-html.js';
import register from '../../directives/register';
import PackageJson from '../../package.json';
import { ENElement } from '../ENElement';
import { ENButton } from '../button/button';
import { ENCheckboxItem } from '../checkbox-item/checkbox-item';
import { ENIconBuilding } from '../icon/icons/building';
import { ENIconBuilding2 } from '../icon/icons/building-2';
import { ENIconChevronDown } from '../icon/icons/chevron-down';
import { ENIconChevronRight } from '../icon/icons/chevron-right';
import { ENIconFolder } from '../icon/icons/folder';
import { ENIconFolder2 } from '../icon/icons/folder-2';
import { ENIconLayers } from '../icon/icons/layers';
import { ENIconLocationPin } from '../icon/icons/location-pin';
import styles from './tree-checkbox.scss';

export type Icon = 'folder' | 'location-pin' | 'building' | 'folder-2' | 'layers' | 'building-2';

export interface CheckboxItem {
  // label must not contain '_'. If for some reason '_' is required in label then must set key WITHOUT '_'.
  label: string;
  // key CANNOT not contain '_'. Otherwise it will break logic
  key?: string;
  icon?: Icon;
  readonly?: boolean;
  helpText?: string;
  type?: 'SELECT_ALL' | undefined;
  disabled?: boolean;
  defaultExpanded?: boolean;
  defaultChecked?: boolean;
  // for internal use only
  touched?: boolean;
  children?: CheckboxItem[];
  // Set this if same label repeat itself in another hierarchy
  doesThisLabelExistInAnotherHierarchy?: boolean | undefined;
}

/**
 * Checkbox Status
 * 0 - when checkbox is unchecked
 * 1 - when checkbox is checked
 * 2 - when some items inside parent are checked and some are unchecked i.e indeterminate
 */
export enum CheckboxStatus {
  UNCHECKED = 0,
  CHECKED = 1,
  INDETERMINATE = 2
}

/**
 * Component: en-tree-checkbox
 * @slot - The components content
 */
export class ENTreeCheckbox extends ENElement {
  static el = 'en-tree-checkbox';

  private elementMap = register({
    elements: [
      [ENCheckboxItem.el, ENCheckboxItem],
      [ENButton.el, ENButton],
      [ENIconChevronRight.el, ENIconChevronRight],
      [ENIconChevronDown.el, ENIconChevronDown],
      [ENIconFolder.el, ENIconFolder],
      [ENIconFolder2.el, ENIconFolder2],
      [ENIconLocationPin.el, ENIconLocationPin],
      [ENIconBuilding.el, ENIconBuilding],
      [ENIconBuilding2.el, ENIconBuilding2],
      [ENIconLayers.el, ENIconLayers]
    ],
    suffix: (globalThis as any).enAutoRegistry === true ? '' : PackageJson.version
  });

  private checkboxItemEl = unsafeStatic(this.elementMap.get(ENCheckboxItem.el));
  private buttonEl = unsafeStatic(this.elementMap.get(ENButton.el));
  private chevronRightEl = unsafeStatic(this.elementMap.get(ENIconChevronRight.el));
  private chevronDownEl = unsafeStatic(this.elementMap.get(ENIconChevronDown.el));
  private folderEl = unsafeStatic(this.elementMap.get(ENIconFolder.el));
  private locationPinEl = unsafeStatic(this.elementMap.get(ENIconLocationPin.el));
  private folder2El = unsafeStatic(this.elementMap.get(ENIconFolder2.el));
  private buildingEl = unsafeStatic(this.elementMap.get(ENIconBuilding.el));
  private building2El = unsafeStatic(this.elementMap.get(ENIconBuilding2.el));
  private layersEl = unsafeStatic(this.elementMap.get(ENIconLayers.el));

  static get styles() {
    return unsafeCSS(styles.toString());
  }

  /**
   * checkboxItems
   * It contains hierarchy of data has to be shown in tree.
   * For EC1 Developer: Keep its initial value same as _checkboxItems state.
   * checkbox items should have UNIQUE LABELS or if label repeats then key should be unique. Type definition is as follow:
      <pre>
      Array<{
        // label must not contain '_'. If for some reason '_' is required in label then must set key WITHOUT '_'.
        label: string;
        // key CANNOT not contain '_'. Otherwise it will break logic
        key?: string;
        icon?: Icon;
        readonly?: boolean;
        type?: 'SELECT_ALL' | undefined;
        defaultExpanded?: boolean;
        defaultChecked?: boolean;
        children?: CheckboxItem[];
      }>
      </pre>
   */
  @property({ type: Array })
  checkboxItems?: Array<CheckboxItem> = [];

  /**
   * If true, then checkbox view will be shown otherwise list view will be shown
   */
  @property({ type: Boolean })
  listView?: boolean = false;

  /**
   * Tree checkbox allows to select multiple items. But if this property is set to true, then at a time only single item can be selected and
   * on clicking on parent, child items will not be selected. Default is false. It should be used with list view.
   * If this is enabled, then you can set max 1 defaultChecked and only on child item. If you do it some other way in that case it may not work correctly.
   */
  @property({ type: Boolean })
  enableSingleSelect?: boolean = false;

  /**
   * If true, then parent can also be selected. This property is relevant only for list view. Default is false.
   */
  @property({ type: Boolean })
  isParentInListSelectable?: boolean = false;

  /**
   * Default is false.
   * If true then selecting child will not select parent. Neither it will set indeterminate state nor checked state on parent if child is selected
   * However if parent is selected then all child in it will be selected.
   */
  @property({ type: Boolean })
  disableParentSelectionOnChildSelection?: boolean = false;

  /**
   * enableSelectAll
   * If set to true, then Select All functionality will be enabled, otherwise disabled. Default is true. Use it with care only where it is required.
   */
  @property({ type: Boolean })
  enableSelectAll?: boolean = true;

  /**
   * selectAllLabel
   * Default is (Select All). Using this property you can customize it.
   */
  @property()
  selectAllLabel?: string = '(Select All)';

  /**
   * selectAllKey
   * Default is (Select All). Key is used to uniquly identify checkbox item.
   */
  @property()
  selectAllKey?: string = '(Select All)';

  /**
   * selectAllIcon
   * Default is undefined. Using this property you can add icon on select all
   */
  @property({ type: String })
  selectAllIcon?: Icon;

  /**
   * selectAllDefaultExpanded
   * Default is true. Using this property make select All default expanded or collapsed.
   */
  @property({ type: Boolean })
  selectAllDefaultExpanded?: boolean = true;

  /**
   * If this property would be enabled, then selecting parent will not select its all child and selecting all child of parent will not select parent.
   * selectAll if enabled will work as it is even with this property.
   */
  @property({ type: Boolean })
  doNotApplyParentChildSelection?: boolean = false;

  /**
   * size
   * - Default size is 16px text and 40px checkbox width/height
   * - **sm** renders a smaller size (14px text and 30px checkbox width/height) than default (16px text and 40px checkbox width/height)
   */
  @property()
  size?: 'sm' | 'md' = 'sm';

  /**
   * Dropdown Version
   * **v1** In this dropdown will be shown so that selected values chips will be shown in textfield, search will have search box with borders and selected chips will not be shown in dropdown.
   * **v2** In this dropdown will be shown so that text field will contain selected values count, selected chips will be shown in droddown, search look and feel will be inline without search box borders.
   */
  @property()
  dropdownVersion?: 'v1' | 'v2' = 'v1';

  /**
   * If true enables lazy loading, i.e data will be loaded lazily as user scroll down. Default is false
   */
  @property({ type: Boolean })
  enableLazyLoading?: boolean = false;

  /* If true enable loader on lazy loading. Default is true. */
  @property({ type: Boolean })
  showLoaderOnLazyLoading?: boolean = true;

  /**
   * Set this property to false to show large loader. Default is true
   */
  @property({ type: Boolean })
  showSmallLoader?: boolean = true;

  /**
   * Set this property for showing loader in light theme. Default is false.
   */
  @property({ type: Boolean })
  showInvertedLoader?: boolean = false;

  /**
   * Expects asynchronous function if `enableLazyLoading` is set that
   * - Receive page number
   * - Calls API
   * - Returns data in shape of Array<CheckboxItem>
   * Default is null.
   */
  @property()
  lazyLoadingService: (page: number) => Promise<Array<CheckboxItem>> = null;

  /**
   * A seperate state for checkboxItems created despite checkboxItems property so as to maintain immutability of checkboxitem property.
   * Keep its initial value same as checkboxItems.
   */
  @state()
  private _checkboxItems?: Array<CheckboxItem> = [];

  /**
   * _activeAccordionId controls accordion arrow icon
   * If id is inside _activeAccordionId then expanded icon is shown otherwise collapsed icon is shown
   */
  @state()
  private _activeAccordionId?: Array<string> = [];

  /**
   * Tree HTML. Rather directly calling function renderTree everytime in render, it is called only when required and value assigned to this property.
   * Aim of this property is to optimize the no of renders.
   */
  @state()
  private _treeHtml?: TemplateResult = html``;

  /**
   * This object contains parent child checkbox status relation. It structure example is:
   * {parent: {
   *    parent1: {
   *      parent2:{
   *        child2: CheckboxStatus.UNCHECKED,
   *        child3: CheckboxStatus.CHECKED
   *      },
   *      child1:CheckboxStatus.CHECKED
   *    }
   * }
   */
  @state()
  private _parentChildCheckedStatus?: { [parentId: string]: Object | CheckboxStatus } = {};

  @state()
  private _parentCheckboxElementStatus?: { [key: string]: CheckboxStatus } = {};

  /**
   * This property aim is to save processing time. It save checkbox item label and its processed id form.
   * If that label again requires processed id then instead of running processing again, we get it from this object.
   */
  private _idMappings?: { [key: string]: string } = {};

  /**
   * Temporary storage to save active accordion ids. Use to revert back in case of RESET or when search is removed
   * in dropdown panel web component
   */
  private _resetActiveAccordionIds?: Array<string> = undefined;

  /**
   * Save searched checkbox items. This search is implemented in tree-dropdown-panel
   */
  private _searchCheckboxItems?: Array<CheckboxItem>;

  /**
   * In case of single select, store last checked item id.
   */
  private _lastCheckedItemId?: string;

  /**
   * In case of single select, store last checked item id for reset purpose.
   */
  private _lastResetCheckedItemId?: string;

  /**
   * This function recursively traverse _parentChildCheckedStatus in order to find its checked status.
   * 1. If checkboxItemId available directly in statusOb and is not object (Child node)
   * 2. If checkboxItemId either not available directly in statusOb or if available then is object.
   * 3. If checkboxItemId either not available directly in statusOb, then traverse statusOb and for every value that is object, search recursively checkboxItemId in it and then either run 1 or 4 point.
   * 4. If checkboxItemId available directly in statusOb, but is object, then find the status of all its child and union status based on conditions applied in 5th point.
   *
   * @param checkboxItemId Id of node whose check status has to be queried.
   * @param statusOb In very first pass it will be _parentChildCheckedStatus. In recursive pass it may shrink to subset of it.
   * @returns
   */
  private _getCheckedStatus = (checkboxItemId: string, statusOb = this._parentChildCheckedStatus): CheckboxStatus | undefined => {
    statusOb = { ...statusOb };
    const isSelectAllCheckbox = this.getValidIdString(`id_${this.selectAllKey}`) === checkboxItemId;
    if (typeof statusOb?.[checkboxItemId] !== 'object' && statusOb?.[checkboxItemId] !== undefined) {
      /* 1 */
      return statusOb?.[checkboxItemId] as CheckboxStatus;
    } else {
      /* 2 */
      let newCheckStatusOb = statusOb[checkboxItemId] as Object;
      if (newCheckStatusOb === undefined) {
        /* 3 */
        let status = undefined;
        for (const itemId in statusOb) {
          if (typeof statusOb[itemId] === 'object') {
            status = this._getCheckedStatus(checkboxItemId, statusOb[itemId] as any);
          }
          if (status !== undefined) {
            break;
          }
        }
        return status;
      } else {
        /* 4 */
        newCheckStatusOb = { ...newCheckStatusOb };
        const status = [];
        for (const itemId in newCheckStatusOb) {
          status.push(this._getCheckedStatus(itemId, newCheckStatusOb as any));
        }
        /* 5 */
        if (status.includes(CheckboxStatus.INDETERMINATE) || (status.includes(CheckboxStatus.UNCHECKED) && status.includes(CheckboxStatus.CHECKED)))
          return this.doNotApplyParentChildSelection && isSelectAllCheckbox ? CheckboxStatus.UNCHECKED : CheckboxStatus.INDETERMINATE;
        if (status.includes(CheckboxStatus.CHECKED)) return CheckboxStatus.CHECKED;
        return CheckboxStatus.UNCHECKED;
      }
    }
  };

  /**
   * Use to set initial checked status or when `this.checkboxItems` is changed. It is setting status taking in consideration following values:
   * - defaultChecked
   * - Already set status in _parentChildCheckedStatus state
   * 1. Parent Checkbox Logic
   * 2. Child Checkbox Logic
   * 3. Is Parent Checked
   * This function is responsible to provide initial structure or value to _parentChildCheckedStatus state.
   * @param checkboxItems
   * @param isParentChecked
   * @returns
   */
  private _setParentChildCheckedStatus = (checkboxItems?: Array<CheckboxItem>, isParentChecked: boolean = false) => {
    let result = {};
    for (const checkboxItem of checkboxItems) {
      if (checkboxItem.children?.length > 0) {
        /* 1 */
        const parentId = this.getValidIdString(`id_${checkboxItem.key}`);
        let isCheckboxChecked = checkboxItem.defaultChecked || isParentChecked;
        if (this.doNotApplyParentChildSelection) {
          this._parentCheckboxElementStatus[parentId] = checkboxItem.defaultChecked ? CheckboxStatus.CHECKED : CheckboxStatus.UNCHECKED;
        } else {
          this._parentCheckboxElementStatus[parentId] = isCheckboxChecked ? CheckboxStatus.CHECKED : CheckboxStatus.UNCHECKED;
        }
        const checkStatus = this._getCheckedStatus(parentId);
        if (checkStatus !== undefined) {
          isCheckboxChecked = checkStatus === CheckboxStatus.CHECKED ? true : false;
        }
        result = { ...result, [parentId]: this._setParentChildCheckedStatus(checkboxItem.children, isCheckboxChecked) };
      } else {
        /* 2 */
        const childId = this.getValidIdString(`id_${checkboxItem.key}`);
        const checkStatus = this._getCheckedStatus(childId);
        const finalStatus =
          !this.enableSingleSelect && !this.doNotApplyParentChildSelection && isParentChecked
            ? CheckboxStatus.CHECKED
            : checkStatus === undefined
              ? checkboxItem.defaultChecked === true
                ? CheckboxStatus.CHECKED
                : CheckboxStatus.UNCHECKED
              : checkStatus;
        if (finalStatus === CheckboxStatus.CHECKED && this.enableSingleSelect && !this._lastCheckedItemId) {
          this._lastCheckedItemId = childId;
          this._lastResetCheckedItemId = childId;
        }
        // this._parentCheckboxElementStatus[childId] = finalStatus;
        result = { ...result, [childId]: finalStatus };
      }
    }
    return result;
  };

  /**
   * Call this when you require to update checkbox items all from scratch. If you will not call this and provide
   * new instance of checkbox items, then still it will remember old selections. In order to remove old selections call this function.
   */
  public resetCheckedStatus = () => {
    this._parentChildCheckedStatus = {};
    if (this.enableSingleSelect) {
      this._lastCheckedItemId = this._lastResetCheckedItemId;
    }
  };

  /**
   * Public method to update tree according to search done by user. This method is called by tree-dropdown-panel component.
   * If nothing is passed in function, then it render tree considering original checkboxItems passed by user, otherwise
   * it render searched or filtered result.
   * 1. If searched results are passed
   * 2. Handle case if selectAll is enabled. In searched results passed to this function we r not expecting selectAll checkbox item.
   * 3. After updating tree, wait for render to complete and then register accordion elements with click event. This is done so that no node is attached to multiple click events.
   * 4. If nothing is passed in function
   * @param checkboxItems
   */
  public setSearchCheckboxItems = async (checkboxItems: Array<CheckboxItem> = undefined) => {
    if (checkboxItems) {
      /* 1 */
      this._searchCheckboxItems = JSON.parse(JSON.stringify(checkboxItems));
      if (this.enableSelectAll) {
        /* 2 */
        if (this._searchCheckboxItems.length > 1 || !this._searchCheckboxItems[0]?.type) {
          this._searchCheckboxItems = [
            {
              label: this.selectAllLabel,
              key: this.selectAllKey,
              icon: this.selectAllIcon,
              type: 'SELECT_ALL',
              defaultExpanded: this.selectAllDefaultExpanded,
              children: [...this._searchCheckboxItems]
            }
          ];
        }
      }
      this._treeHtml = this._renderTree(this._searchCheckboxItems);
      /* 3 */
      await this.updateComplete;
      setTimeout(() => {
        this._registerEventListenerOnAccordionItems();
        this.dispatch({ eventName: 'updateComplete', detailObj: { checkedStatus: this._parentChildCheckedStatus } });
      }, 0);
    } else {
      /* 4 */
      this._searchCheckboxItems = undefined;
      this._treeHtml = this._renderTree(this._checkboxItems);
      /* 3 */
      await this.updateComplete;
      setTimeout(() => {
        this._registerEventListenerOnAccordionItems();
        this.dispatch({ eventName: 'updateComplete', detailObj: { checkedStatus: this._parentChildCheckedStatus } });
      }, 0);
    }
  };

  /**
   * Public method to update _activeAccordionId. This state controls that which node should be expanded and which not.
   * @param addIds Ids required to add in array
   * @param removeIds Ids required to remove from array
   */
  public addAndRemoveActiveAccordionItems = (addIds: Array<string> = [], removeIds: Array<string> = []) => {
    if (!this._resetActiveAccordionIds) {
      this._resetActiveAccordionIds = [...this._activeAccordionId];
    }
    const newValue = this._activeAccordionId.filter((id) => !removeIds.includes(id));
    this._activeAccordionId = [...new Set([...newValue, ...addIds])];
  };

  /**
   * If we are adding or removing active accordion ids, then in that case we are saving old value in _resetActiveAccordionIds
   * and when user required, they can call this method to get old values again.
   * NOTE: This function has nothing to do with "Reset" functionality implemented in tree-dropdown-panel.
   */
  public resetActiveAccordionIds = () => {
    if (!!this._resetActiveAccordionIds) {
      this._activeAccordionId = [...this._resetActiveAccordionIds];
      this._resetActiveAccordionIds = undefined;
    }
  };

  /**
   * Search item recursivly and set its status in _parentChildCheckedStatus
   * @param searchId id of checkbox whose status has to be set
   * @param checkedStatus status has to be set
   * @param instanceOb
   */
  private _searchRecursivlyItemAndSetStatus(
    searchId: string,
    checkedStatus: CheckboxStatus,
    instanceOb: { [key: string]: CheckboxStatus | Object } = this._parentChildCheckedStatus
  ) {
    if (searchId in instanceOb) {
      instanceOb[searchId] = checkedStatus;
    } else {
      for (const id in instanceOb) {
        if (typeof instanceOb[id] === 'object') {
          this._searchRecursivlyItemAndSetStatus(searchId, checkedStatus, instanceOb[id] as any);
        }
      }
    }
  }

  private _setAllItemsUnchecked = (instanceOb: { [key: string]: CheckboxStatus | Object }) => {
    for (const id in instanceOb) {
      if (typeof instanceOb[id] === 'object') {
        this._setAllItemsUnchecked(instanceOb[id] as any);
      } else {
        instanceOb[id] = CheckboxStatus.UNCHECKED;
      }
    }
  };

  public uncheckAll = () => {
    this._setAllItemsUnchecked(this._parentChildCheckedStatus);
    for (const id in this._parentCheckboxElementStatus) {
      this._parentCheckboxElementStatus[id] = CheckboxStatus.UNCHECKED;
    }
    this._parentChildCheckedStatus = { ...this._parentChildCheckedStatus };
  };

  /**
   * Public function to uncheck item by its label
   * @param label
   */
  public uncheckItem = (key: string, isId: boolean = false) => {
    const id = isId ? key : this.getValidIdString(`id_${key}`);
    let recursiveSearch = true;
    if (id in this._parentCheckboxElementStatus) {
      this._parentCheckboxElementStatus[id] = CheckboxStatus.UNCHECKED;
      if (this.doNotApplyParentChildSelection) {
        recursiveSearch = false;
      }
    }
    if (recursiveSearch) {
      this._searchRecursivlyItemAndSetStatus(id, CheckboxStatus.UNCHECKED, this._parentChildCheckedStatus);
    }
    this._parentChildCheckedStatus = { ...this._parentChildCheckedStatus };
  };

  /**
   * key is used to uniquely identify checkbox items. It is specifically needed where 2 checkbox item has same label.
   * For that case those checkbox item must have different key. If no key is provided then key will be considered equal to label
   * @param checkboxItems
   */
  private _setKeyOfCheckboxItems = (checkboxItems: Array<CheckboxItem>) => {
    for (const item of checkboxItems) {
      if (!item.key) {
        item.key = item.label;
      }
      if (item.children) {
        this._setKeyOfCheckboxItems(item.children);
      }
    }
  };

  /**
   * _updateHierarchyOnSelectAllChange
   * 1. If selectAll is enabled and either `checkboxItems` property passed by user has more than 1 item or Ist item is not SELECT ALL Item, then update `_checkboxItems` state according to SELECT ALL hierarchy.
   * 2. If selectAll is disabled and `_checkboxItems` structure is according to SELECT ALL hierarchy, then convert it back to normal hierarchy.
   * 3. Update intial check status or whenever `this.checkboxItems` property changes.
   * 4. Saved HTML seperatly and then render to optimize no of renders.
   * 5. Register accordion click listener
   */
  private async _updateHierarchyOnSelectAllChange() {
    if (this.enableSelectAll) {
      /* 1 */
      if (this.checkboxItems.length > 1 || !this.checkboxItems[0]?.type) {
        this._checkboxItems = [
          {
            label: this.selectAllLabel,
            key: this.selectAllKey,
            icon: this.selectAllIcon,
            type: 'SELECT_ALL',
            defaultExpanded: this.selectAllDefaultExpanded,
            children: [...this.checkboxItems]
          }
        ];
      }
    } else {
      /* 2 */
      if (this._checkboxItems.length === 1 && this._checkboxItems[0].type === 'SELECT_ALL') {
        this._checkboxItems = this._checkboxItems[0]?.children || [];
      } else {
        this._checkboxItems = this.checkboxItems;
      }
    }
    this._setKeyOfCheckboxItems(this._checkboxItems);
    /* 3 */
    const firstCheckboxItem = this._checkboxItems[0];
    let isFirstCheckboxChecked = false;
    if (firstCheckboxItem) {
      const firstCheckboxId = this.getValidIdString(`id_${firstCheckboxItem.key}`);
      isFirstCheckboxChecked = firstCheckboxItem.defaultChecked || false;
      const checkStatus = this._getCheckedStatus(firstCheckboxId);
      if (checkStatus !== undefined) {
        isFirstCheckboxChecked = checkStatus === CheckboxStatus.CHECKED ? true : false;
      }
    }
    this._parentChildCheckedStatus = this._setParentChildCheckedStatus(this._checkboxItems, this.enableSelectAll ? isFirstCheckboxChecked : false);
    /* 4 */
    this._treeHtml = this._renderTree(this._checkboxItems);
    await this.updateComplete;
    setTimeout(() => {
      /* 5 */
      this._registerEventListenerOnAccordionItems();
      this.dispatch({ eventName: 'updateComplete', detailObj: { checkedStatus: this._parentChildCheckedStatus } });
    }, 0);
  }

  /**
   * Expand/Collapse panel and attach necessary classes with accordion element
   * 1. To prevent running accordion event handler in case checkbox is clicked.
   * 2. Toggle 'en-c-active' class upon accordion click
   * 3. Update _activeAccordionId based on selection. _activeAccordionId controls accordion arrow icon
   * If id is inside _activeAccordionId then expanded icon is shown otherwise collapsed icon is shown
   * @param evt Cick Event
   */
  private _accordionClickEventListener = (evt: Event) => {
    const accordion = evt.target as HTMLElement;
    if (!accordion.classList.contains('en-c-tree-checkbox__accordion')) {
      /* 1 */
      return false;
    }
    const id: string = accordion.getAttribute('id');
    this._activeAccordionId = [...new Set(this._activeAccordionId)];
    const idIndex = this._activeAccordionId.findIndex((item: string) => item === id);
    if (idIndex > -1) {
      /* 3 */
      this._activeAccordionId.splice(idIndex, 1);
      this._activeAccordionId = [...this._activeAccordionId];
    } else {
      this._activeAccordionId = [...new Set([...this._activeAccordionId, id])];
    }
  };

  /**
   * Get all accordion clickable elements and attach _accordionClickEventListener with them ensuring that event listener attached once only in case of multiple update
   * 1. Remove listener so as to ensure listener attached once only.
   * 2. Attach listener
   */
  private _registerEventListenerOnAccordionItems() {
    const accordionElements = this.shadowRoot.querySelectorAll('.en-c-tree-checkbox__accordion');
    for (const element of accordionElements) {
      /* 1 */
      element.removeEventListener('click', this._accordionClickEventListener);
      /* 2 */
      element.addEventListener('click', this._accordionClickEventListener);
    }
  }

  /**
   * Handle click event when accordion button clicked. It trigger accordion click handler.
   * @param evt Event
   * @param id Accordion Element id
   */
  private _handleAccordionButtonClick = (evt: Event, id: string) => {
    evt.stopPropagation();
    const accordionElement = this.shadowRoot.querySelector(`#${id}`) as HTMLElement;
    accordionElement?.click();
  };

  /**
   * As we are allowing user to set defaultExpanded so this function recursivly handles this property for all checkbox item
   * @param checkboxItems
   * @param checkedStatus
   */
  private _handleDefaultExpanded = (checkboxItems: Array<CheckboxItem>, checkedStatus: boolean = undefined) => {
    for (const item of checkboxItems) {
      if (item.defaultChecked && !!item.children) {
        this._handleDefaultExpanded(item.children, true);
      } else {
        if (checkedStatus === true) {
          item.defaultChecked = checkedStatus;
          if (!!item.children) {
            this._handleDefaultExpanded(item.children, true);
          }
        } else if (!!item.children) {
          this._handleDefaultExpanded(item.children, undefined);
        }
      }
    }
  };

  /**
   * Updated lifecycle
   * 1. Iterates over the changed properties of the component after an update.
   * 2. Checks if the changed property is 'enableSelectAll' and it has been modified and if true then update checkboxItems state hierarchy
   * 3. Checks if the changed property is 'checkboxItems' and it has been modified and if true then update checkboxItems state hierarchy
   * 4. If `listView` or `enableSingleSelect` is set then, set enableSelectAll to false irrespective of what set by user.
   * @param changedProperties - A map of changed properties in the component after an update.
   */
  updated(changedProperties: Map<string, unknown>) {
    let callUpdateHierarchy = false;
    /* 1 */
    changedProperties.forEach((oldValue, propName) => {
      if (propName === 'listView' && this.listView !== oldValue && this.listView) {
        /* 4 */
        this.enableSelectAll = false;
        callUpdateHierarchy = true;
      } else if (propName === 'enableSingleSelect' && this.enableSingleSelect !== oldValue && this.enableSingleSelect) {
        /* 4 */
        this.enableSelectAll = false;
        callUpdateHierarchy = true;
      } else if (propName === 'enableSelectAll' && this.enableSelectAll !== oldValue) {
        /* 2 */
        callUpdateHierarchy = true;
      } else if (propName === 'checkboxItems' && this.checkboxItems !== oldValue) {
        /* 3 */
        callUpdateHierarchy = true;
      }
    });
    if (callUpdateHierarchy) {
      this._updateHierarchyOnSelectAllChange();
    }
  }

  /**
   * Return icon size based on checkbox item size
   * @returns Icon size
   */
  private _getCheckboxItemIconSize() {
    if (this.size === 'sm') return 'md';
    if (this.size === 'md') return 'lg';
    return '';
  }

  /**
   * Get icon HTML based on icon passed in checkbox item
   * @param icon Icon string passed in checkboxItems object
   * @returns Icon HTML
   */
  private _getIconHtml(icon: Icon) {
    switch (icon) {
      case 'folder': {
        return html`<${this.folderEl} slot="prefix-icon" size="${this._getCheckboxItemIconSize()}"></${this.folderEl}>`;
      }
      case 'folder-2': {
        return html`<${this.folder2El} slot="prefix-icon" size="${this._getCheckboxItemIconSize()}"></${this.folder2El}>`;
      }
      case 'location-pin': {
        return html`<${this.locationPinEl} slot="prefix-icon" size="${this._getCheckboxItemIconSize()}"></${this.locationPinEl}>`;
      }
      case 'building': {
        return html`<${this.buildingEl} slot="prefix-icon" size="${this._getCheckboxItemIconSize()}"></${this.buildingEl}>`;
      }
      case 'building-2': {
        return html`<${this.building2El} slot="prefix-icon" size="${this._getCheckboxItemIconSize()}"></${this.building2El}>`;
      }
      case 'layers': {
        return html`<${this.layersEl} slot="prefix-icon" size="${this._getCheckboxItemIconSize()}"></${this.layersEl}>`;
      }
      default: {
        return html``;
      }
    }
  }

  /**
   * Return valid id string removing all special characters
   * 1. This check prevent loop executing for ids which are already processed. Already processed ids are returned directly.
   * @param id Id provided by user. It may contain special characters as well.
   * @returns Valid id string
   */
  public getValidIdString(id: string) {
    if (id in this._idMappings) {
      /* 1 */
      return this._idMappings[id];
    }
    let validId = '';
    for (let character of id) {
      if (/^[a-z0-9_-]+$/i.test(character)) {
        validId += character;
      }
    }
    this._idMappings[id] = validId;
    return validId;
  }

  /**
   * If parent node is checked or unchecked, then all child and descendents have to be checked or unchecked. This function is implementing it.
   * 1. Flow for child node
   * 2. Flow for parent node
   * @param statusOb status object subset of `this._parentChildCheckedStatus`
   * @param status Boolean value of status
   */
  private _updateChildStatusRecursively = (statusOb: any, status: boolean = false) => {
    if (!!statusOb && typeof statusOb === 'object') {
      for (const key in statusOb) {
        if (typeof statusOb[key] !== 'object') {
          /* 1 */
          statusOb[key] = status === false ? CheckboxStatus.UNCHECKED : CheckboxStatus.CHECKED;
        } else {
          /* 2 */
          this._parentCheckboxElementStatus[key] = !!status ? CheckboxStatus.CHECKED : CheckboxStatus.UNCHECKED;
          this._updateChildStatusRecursively(statusOb[key], status);
        }
      }
    }
  };

  /**
   * Handle change in parent item checkbox change
   * 1. Ids on checkbox item are framed as id_parent1_parent2_.. where parent1, parent2 are checkbox item processed labels.
   * These labels are used to generate ids and get checked status from __parentChildCheckedStatus.
   * 2. Assigning new reference so as to update component HTML
   * @param evt
   */
  private _handleCheckboxItemParentChange = (evt: CustomEvent, checkboxItemOb: CheckboxItem) => {
    if (this.enableSingleSelect && !this.isParentInListSelectable) {
      return false;
    }
    const checkboxItem = evt.target as HTMLElement;
    const isSelectAllCheckbox = checkboxItemOb.key === this.selectAllKey;
    const statusId = checkboxItem.getAttribute('data-statusid');
    if (checkboxItem) {
      const id = `${checkboxItem.getAttribute('id')}`;
      let splitId = id.split('_');
      if (this.enableSingleSelect) {
        if (this._lastCheckedItemId) {
          this.uncheckItem(this._lastCheckedItemId, true);
        }
        const keyId = this.getValidIdString(`id_${splitId[splitId.length - 1]}`);
        if (!this._lastCheckedItemId) {
          this._lastResetCheckedItemId = keyId;
        }
        this._lastCheckedItemId = keyId;
      }
      const { checked } = evt.detail;
      this._parentCheckboxElementStatus[statusId] = checked ? CheckboxStatus.CHECKED : CheckboxStatus.UNCHECKED;
      if (!!splitId && splitId.length > 1) {
        let instanceOb: any = this._parentChildCheckedStatus;
        for (let ind = 1; ind < splitId.length - 1; ++ind) {
          instanceOb = instanceOb[this.getValidIdString(`id_${splitId[ind]}`)];
          if (!instanceOb) break;
        }
        if (instanceOb && (!this.doNotApplyParentChildSelection || isSelectAllCheckbox)) {
          if (!this.enableSingleSelect) {
            this._updateChildStatusRecursively(instanceOb[this.getValidIdString(`id_${splitId[splitId.length - 1]}`)], checked);
            this._parentChildCheckedStatus = { ...this._parentChildCheckedStatus };
            setTimeout(() => {
              this._parentChildCheckedStatus = { ...this._parentChildCheckedStatus };
            }, 10);
          }
          this.dispatch({
            eventName: 'parentItemClicked',
            detailObj: {
              label: `${checkboxItem.textContent}`.replace(/\s+/g, ' ').trim(),
              checked: checked,
              checkboxItem: checkboxItem,
              checkboxItemOb: checkboxItemOb,
              selectAllCheckbox: isSelectAllCheckbox
            }
          });
        } else {
          setTimeout(() => {
            this._parentChildCheckedStatus = { ...this._parentChildCheckedStatus };
          }, 10);
          this.dispatch({
            eventName: 'parentItemClicked',
            detailObj: {
              label: `${checkboxItem.textContent}`.replace(/\s+/g, ' ').trim(),
              checked: checked,
              checkboxItem: checkboxItem,
              checkboxItemOb: checkboxItemOb
            }
          });
        }
      }
    }
  };

  /**
   * Handle change in child item checkbox change
   * 1. Ids on checkbox item are framed as id_parent1_parent2_.._child where parent1, parent2, child are checkbox item processed labels.
   * These labels are used to generate ids and get checked status from __parentChildCheckedStatus.
   * 2. Assigning new reference so as to update component HTML
   * @param evt
   */
  private _handleCheckboxItemChildChange = (evt: CustomEvent, checkboxItemOb: CheckboxItem) => {
    const checkboxItem = evt.target as HTMLElement;
    if (checkboxItem) {
      const checkboxItemLabel = `${checkboxItem.textContent}`.replace(/\s+/g, ' ').trim();
      /* 1 */
      const id = `${checkboxItem.getAttribute('id')}`;
      if (this.enableSingleSelect) {
        if (this._lastCheckedItemId) {
          this.uncheckItem(this._lastCheckedItemId, true);
        }
        const splitId = id.split('_');
        const keyId = this.getValidIdString(`id_${splitId[splitId.length - 1]}`);
        if (!this._lastCheckedItemId) {
          this._lastResetCheckedItemId = keyId;
        }
        this._lastCheckedItemId = keyId;
      }

      let splitId = id.split('_');
      const { checked } = evt.detail;
      if (!!splitId && splitId.length > 1) {
        let instanceOb: any = this._parentChildCheckedStatus;
        for (let ind = 1; ind < splitId.length - 1; ++ind) {
          instanceOb = instanceOb[this.getValidIdString(`id_${splitId[ind]}`)];
          if (!instanceOb) break;
        }
        if (instanceOb) {
          instanceOb[this.getValidIdString(`id_${splitId[splitId.length - 1]}`)] = checked ? CheckboxStatus.CHECKED : CheckboxStatus.UNCHECKED;
          /* 2 */
          this._parentChildCheckedStatus = { ...this._parentChildCheckedStatus };
          this.dispatch({
            eventName: 'childItemClicked',
            detailObj: {
              label: checkboxItemLabel,
              checked: checked,
              checkboxItem: checkboxItem,
              checkboxItemOb: checkboxItemOb
            }
          });
        }
      }
    }
  };

  /**
   * Responsible of creating tree HTML
   * @param checkboxItems checkboxItems state (not property)
   * @param parentKey parent checkbox item label. It is used to prefix in child items ids so that we can use those item ids to get that item complete hierarchy.
   * @returns Tree HTML
   */
  private _renderTree = (checkboxItems: Array<CheckboxItem>, parentKey = ''): TemplateResult => {
    return html`${repeat(
      checkboxItems,
      (checkboxItem) => this.getValidIdString(`key_${parentKey}${checkboxItem.key}`),
      (checkboxItem) => {
        const id = this.getValidIdString(`id_${checkboxItem.key}`);
        const checkboxItemId = this.getValidIdString(`id_${parentKey}${checkboxItem.key}`);
        const hierarchy = `${parentKey}${checkboxItem.key}`;
        const statusId = this.getValidIdString(`id_${checkboxItem.key}`);
        const isSelectAll = checkboxItem.key === this.selectAllKey;
        let checkboxStatus = this._getCheckedStatus(statusId);
        const doNotShowHierarchyStatus = this.disableParentSelectionOnChildSelection || (this.doNotApplyParentChildSelection && !isSelectAll);
        if (this.doNotApplyParentChildSelection && isSelectAll && checkboxStatus === CheckboxStatus.CHECKED) {
          for (const id in this._parentCheckboxElementStatus) {
            if (id !== statusId && this._parentCheckboxElementStatus[id] !== CheckboxStatus.CHECKED) {
              checkboxStatus = CheckboxStatus.UNCHECKED;
              break;
            }
          }
        }
        if (checkboxItem.defaultExpanded && !checkboxItem.touched && !this._activeAccordionId.includes(id)) {
          this._activeAccordionId.push(id);
          checkboxItem.touched = true;
        }

        return html`
          ${checkboxItem.children?.length > 0
            ? html`
            <div class=${classMap({ 'en-c-tree-checkbox__accordion': true, 'en-c-active': this._activeAccordionId.includes(id) })} id="${id}">
              <${this.buttonEl} style="--en-button-padding:0px;--en-c-button-display:flex" @click=${(evt: Event) => this._handleAccordionButtonClick(evt, id)} variant="quaternary" .hideText=${true} aria-expanded=${ifDefined(this._activeAccordionId.includes(id))} aria-controls="${id}_panel">
              ${this._activeAccordionId.includes(id) ? html`<${this.chevronDownEl} class="en-c-tree-checkbox__accordion--expanded-icon" slot="before"></${this.chevronDownEl}>` : html`<${this.chevronRightEl} class="en-c-tree-checkbox__accordion--collapsed-icon" slot="before"></${this.chevronRightEl}>`}
              </${this.buttonEl}>
              <${this.checkboxItemEl} checkboxLabelSubText=${checkboxItem.helpText || ''} .isDisabled=${checkboxItem.disabled} .hideCheckbox=${this.listView} .isReadonly=${checkboxItem.readonly || this.enableSingleSelect} @change=${(evt: CustomEvent) => this._handleCheckboxItemParentChange(evt, checkboxItem)} data-repeat-hierarchy="${checkboxItem.doesThisLabelExistInAnotherHierarchy ? '1' : '0'}" data-key="${checkboxItem.key}" data-statusid="${statusId}" data-hierarchy="${hierarchy}" id="${checkboxItemId}" .isChecked=${doNotShowHierarchyStatus ? this._parentCheckboxElementStatus[statusId] === CheckboxStatus.CHECKED : checkboxStatus === CheckboxStatus.CHECKED} .isIndeterminate=${doNotShowHierarchyStatus ? false : checkboxStatus === CheckboxStatus.INDETERMINATE} class="en-checkbox-item en-is-parent" size="${this.size}">
                ${this._getIconHtml(checkboxItem.icon)}
                ${checkboxItem.label}
              </${this.checkboxItemEl}>
            </div>
            <div class=${classMap({ 'en-c-tree-checkbox__accordion-panel': true })} id="${id}_panel">${this._renderTree(checkboxItem.children, `${parentKey}${checkboxItem.key}_`)}</div> `
            : !checkboxItem.children
              ? html`
            <${this.checkboxItemEl} checkboxLabelSubText=${checkboxItem.helpText || ''} .isDisabled=${checkboxItem.disabled} .hideCheckbox=${this.listView} .isReadonly=${checkboxItem.readonly} .showHighlightOnHover=${this.listView} .showHighlightOnSelection=${this.listView} .enableLabelFullWidth=${this.listView} .tabableItem=${this.listView} data-repeat-hierarchy="${checkboxItem.doesThisLabelExistInAnotherHierarchy ? '1' : '0'}" data-key="${checkboxItem.key}" data-hierarchy="${hierarchy}" id="${checkboxItemId}" @change=${(evt: CustomEvent) => this._handleCheckboxItemChildChange(evt, checkboxItem)} .isChecked=${checkboxStatus === CheckboxStatus.CHECKED} .isIndeterminate=${checkboxStatus === CheckboxStatus.INDETERMINATE} class="en-checkbox-item en-is-child" size="${this.size}">
              ${this._getIconHtml(checkboxItem.icon)}
              ${checkboxItem.label}
            </${this.checkboxItemEl}>
          `
              : html``}
        `;
      }
    )}`;
  };

  render() {
    const componentClassNames = this.componentClassNames('en-c-tree-checkbox', {
      'en-dropdown-version-v2': this.dropdownVersion === 'v2'
    });
    return html` <div class="${componentClassNames}">${this._treeHtml}</div> ` as TemplateResult<1>;
  }
}

if ((globalThis as any).enAutoRegistry === true && customElements.get(ENTreeCheckbox.el) === undefined) {
  customElements.define(ENTreeCheckbox.el, ENTreeCheckbox);
}

declare global {
  interface HTMLElementTagNameMap {
    'en-tree-checkbox': ENTreeCheckbox;
  }
}
