export const GET_AUTH_TOKEN = `/auth/api/v1/accounts/auth-token/`
export const WORKSPACE_IDP_NAME = "/auth/api/v1/accounts/workspace-idp-name/"
export const CHECK_FOR_VALID_SESSION_END_USER = `/auth/api/v1/accounts/validate/end-user/`

export const END_USER_AGENTLESS_SERVICES = "/resource/api/v1/resources/services/user/agentless/"

export const MICROSOFT_OIDC_AUTH_URL_SIGNUP_INVITE = `/auth/api/v1/accounts/invite/microsoft/signup/`

export const SAML_AUTH_URL_SIGNUP_INVITE = `/auth/api/v1/saml/signup/`

export const MICROSOFT_OIDC_AUTH_URL_LOGIN = `/auth/api/v1/accounts/microsoft/login/`

export const SAML_AUTH_URL_LOGIN = `/auth/api/v1/saml/login/`

export const GOOGLE_AUTH_URL_SIGNUP_INVITE = `/auth/api/v1/accounts/invite/google/signup/`

export const GOOGLE_AUTH_URL_LOGIN = `/auth/api/v1/accounts/google/login/`

export const GET_DOWNLOADS = "/resource/api/v1/downloads/agents/user/"

export const DOWNLOAD_ROOT_CA_CERTIFICATE = "/resource/api/v1/resources/downloads/root-ca-certificate/"

export const GET_USER_ACTIVE_DEVICES = "/auth/api/v1/accounts/user-active-devices/"

export const TOKEN_INFO_URL = "/auth/api/v1/accounts/token-info/"

export const REQUEST_INVITE = "/auth/api/v1/accounts/request-invite/"
