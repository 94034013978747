import dayjs from "dayjs"
import relativeTime from "dayjs/plugin/relativeTime"
import utc from "dayjs/plugin/utc"
import { useSelector } from "react-redux"
import { CUSTOMER_ADMIN_SITES } from "src/constants"
import { API_URL, REQUEST_HEADERS } from "src/services"
import { RootState } from "src/store"
import useSWR from "swr"

dayjs.extend(relativeTime)
dayjs.extend(utc)

type SiteType = {
  name: string
  hostsCount: number
  id: string
  proxiesCount: number
  createTime: string
  parentId: number
  address: {
    address: string
    address2: string
    city: string
    postalCode: string | number
    state: string
  }
  country: {
    name: string
    alphaCode: string
    countryCode: number
  }
}

export type SiteResponseType = {
  parentId: string
  name: string
  hostsCount: number
  id: string
  radsecProxiesCount: number
  createdBy: string
  createdAt: string
  description?: string
  address?: string
  type?: string
  parsedAddress: {
    address: string
    address2: string
    city: string
    postalCode: string | number
    state: string
  }
  country: {
    alphaCode: string
    countryCode: number
    name: string
  }
  countryCode: string
  location: {
    countryName: string
    countryCode: string // this will be alphacode
    code: string //this will be country code for api
  }
  children?: any[]
}

type SitesPayloadType = {
  sites: SiteResponseType[] | null
  total?: number
  from?: number
  to?: number
}

type UseSitesResponseType = {
  data?: SitesPayloadType
  isLoading: boolean
  error: any
  isValidating: boolean
  getSites: () => void
}

type UseSitesType = {
  offset?: number
  limit?: number
  query?: string
  filterQuery?: string
  refreshInterval?: number
  isUrlNull?: boolean
}

/**
 * Parses site data into a formatted structure suitable for display.
 *
 * @param {SiteType[]} data - The list of sites to parse.
 * @param {string} dateTimeFormat - The desired date-time format.
 *
 * @returns {any[]} An array of parsed site objects with formatted details.
 */
export const parseSiteData = (data: SiteType[], dateTimeFormat: string): any[] =>
  data.map((site) => ({
    ...site,
    createdAt: site?.createTime ? dayjs(site?.createTime).format(dateTimeFormat) : "N/A",
    location: {
      countryCode: site?.country?.alphaCode,
      countryName: site?.country?.name,
      code: site?.country?.countryCode,
    },
    address: site?.address?.address,
    city: site?.address?.city,
    postalCode: site?.address?.postalCode,
    state: site?.address?.state,
    address2: site?.address?.address2,
  }))

/**
 * Custom hook to fetch and manage site data.
 *
 * @param {UseSitesType} params - The params for fetching sites.
 * @param {number} [params.offset=0] - The starting offset for data pagination.
 * @param {number} [params.limit=100] - The maximum number of records to fetch.
 * @param {string} [params.filterQuery] - Additional query string for filtering.
 * @param {number} [params.refreshInterval] - Interval in milliseconds for refreshing data.
 * @param {boolean} [params.isUrlNull=false] - Whether to skip URL generation.
 *
 * @returns {UseSitesResponseType} The site data and metadata, including loading and error states.
 */
const useSites = ({
  offset = 0,
  limit = 100,
  filterQuery,
  refreshInterval,
  isUrlNull = false,
}: UseSitesType): UseSitesResponseType => {
  const dateTimeFormat = useSelector((state: RootState) => state.ui.dateTimeFormat.dateTimeFormat)

  let url = !isUrlNull ? `${API_URL}${CUSTOMER_ADMIN_SITES}?limit=${limit}&offset=${offset}` : null

  if (filterQuery) url += `&${filterQuery}`

  const { data, error, mutate, isValidating } = useSWR([url, REQUEST_HEADERS], { refreshInterval })

  const payload = data?.payload

  return {
    data: payload
      ? {
          sites: parseSiteData(payload?.sites, dateTimeFormat),
          from: payload?.from,
          to: payload?.to,
          total: payload?.total,
        }
      : undefined,
    isLoading: !error && !data,
    error,
    getSites: mutate,
    isValidating,
  }
}

export default useSites
