import { PropertyValueMap, TemplateResult, unsafeCSS } from 'lit';
import { property, query, state } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';
import { ifDefined } from 'lit/directives/if-defined.js';
import { html, unsafeStatic } from 'lit/static-html.js';
import { nanoid } from 'nanoid';
import register from '../../directives/register';
import PackageJson from '../../package.json';
import { ENElement } from '../ENElement';
import { ENButton } from '../button/button';
import { ENCheckboxItem } from '../checkbox-item/checkbox-item';
import { ENFieldNote } from '../field-note/field-note';
import { ENIconChevronDown } from '../icon/icons/chevron-down';
import { ENIconClose } from '../icon/icons/close';
import { ENTextField } from '../text-field/text-field';
import { CheckboxItem, Icon } from '../tree-checkbox/tree-checkbox';
import { ENTreeDropdownPanel } from '../tree-dropdown-panel/tree-dropdown-panel';
import styles from './tree-dropdown.scss';

/**
 * Component: en-tree-dropdown
 * @slot - The components content
 */
export class ENTreeDropdown extends ENElement {
  static el = 'en-tree-dropdown';

  private elementMap = register({
    elements: [
      [ENTreeDropdownPanel.el, ENTreeDropdownPanel],
      [ENFieldNote.el, ENFieldNote],
      [ENButton.el, ENButton],
      [ENIconChevronDown.el, ENIconChevronDown],
      [ENIconClose.el, ENIconClose],
      [ENTextField.el, ENTextField]
    ],
    suffix: (globalThis as any).enAutoRegistry === true ? '' : PackageJson.version
  });

  private treeDropdownPanelEl = unsafeStatic(this.elementMap.get(ENTreeDropdownPanel.el));
  private fieldNoteEl = unsafeStatic(this.elementMap.get(ENFieldNote.el));
  private iconChevronDownEl = unsafeStatic(this.elementMap.get(ENIconChevronDown.el));
  private iconCloseEl = unsafeStatic(this.elementMap.get(ENIconClose.el));
  private buttonEl = unsafeStatic(this.elementMap.get(ENButton.el));
  private textFieldEl = unsafeStatic(this.elementMap.get(ENTextField.el));

  static get styles() {
    return unsafeCSS(styles.toString());
  }

  /**
   * isActive
   * Label is moved to top when set to true.
   */
  @property({ type: Boolean })
  isActive?: boolean;

  /**
   * isActiveDropdown
   * 1. Dropdown is open when set to true. Close when set to false
   */
  @property({ type: Boolean })
  isActiveDropdown?: boolean = false;

  /**
   * The unique id of the select field
   */
  @property()
  fieldId?: string;

  /**
   * The select field's label
   */
  @property()
  label = 'Label';

  /**
   * The select field's name attribute
   */
  @property()
  name?: string;

  /**
   * Placeholder attribute
   * - Specifies a short hint that describes the expected value of an <input> element
   */
  @property()
  placeholder?: string;

  /**
   * The dropdown field note
   */
  @property()
  fieldNote?: string;

  /**
   * The dropdown error note
   */
  @property()
  errorNote?: string;

  /**
   * Aria describedby
   * 1. Used to connect the field note in select field to the select menu for accessibility
   */
  @property()
  ariaDescribedBy?: string;

  /**
   * The select field's required attribute
   */
  @property({ type: Boolean })
  isRequired?: boolean = false;

  /**
   * Optional state
   * - Specifies that a field is optional and adds the text 'optional' to the label
   */
  @property({ type: Boolean })
  isOptional?: boolean;

  /**
   * The select field's disabled attribute
   */
  @property({ type: Boolean })
  isDisabled?: boolean;

  /**
   * The select field's readonly attribute
   */
  @property({ type: Boolean })
  isReadonly?: boolean;

  /**
   * Error state
   */
  @property({ type: Boolean })
  isError?: boolean;

  /**
   * Hide the label
   */
  @property({ type: Boolean })
  hideLabel?: boolean;

  /**
   * checkboxItems
   * It contains hierarchy of data has to be shown in tree. checkbox items should have UNIQUE LABELS
   * For disabled state, don't expect that setting disabled on parent will disable child selection also
   * Handling this case in tree dropdown will increase lookups and conditions making logic more complex. It is simple thing that developer can handle at their end also.
   * If you want to disable all child under a parent, set disabled property seperatly for all of them.
   * For EC1 Developer: Keep its initial value same as _checkboxItems state.
   * checkbox items should have UNIQUE LABELS or if label repeats then key should be unique. Type definition is as follow:
      <pre>
      Array<{
        // label must not contain '_'. If for some reason '_' is required in label then must set key WITHOUT '_'.
        label: string;
        // key CANNOT not contain '_'. Otherwise it will break logic
        key?: string;
        icon?: Icon;
        readonly?: boolean;
        disabled?: boolean;
        type?: 'SELECT_ALL' | undefined;
        defaultExpanded?: boolean;
        defaultChecked?: boolean;
        children?: CheckboxItem[];
      }>
      </pre>
   */
  @property({ type: Array })
  checkboxItems?: Array<CheckboxItem> = [];

  /**
   * enableSelectAll
   * If set to true, then Select All functionality will be enabled, otherwise disabled. Default is true. Use it with care only where it is required.
   */
  @property({ type: Boolean })
  enableSelectAll?: boolean = true;

  /**
   * If true, then checkbox view will be shown otherwise list view will be shown
   */
  @property({ type: Boolean })
  listView?: boolean = false;

  /**
   * Tree checkbox allows to select multiple items. But if this property is set to true, then at a time only single item can be selected and
   * on clicking on parent, child items will not be selected. Default is false. It should be used with list view.
   * If this is enabled, then you can set max 1 defaultChecked and only on child item. If you do it some other way in that case it may not work correctly.
   */
  @property({ type: Boolean })
  enableSingleSelect?: boolean = false;

  /**
   * If true, then parent can also be selected. This property is relevant only for list view. Default is false.
   */
  @property({ type: Boolean })
  isParentInListSelectable?: boolean = false;

  /**
   * selectAllLabel
   * Default is (Select All). Using this property you can customize it.
   */
  @property()
  selectAllLabel?: string = '(Select All)';

  /**
   * If true then disable Reset button. Default is false.
   */
  @property({ type: Boolean })
  disableResetButton?: boolean = false;

  /**
   * If true then disable Done button. Default is false.
   */
  @property({ type: Boolean })
  disableDoneButton?: boolean = false;

  /**
   * selectAllKey
   * Default is (Select All). Key is used to uniquly identify checkbox item.
   */
  @property()
  selectAllKey?: string = '(Select All)';

  /**
   * selectAllIcon
   * Default is undefined. Using this property you can add icon on select all
   */
  @property({ type: String })
  selectAllIcon?: Icon;

  /**
   * selectAllDefaultExpanded
   * Default is true. Using this property make select All default expanded or collapsed.
   */
  @property({ type: Boolean })
  selectAllDefaultExpanded?: boolean = true;

  /**
   * If true then show Reset button
   */
  @property({ type: Boolean })
  showResetButton?: boolean = true;

  /**
   * If true then show Done button
   */
  @property({ type: Boolean })
  showDoneButton?: boolean = true;

  /**
   * If true, then show header and search box inside it. Default is true.
   */
  @property({ type: Boolean })
  enableSearch?: boolean = true;

  /**
   * Set true if case-insensitive search has to be performed. Default is true.
   */
  @property({ type: Boolean })
  caseInSensitiveSearch?: boolean = true;

  /**
   * chipVariant
   * - **default** A chip with a high contrast background
   * - **secondary** A chip with a medium contrast background
   * - **tertiary** A chip with a low contrast background
   * - **green** A chip with a green background
   * - **red** A chip with a red background
   * - **blue** A chip with a blue background
   * - **amber** A chip with a amber background
   * - **purple** A chip with a purple background
   */
  @property()
  chipVariant?: 'default' | 'secondary' | 'tertiary' | 'green' | 'red' | 'blue' | 'amber' | 'purple' | 'neutral' = 'default';

  /**
   * treeCheckboxSize
   * - **sm** Displays 30px width/height checkbox and 14px text size
   * - **md** Displays 40px width/height checkbox and 16px text size
   * Default is sm.
   */
  @property({ type: String })
  treeCheckboxSize?: 'md' | 'sm' = 'sm';

  /**
   * **Dropdown alignment**
   * - **bottom** Dropdown panel appears on the bottom
   * - **top** Dropdown panel appears on the top
   * Default is bottom. Relevance of setting it only when `enableDynamicPositioning` set to false.
   */
  @property()
  align?: 'bottom' | 'top' = 'bottom';

  /**
   * Default is false.
   * If true then selecting child will not select parent. Neither it will set indeterminate state nor checked state on parent if child is selected
   * However if parent is selected then all child in it will be selected.
   */
  @property({ type: Boolean })
  disableParentSelectionOnChildSelection?: boolean = false;

  /**
   * If true dynamically position dropdown panel. Otherwise position according to `align` property value. Default is true.
   */
  @property({ type: Boolean })
  enableDynamicPositioning?: boolean = true;

  /**
   * It takes CSS selector for container consisting dropdown. This container height and bottom is compared with dropdown panel height and bottom and accordingly position is determined. Default value is body. Relevance of setting it only if `enableDynamicPositioning` is set to true.
   */
  @property()
  dropdownPanelContainerSelector?: string = 'body';

  /**
   * If this property would be enabled, then selecting parent will not select its all child and selecting all child of parent will not select parent.
   * selectAll if enabled will work as it is even with this property.
   */
  @property({ type: Boolean })
  doNotApplyParentChildSelection?: boolean = false;

  /**
   * Set child chip max width except count chip. This property  is required to set in order to control text overflow in chip.
   */
  @property({ type: String })
  chipsMaxWidth?: string = '115px';

  /**
   * Set child chip min width except count chip. This property is required to set in order to automatically determine chips visible and text overflow.
   */
  @property({ type: String })
  chipsMinWidth?: string = '115px';

  /**
   * If true, then add cross icon on dropdown which enables clearing selection. Default value is false.
   */
  @property({ type: Boolean })
  enableClearSelection?: boolean = false;

  /**
   * Variant
   * - **primary** renders the tree-dropdown to be used on backgrounds with var(--en-theme-color-background-surface-elevation-1) (Dialogs Tables Panels etc)
   * - **secondary** renders the tree-dropdown to be used on backgrounds with var(--en-theme-color-background-surface-elevation-0) (The main body background)
   * - **tertiary** renders the text-field to be used on backgrounds with var(--en-theme-color-background-surface-elevation-2)
   */
  @property()
  variant?: 'primary' | 'secondary' | 'tertiary' = 'primary';

  /**
   * If true, show dropdown menu on counter chip. Default is true.
   */
  @property({ type: Boolean })
  showCounterChipDropdownMenu?: boolean = true;

  /**
   * If set to true, then make chip dismissible. Default is true
   */
  @property({ type: Boolean })
  isChipDismissible?: boolean = true;

  /**
   * Text shown in text-field when "Select All" checkbox is selected.
   */
  @property()
  selectAllText?: string = 'All Selected';

  /**
   * Set it in order to change chip dropdown menu max height. Expected valid height string css value.
   */
  @property()
  chipDropdownMenuMaxHeight?: string = '205px';

  /**
   * Max height of tree dropdown panel. Default value is 185px. But you can configure according to your requirement.
   */
  @property()
  treeDropdownPanelBodyMaxHeight?: string = '185px';

  @property()
  treeDropdownPanelMinWidth?: string = '100%';

  /**
   * Dropdown Version
   * **v1** In this dropdown will be shown so that selected values chips will be shown in textfield, search will have search box with borders and selected chips will not be shown in dropdown.
   * **v2** In this dropdown will be shown so that text field will contain selected values count, selected chips will be shown in droddown, search look and feel will be inline without search box borders.
   */
  @property()
  dropdownVersion?: 'v1' | 'v2' = 'v1';

  /**
   * If true, restrict chips area height to 8rem. Default is true. Applicable for dropdown version 2
   */
  @property({ type: Boolean })
  restrictChipsAreaHeight: boolean = true;

  /**
   * Applicable for dropdown version 2. Max height of chips area after which if chips exceed then scroll would be shown
   */
  @property()
  chipsAreaMaxHeight: string = '8rem';

  /**
   * Applicable for dropdown version 2. Type variant
   * - **default** A chip with rounded corners
   * - **squared** A chip with squared corners
   * Default is squared
   */
  @property()
  chipType?: 'default' | 'squared' = 'squared';

  /**
   * If true, chips are dismissible, else not.
   * NOTE: This property is applicable only with dropdown version 2.
   */
  @property({ type: Boolean })
  areChipsDismissible?: boolean = true;

  /**
   * If true enables lazy loading, i.e data will be loaded lazily as user scroll down. Default is false
   */
  @property({ type: Boolean })
  enableLazyLoading?: boolean = false;

  /* If true enable loader on lazy loading. Default is true. */
  @property({ type: Boolean })
  showLoaderOnLazyLoading?: boolean = true;

  /**
   * Set this property to false to show large loader. Default is true
   */
  @property({ type: Boolean })
  showSmallLoader?: boolean = true;

  /**
   * Set this property for showing loader in light theme. Default is false.
   */
  @property({ type: Boolean })
  showInvertedLoader?: boolean = false;

  /**
   * Expects asynchronous function if `enableLazyLoading` is set that
   * - Receive page number
   * - Calls API
   * - Returns data in shape of Array<CheckboxItem>
   * Default is null.
   */
  @property()
  lazyLoadingService: (page: number) => Promise<Array<CheckboxItem>> = null;

  /**
   * Scroll offset at which loading should start
   */
  @property({ type: Number })
  lazyLoadingScrollOffset?: number = 0;

  /**
   * Disable it after first render if tree-dropdown is getting rendered multiple times.
   */
  @property({ type: Boolean })
  disableScrollDispatchEventInUpdated?: boolean = false;

  /**
   * Expects string value of trigger menu panel width. It is for dropdown menu open after clicking overflow chip
   * Default value is max-content
   */
  @property()
  triggerMenuPanelWidth?: string = 'max-content';

  /**
   * Sometimes browsers are not able to detect correctly if scroll reaches bottom or meet
   * offset or not. The error of detection was found to be small in chrome. In order to fix
   * it a fixOffset of 0.5 is defined. It is advised to not touch this setting unless very much required.
   * If required to set it, set to value <=1 only. If larger offset is required, contact Web Components Team.
   */
  @property({ type: Number })
  marginalFixOffset?: number = 0.5;

  /**
   * State to save input value
   */
  @state()
  public inputValue?: string;

  @state()
  values?: Array<{ label: string; key: string; hierarchy: string; doesThisLabelExistInAnotherHierarchy: boolean | undefined }> = [];

  /**
   * True if all is selected. Default is false.
   */
  @state()
  childNodesCount?: number = 0;

  /**
   * Query the tree dropdown panel component
   */
  @query('.en-c-tree-dropdown__panel')
  treeDropdownPanelQueryEl: ENTreeDropdownPanel;

  @query('.en-c-dropdown__input')
  textFieldQueryEl: ENTextField;

  private _dropdownKeyLabelMapping: { [key: string]: string } = {};

  /** Set it to suppress update cycle due to change in checkboxItems in updated Lifecycle method. */
  private _suppressUpdateInCheckboxItems = false;

  /**
   * Initialize functions
   */
  constructor() {
    super();
  }

  /**
   * Connected Callback lifecycle
   * 1. Close dropdown panel when you click outside of the element
   * 2. Autogenerate the fieldID
   */
  connectedCallback() {
    super.connectedCallback();
    document.addEventListener('mousedown', this.handleOnClickOutside, false); /* 1 */
    this.fieldId = this.fieldId || nanoid(); /* 2 */
    if (this.enableSelectAll && !this.enableSingleSelect) {
      this._dropdownKeyLabelMapping = { [this.selectAllLabel]: this.selectAllLabel };
    }
  }

  /**
   * Disconnected callback lifecycle
   * 1. Remove event listeners
   */
  disconnectedCallback() {
    super.disconnectedCallback();
    document.removeEventListener('mousedown', this.handleOnClickOutside, false);
  }

  private _getInputValueFromValues(
    values: Array<{ label: string; key: string; hierarchy: string; doesThisLabelExistInAnotherHierarchy: boolean | undefined }>
  ) {
    const result = values
      .map((value) => {
        const splitHierarchy = value.hierarchy === '' ? [] : value.hierarchy.split('_');
        const resultValue = [];
        for (const hierarchy of splitHierarchy) {
          resultValue.push(this._dropdownKeyLabelMapping[hierarchy]);
        }
        return resultValue.join(' > ') || value.label;
      })
      .join(',');
    return result;
  }

  /**
   * This method is used to get hierarchy of selected values only during initialization or reset
   * @param checkboxItems
   * @param selectedValues
   * @param parentCheckedStatus
   * @param hierarchy
   */
  private _getSelectedValues(
    checkboxItems: Array<CheckboxItem>,
    selectedValues: Array<{ label: string; key: string; hierarchy: string; doesThisLabelExistInAnotherHierarchy: boolean | undefined }> = [],
    parentCheckedStatus: boolean = undefined,
    hierarchy = this.enableSelectAll && !this.enableSingleSelect ? this.selectAllLabel : ''
  ) {
    for (const item of checkboxItems) {
      if (
        (!item.children &&
          ((!this.enableSingleSelect && !this.doNotApplyParentChildSelection && (item.defaultChecked || parentCheckedStatus === true)) ||
            (this.enableSingleSelect && item.defaultChecked) ||
            (this.doNotApplyParentChildSelection && item.defaultChecked))) ||
        (!!item.children && this.doNotApplyParentChildSelection && item.key !== this.selectAllKey && item.defaultChecked)
      ) {
        selectedValues.push({
          label: item.label,
          key: item.key,
          hierarchy: hierarchy + `_${item.key || item.label}`,
          doesThisLabelExistInAnotherHierarchy: item.doesThisLabelExistInAnotherHierarchy
        });
      } else if (item.children) {
        if (hierarchy === '') {
          hierarchy = item.key || item.label;
        } else {
          hierarchy += `_${item.key || item.label}`;
        }
        this._getSelectedValues(item.children, selectedValues, item.defaultChecked ? true : parentCheckedStatus, hierarchy);
        const splitHierarchy = hierarchy === '' ? [] : hierarchy.split('_');
        hierarchy = splitHierarchy.slice(0, splitHierarchy.length - 1).join('_');
      }
    }
  }

  private _getChildNodesCount(checkboxItems: Array<CheckboxItem>) {
    let count = 0;
    for (const item of checkboxItems) {
      if (!item.children) {
        ++count;
      } else {
        if (this.doNotApplyParentChildSelection && item.key !== this.selectAllKey) {
          count += 1;
        }
        count += this._getChildNodesCount(item.children);
      }
    }
    return count;
  }

  private _setKeyLabelMapping = (checkboxItems: Array<CheckboxItem>) => {
    for (const item of checkboxItems) {
      this._dropdownKeyLabelMapping[item.key || item.label] = item.label;
      if (item.children) {
        this._setKeyLabelMapping(item.children);
      }
    }
  };

  /**
   * Check if entries have repeating (key or label). If it has repeating key or label then it will eliminate it and return the fresh array with entries having unique key or label
   * NOTE: Labels can be repeating but repeating labels are expected to have unique repeating keys.
   * @param values
   * @returns
   */
  private _getUniqueValues = (
    values: Array<{ label: string; key: string; hierarchy: string; doesThisLabelExistInAnotherHierarchy: boolean | undefined }>
  ) => {
    const result = [];
    const keysRecorded: string[] = [];
    for (const value of values) {
      const unique = value.key || value.label;
      if (unique === this.selectAllKey || unique === this.selectAllLabel) {
        continue;
      }
      if (!keysRecorded.includes(unique)) {
        result.push(value);
        keysRecorded.push(unique);
      }
    }
    return result;
  };

  protected updated(_changedProperties: PropertyValueMap<any> | Map<PropertyKey, unknown>): void {
    _changedProperties.forEach((oldValue, propName) => {
      /* 1 */
      if (propName === 'checkboxItems' && this.checkboxItems !== oldValue && !this._suppressUpdateInCheckboxItems) {
        /* 2 */
        const defaultSelectedValues: Array<{
          label: string;
          key: string;
          hierarchy: string;
          doesThisLabelExistInAnotherHierarchy: boolean | undefined;
        }> = [];
        this._setKeyLabelMapping(this.checkboxItems);
        this._getSelectedValues(this.checkboxItems || [], defaultSelectedValues);
        if (defaultSelectedValues && defaultSelectedValues.length > 0) {
          this.values = this._getUniqueValues([...defaultSelectedValues]);
          this.inputValue = this._getInputValueFromValues(this.values);
        }
        this.childNodesCount = this._getChildNodesCount(this.checkboxItems);
      }
    });
    this._suppressUpdateInCheckboxItems = false;
  }

  /**
   * Set menu active state
   * 1. Toggle the active state between true and false
   */
  toggleActive() {
    if (this.isReadonly) {
      return;
    }

    this.isActiveDropdown = !this.isActiveDropdown; /* 1 */
    if (this.isActiveDropdown === true) {
      this.handleOnActiveDropdown();
      this.dispatch({ eventName: 'open', detailObj: { active: true } });
    } else {
      this.dispatch({ eventName: 'close', detailObj: { active: false } });
    }
  }

  /**
   * Handles the activation behavior of the dropdown:
   * 1. Positions the dropdown panel based on available viewport space
   */
  handleOnActiveDropdown() {
    setTimeout(() => {
      if (this.enableDynamicPositioning) {
        const dropdownPanel = this.shadowRoot.querySelector<HTMLElement>('.en-c-tree-dropdown__panel')?.getBoundingClientRect();
        const body = document.querySelector(this.dropdownPanelContainerSelector);
        const bodyPosition = body?.getBoundingClientRect();
        if (!!bodyPosition && bodyPosition.height > dropdownPanel?.height && dropdownPanel?.bottom > bodyPosition.bottom) {
          /* 1 */
          this.align = 'top';
        }
      }
    }, 0);
  }

  /**
   * Get labels in order to show in textfield.
   * @param checkboxItemElement
   * @returns array of labels
   */
  private _getChildLabelsOfParentCheckboxItem = (checkboxItemElement: HTMLElement) => {
    if (checkboxItemElement && checkboxItemElement.classList.contains('en-is-parent')) {
      const parentAccordion = checkboxItemElement.parentElement;
      if (parentAccordion && parentAccordion.classList.contains('en-c-tree-checkbox__accordion')) {
        const accordionPanel = parentAccordion.nextElementSibling as HTMLElement;
        if (accordionPanel && accordionPanel?.classList.contains('en-c-tree-checkbox__accordion-panel')) {
          const allChildElements = Array.from(accordionPanel.querySelectorAll<ENCheckboxItem>('.en-is-child'));
          return allChildElements.map((childElement) => {
            const label = `${childElement.textContent}`.replace(/\s+/g, ' ').trim();
            const hierarchy = childElement.getAttribute('data-hierarchy');
            const key = childElement.getAttribute('data-key');
            const selectedCheckboxRepeatHierarchyStatusStr = childElement.getAttribute('data-repeat-hierarchy');
            const selectedCheckboxRepeatHierarchyStatus = selectedCheckboxRepeatHierarchyStatusStr === '1' ? true : false;
            return { label, hierarchy, key, doesThisLabelExistInAnotherHierarchy: selectedCheckboxRepeatHierarchyStatus };
          });
        }
      }
    }
    return [];
  };

  /**
   * Event handler to handle when any checkbox is checked or unchecked in tree
   * @param evt
   */
  handleTreeCheckboxChecked = (evt: CustomEvent) => {
    const { isParent, checked, checkboxItem, label } = evt.detail;
    const selectedCheckboxHierarchy = checkboxItem.getAttribute('data-hierarchy');
    const selectedCheckboxId = checkboxItem.id;
    const selectedCheckboxKey = checkboxItem.getAttribute('data-key');
    const selectedCheckboxRepeatHierarchyStatusStr = checkboxItem.getAttribute('data-repeat-hierarchy');
    const selectedCheckboxRepeatHierarchyStatus = selectedCheckboxRepeatHierarchyStatusStr === '1' ? true : false;

    if (!isParent || (this.doNotApplyParentChildSelection && selectedCheckboxKey !== this.selectAllKey)) {
      if (checked) {
        const newValue = {
          label,
          key: selectedCheckboxKey,
          hierarchy: selectedCheckboxHierarchy,
          doesThisLabelExistInAnotherHierarchy: selectedCheckboxRepeatHierarchyStatus
        };
        this.values = this.enableSingleSelect ? [newValue] : [...this._getUniqueValues([...this.values, newValue])];
        this.inputValue = this._getInputValueFromValues(this.values);
      } else {
        const findKeyOrLabelIndex = this.values.findIndex((value) => {
          if (value.key) {
            return value.key === selectedCheckboxKey;
          } else return value.label === label;
        });
        if (findKeyOrLabelIndex > -1) {
          this.values.splice(findKeyOrLabelIndex, 1);
          this.values = this._getUniqueValues(this.values);
          this.inputValue = this._getInputValueFromValues(this.values);
          if (this.inputValue === '') {
            this.isActive = false;
          }
        }
      }
    } else {
      const childValues = this._getChildLabelsOfParentCheckboxItem(checkboxItem);
      const childLabelsOrKeys = childValues.map((value) => value.key || value.label);
      if (checked) {
        this.values = [...this._getUniqueValues([...this.values, ...childValues])];
        this.inputValue = this._getInputValueFromValues(this.values);
      } else {
        this.values = this.values.filter((value) => !childLabelsOrKeys.includes(value.key || value.label));
        this.inputValue = this._getInputValueFromValues(this._getUniqueValues(this.values));
        if (this.inputValue === '') {
          this.isActive = false;
        }
      }
    }
    this.dispatch({
      eventName: 'treeDropdownChange',
      detailObj: {
        inputValue: this.inputValue,
        checkboxItems: this.checkboxItems,
        selectedValues: [...this.values],
        selectedCheckboxHierarchy,
        selectedCheckboxId,
        selectedCheckboxKey,
        selectedCheckboxItem: checkboxItem,
        selectedCheckboxCheckedStatus: checked,
        selectedCheckboxParentStatus: isParent,
        selectedCheckboxLabel: label
      }
    });
  };

  /**
   * Handle tree reset
   * @param evt
   */
  handleTreeReset = (evt: CustomEvent) => {
    const initialCheckboxItems = evt.detail?.initialCheckboxItems || [];
    const defaultSelectedValues: Array<{ label: string; key: string; hierarchy: string; doesThisLabelExistInAnotherHierarchy: boolean | undefined }> =
      [];
    this._getSelectedValues(initialCheckboxItems, defaultSelectedValues);
    if (defaultSelectedValues && defaultSelectedValues.length > 0) {
      this.values = this._getUniqueValues(defaultSelectedValues);
      this.inputValue = this._getInputValueFromValues(this.values);
      if (this.inputValue === '') {
        this.isActive = false;
      }
    } else {
      this.values = [];
      this.inputValue = this._getInputValueFromValues(this.values);
      if (this.inputValue === '') {
        this.isActive = false;
      }
    }

    this.dispatch({
      eventName: 'treeDropdownReset',
      detailObj: { values: this.values, inputValue: this.inputValue, isDropdownActive: this.isActive }
    });
  };

  /**
   * Uncheck selected label checkbox item
   * @param key
   */
  private _uncheckItem = (key: string) => {
    if (this.treeDropdownPanelQueryEl && !!key) {
      this.treeDropdownPanelQueryEl.uncheckItem(key);
    }
  };

  /**
   * Search for key in checkbox items
   * @param checkboxItems
   * @param searchLabel
   * @returns
   */
  private _searchForKeyByCheckboxItemLabel = (checkboxItems: Array<CheckboxItem>, searchLabel: string): string => {
    let result = '';
    if (searchLabel.includes(' | ')) {
      const splitSearchLabel = searchLabel.split(' | ');
      return splitSearchLabel[0];
    }
    for (const item of checkboxItems) {
      if (item.label === searchLabel) {
        result = item.key || item.label;
        break;
      } else if (item.children) {
        result = this._searchForKeyByCheckboxItemLabel(item.children, searchLabel);
        if (!!result) break;
      }
    }
    return result;
  };

  /**
   * Handle chip in text-field or dropdown selection panel dismiss.
   * But for dropdown selection panel it will be triggered from
   * @param evt
   */
  handleChipDismiss = (evt: CustomEvent) => {
    const { chipContent, dataInfo } = evt.detail;
    if (!chipContent) return false;
    const dissmissedChipHierarchy = dataInfo;
    // const uncheckItemKey = this._searchForKeyByCheckboxItemLabel(this.checkboxItems, chipContent);
    const splitDissmissedChipHierarchy = dissmissedChipHierarchy === '' ? [] : dissmissedChipHierarchy.split('_');
    const uncheckItemKey = splitDissmissedChipHierarchy[splitDissmissedChipHierarchy.length - 1];
    this._uncheckItem(uncheckItemKey);
    // uncheckItemKey is equal to label if key is not available
    this.values = this.values.filter((value) => value.key !== uncheckItemKey && value.label !== uncheckItemKey);
    this.inputValue = this._getInputValueFromValues(this.values);
    if (!this.inputValue?.trim()) {
      this.isActive = false;
    }
    this.dispatch({
      eventName: 'treeChipDismiss',
      detailObj: { values: this.values, inputValue: this.inputValue, dismissedChipContent: chipContent, uncheckItemKeyOrLabel: uncheckItemKey }
    });
  };

  /**
   * Handle clear selection
   * @param evt
   * @returns
   */
  handleClearSelection = (evt: CustomEvent) => {
    const { chipsContent } = evt.detail;
    if (!chipsContent) return false;
    if (!!this.treeDropdownPanelQueryEl) {
      this.treeDropdownPanelQueryEl.uncheckAll();
    }
    this.values = [];
    this.inputValue = this._getInputValueFromValues(this.values);
    if (!this.inputValue?.trim()) {
      this.isActive = false;
    }
  };

  /**
   * Handle on select input keydown
   * 1. If key selected is enter or spacebar, toggle the menu open/close
   */
  handleOnKeydown(e: KeyboardEvent) {
    if (e.code === 'Enter' || e.code === 'Space') {
      this.toggleActive();
    }
  }

  /**
   * Handle on keydown
   * 1. If the panel is open and escape is keyed, close the menu and return focus to the trigger button
   * 2. Find the last item in the list. Set the last element to that item to define variable
   * 3. If the last element is defined, once Tab is selected after that the panel will close
   */
  handleOnKeydownDropdownPanel(e: KeyboardEvent) {
    if (this.isActiveDropdown === true && e.code === 'Escape') {
      /* 1 */
      this.toggleActive();
    }
  }

  /**
   * Handle click outside the component
   * 1. If the nav is already closed then we don't care about outside clicks and we
   * can bail early
   * 2. By the time a user clicks on the page the shadowRoot will almost certainly be
   * defined, but TypeScript isn't that trusting and sees this.shadowRoot as possibly
   * undefined. To work around that we'll check that we have a shadowRoot (and a
   * rendered .host) element here to appease the TypeScript compiler. This should never
   * actually be shown or run for a human end user.
   * 3. Check to see if we clicked inside the active panel
   * 4. If the panel is active and we've clicked outside of the panel then it should
   * be closed.
   */
  handleOnClickOutside = (event: MouseEvent) => {
    /* 1 */
    if (!this.isActiveDropdown) {
      return;
    }
    /* 2 */
    if (!this.shadowRoot?.host) {
      throw Error('Could not determine panel context during click handler');
    }
    /* 3 */
    const didClickInside = event.composedPath().includes(this.shadowRoot.host);
    /* 4 */
    if (this.isActiveDropdown && !didClickInside) {
      this.toggleActive();
    }
  };

  /**
   * Handles close icon click
   * 1. Set value to empty.
   * 2. Set isActive to false, so that Label can come to original position and select text field value to empty.
   * 3. Close dropdown if it is open.
   * 4. Remove list item selection from dropdown and in case of checkbox item mark them unchecked.
   * 5. Save temporary version of activeElements and set it empty
   * 6. Dispatch clear dropdown event so that any other handling if required can be done by developer.
   * @param evt Mouse Click Event
   */
  handleCloseIconClick(evt: MouseEvent) {
    evt.preventDefault();
    evt.stopPropagation();

    if (!this.enableClearSelection) {
      return false;
    }

    /* 1 */
    const lastValues = this.values;
    this.values = [];
    /* 2 */
    const lastInputValue = this.inputValue;
    this.inputValue = '';
    this.isActive = false;
    /* 3 */
    if (this.isActiveDropdown) {
      this.toggleActive();
    }

    /* 6 */
    this.dispatch({
      eventName: 'clearDropdown',
      detailObj: { lastSelectedValues: lastValues, lastInputValue }
    });
  }

  /**
   * On Lazy Loading success, update tree dropdown
   * @param evt
   */
  private _onLazyLoadingServiceSuccess = (evt: CustomEvent) => {
    this._suppressUpdateInCheckboxItems = true;
    this.checkboxItems = evt.detail.dataSource;
    this._setKeyLabelMapping(this.checkboxItems);
    this.childNodesCount = this._getChildNodesCount(this.checkboxItems);
    const checkboxItemEls = evt.detail.checkboxItemEls;
    for (const checkboxItemEl of checkboxItemEls) {
      const { isParent, checked, checkboxItem, label } = {
        isParent: checkboxItemEl.classList.contains('en-is-parent'),
        checked: checkboxItemEl.isChecked,
        checkboxItem: checkboxItemEl,
        label: `${checkboxItemEl.textContent}`.replace(/\s+/g, ' ').trim()
      };
      const selectedCheckboxHierarchy = checkboxItem.getAttribute('data-hierarchy');
      const selectedCheckboxKey = checkboxItem.getAttribute('data-key');
      const selectedCheckboxRepeatHierarchyStatusStr = checkboxItem.getAttribute('data-repeat-hierarchy');
      const selectedCheckboxRepeatHierarchyStatus = selectedCheckboxRepeatHierarchyStatusStr === '1' ? true : false;

      if (!isParent || (this.doNotApplyParentChildSelection && selectedCheckboxKey !== this.selectAllKey)) {
        if (checked) {
          const newValue = {
            label,
            key: selectedCheckboxKey,
            hierarchy: selectedCheckboxHierarchy,
            doesThisLabelExistInAnotherHierarchy: selectedCheckboxRepeatHierarchyStatus
          };
          this.values = this.enableSingleSelect ? [newValue] : [...this._getUniqueValues([...this.values, newValue])];
          this.inputValue = this._getInputValueFromValues(this.values);
        } else {
          const findKeyOrLabelIndex = this.values.findIndex((value) => {
            if (value.key) {
              return value.key === selectedCheckboxKey;
            } else return value.label === label;
          });
          if (findKeyOrLabelIndex > -1) {
            this.values.splice(findKeyOrLabelIndex, 1);
            this.values = this._getUniqueValues(this.values);
            this.inputValue = this._getInputValueFromValues(this.values);
            if (this.inputValue === '') {
              this.isActive = false;
            }
          }
        }
      } else {
        const childValues = this._getChildLabelsOfParentCheckboxItem(checkboxItem);
        const childLabelsOrKeys = childValues.map((value) => value.key || value.label);
        if (checked) {
          this.values = [...this._getUniqueValues([...this.values, ...childValues])];
          this.inputValue = this._getInputValueFromValues(this.values);
        } else {
          this.values = this.values.filter((value) => !childLabelsOrKeys.includes(value.key || value.label));
          this.inputValue = this._getInputValueFromValues(this._getUniqueValues(this.values));
          if (this.inputValue === '') {
            this.isActive = false;
          }
        }
      }
    }
    this.dispatch({
      eventName: 'treeDropdownLoadingSuccess',
      detailObj: {
        inputValue: this.inputValue,
        selectedValues: [...this.values],
        checkboxItemEls: [...checkboxItemEls],
        ...evt.detail
      }
    });
  };

  render() {
    const componentClassNames = this.componentClassNames('en-c-tree-dropdown', {
      'en-is-disabled': this.isDisabled,
      'en-is-required': this.isRequired,
      'en-is-error': this.isError,
      'en-is-active': this.isActive === true,
      'en-is-active-dropdown': this.isActiveDropdown === true,
      'en-has-hidden-label': this.hideLabel === true,
      'en-c-tree-dropdown--align-bottom': this.align === 'bottom',
      'en-c-tree-dropdown--align-top': this.align === 'top'
    });

    return html`
      <div class="${componentClassNames}">
        <div class="en-c-tree-dropdown__container">
          <${this.textFieldEl}
            class="en-c-dropdown__input"
            .alignChipsToRight=${this.dropdownVersion === 'v2'}
            paddingEndOffset=${this.enableClearSelection && !!this.inputValue ? 24 : 0}
            type="text"
            label="${this.label}"
            id="${this.fieldId}"
            name="${ifDefined(this.name)}"
            variant="${this.variant}"
            value="${ifDefined(this.enableSingleSelect ? this.inputValue : this.childNodesCount > 0 && this.values.length === this.childNodesCount ? this.selectAllText : '')}"
            values="${ifDefined(this.dropdownVersion === 'v2' ? (this.inputValue === '' ? '' : `${this.values.length} selected`) : this.enableSingleSelect ? undefined : this.values.length !== this.childNodesCount ? this.inputValue || '' : undefined)}"
            chipInfos="${ifDefined(this.dropdownVersion === 'v2' ? undefined : this.enableSingleSelect ? undefined : this.values.length !== this.childNodesCount ? this.values?.map((value) => value.hierarchy)?.join(',') || '' : undefined)}"
            chipTexts="${ifDefined(this.dropdownVersion === 'v2' ? undefined : this.enableSingleSelect ? undefined : this.values.length !== this.childNodesCount ? this.values?.map((value) => value.label)?.join(',') || '' : undefined)}"
            chiptooltipTexts="${ifDefined(this.dropdownVersion === 'v2' ? undefined : this.enableSingleSelect ? undefined : this.values.length !== this.childNodesCount ? this._getInputValueFromValues(this.values) || '' : undefined)}"
            .showTooltipOnMenuChip=${false}
            ?hideLabel="${this.hideLabel}"
            ?isRequired="${this.isRequired}"
            .isReadonly=${true}
            ?isOptional="${this.isOptional}"
            ?isDisabled="${this.isDisabled}"
            ?isError="${this.isError}"
            aria-describedby="${ifDefined(this.ariaDescribedBy)}"
            placeholder="${ifDefined(this.placeholder)}"
            @click=${this.toggleActive}
            @keydown=${this.handleOnKeydown}
            ?isActive="${this.isActive}"
            enableSlotAfterClick=${true}
            chipsMinWidth=${this.dropdownVersion === 'v1' ? this.chipsMinWidth : 'fit-content'}
            chipsMaxWidth=${this.dropdownVersion === 'v1' ? this.chipsMaxWidth : 'fit-content'}
            chipVariant=${this.dropdownVersion === 'v2' ? 'tertiary' : this.chipVariant}
            .showCounterChipDropdownMenu=${this.dropdownVersion === 'v2' ? false : this.showCounterChipDropdownMenu}
            chipDropdownMenuMaxHeight=${this.chipDropdownMenuMaxHeight}
            .isChipDismissible=${this.dropdownVersion === 'v2' ? false : this.isChipDismissible && !this.isReadonly}
            @chipDismiss=${this.dropdownVersion === 'v2' ? () => false : this.handleChipDismiss}
            triggerMenuPanelWidth=${this.triggerMenuPanelWidth}
          >
            ${
              this.slotNotEmpty('before-icon')
                ? html`<div slot="before">
                    <slot name="before-icon"></slot>
                  </div>`
                : html``
            }
            ${
              this.enableClearSelection && this.dropdownVersion === 'v1'
                ? html`<div slot="after" style="display:flex;align-items:center">
              ${
                this.inputValue
                  ? html`<${this.buttonEl} ?hideText=${true} @click=${this.handleCloseIconClick} variant="quaternary">
                <${this.iconCloseEl} slot="after" size="md"></${this.iconCloseEl}>
              </${this.buttonEl}>`
                  : html``
              }
              <${this.iconChevronDownEl} slot="after" class="en-c-tree-dropdown__icon-arrow"></${this.iconChevronDownEl}>
            </div>`
                : html`<${this.iconChevronDownEl} slot="after" class="en-c-tree-dropdown__icon-arrow"></${this.iconChevronDownEl}>`
            }

          </${this.textFieldEl}>
          <${this.treeDropdownPanelEl} @keydown=${this.handleOnKeydownDropdownPanel}
            class=${classMap({ 'en-c-tree-dropdown__panel': true, 'en-c-hide': !this.isActiveDropdown })}
            .checkboxItems=${this.checkboxItems}
            .enableSelectAll=${this.enableSelectAll}
            selectAllLabel=${this.selectAllLabel}
            selectAllKey="${this.selectAllKey}"
            selectAllIcon=${this.selectAllIcon}
            .selectAllDefaultExpanded=${this.selectAllDefaultExpanded}
            .showResetButton=${this.showResetButton}
            .showDoneButton=${this.showDoneButton}
            .enableSearch=${this.enableSearch}
            .caseInSensitiveSearch=${this.caseInSensitiveSearch}
            .listView=${this.listView}
            .enableSingleSelect=${this.enableSingleSelect}
            .isParentInListSelectable=${this.isParentInListSelectable}
            size=${this.treeCheckboxSize}
            .disableParentSelectionOnChildSelection=${this.disableParentSelectionOnChildSelection}
            @reset=${this.handleTreeReset}
            @done=${this.toggleActive}
            @checked=${this.handleTreeCheckboxChecked}
            style="--en-c-tree-dropdown-panel-body-max-height:${this.treeDropdownPanelBodyMaxHeight};--en-c-tree-dropdown-panel-min-width:${this.treeDropdownPanelMinWidth}"
            dropdownVersion="${this.dropdownVersion}"
            .restrictChipsAreaHeight=${this.restrictChipsAreaHeight}
            chipsAreaMaxHeight="${this.chipsAreaMaxHeight}"
            .enableClearSelection=${this.enableClearSelection}
            chipType="${this.chipType}"
            .areChipsDismissible="${this.areChipsDismissible}"
            chipsMaxWidth="${this.chipsMaxWidth}"
            chipsMinWidth="${this.chipsMinWidth}"
            chipVariant="${this.chipVariant}"
            dropdownInputValue="${this.values?.map((value) => value.label)?.join(',') || ''}"
            chipInfos="${this.values?.map((value) => value.hierarchy)?.join(',') || ''}"
            chiptooltipTexts="${this._getInputValueFromValues(this.values) || ''}"
            chipTexts="${this.values?.map((value) => value.label)?.join(',') || ''}"
            @chipDismiss=${this.handleChipDismiss}
            @clearSelection=${this.handleClearSelection}
            .doNotApplyParentChildSelection=${this.doNotApplyParentChildSelection}
            .enableLazyLoading=${this.enableLazyLoading}
            .showLoaderOnLazyLoading=${this.showLoaderOnLazyLoading}
            .showSmallLoader=${this.showSmallLoader}
            .showInvertedLoader=${this.showInvertedLoader}
            .lazyLoadingService=${this.lazyLoadingService}
            .lazyLoadingScrollOffset=${this.lazyLoadingScrollOffset}
            @lazyLoadingServiceSuccess=${this._onLazyLoadingServiceSuccess}
            .disableDoneButton=${this.disableDoneButton}
            .disableResetButton=${this.disableResetButton}
          >
          </${this.treeDropdownPanelEl}>
        </div>
        ${
          this.fieldNote || this.slotNotEmpty('field-note')
            ? html`
              <slot name="field-note">
                <${this.fieldNoteEl} ?isDisabled=${this.isDisabled} id=${ifDefined(this.ariaDescribedBy)}> ${this.fieldNote} </${this.fieldNoteEl}>
              </slot>
            `
            : html``
        }
        ${
          (this.errorNote || this.slotNotEmpty('error')) && this.isError
            ? html`
              <slot name="error">
                <${this.fieldNoteEl} ?isDisabled=${this.isDisabled} ?isError=${true}> ${this.errorNote} </${this.fieldNoteEl}>
              </slot>
            `
            : html``
        }
      </div>
    ` as TemplateResult<1>;
  }
}

if ((globalThis as any).enAutoRegistry === true && customElements.get(ENTreeDropdown.el) === undefined) {
  customElements.define(ENTreeDropdown.el, ENTreeDropdown);
}

declare global {
  interface HTMLElementTagNameMap {
    'en-tree-dropdown': ENTreeDropdown;
  }
}
