import { ENTextPassage } from "en-react/dist/src/components/TextPassage"
import { useState } from "react"
import { retryUpgradeAllConnectors } from "src/components/AppContainer/Banners/UpgradeBanner/UpgradeBanner.service"
import CopyIconWithTooltip from "src/components/CopyIconWithTooltip"
import { DataGridLoader } from "src/components/DataGrid/DataGridLoader"
import { CUSTOMER_ADMIN_HOSTS_FAILED_COMMAND } from "src/constants"
import useFetch from "src/hooks/useFetch"
import Alert from "src/shared/components/Alert"
import ErrorContainer from "src/shared/components/ErrorContainer"
import Modal from "src/shared/components/Modal"
import theme from "src/theme"
import { addEllipsis } from "src/utils"
import { InstanceType } from "../ConnectorExpandedView/useConnectorExpandedView"
import { useRetryConnectorInstanceUpgradeStyles } from "./RetryConnectorInstanceUpgrade.styles"

interface RetryConnectorInstanceUpgradeProps {
  onModalClose: () => void
  instanceData: Pick<InstanceType, "name" | "upgradeFailedMessage">
  connectorName: string
}

type CommandResponseType = {
  command: string
}

const RetryConnectorInstanceUpgrade: React.FC<RetryConnectorInstanceUpgradeProps> = ({
  onModalClose,
  instanceData,
  connectorName,
}) => {
  const classes = useRetryConnectorInstanceUpgradeStyles()

  const { name } = instanceData

  const [error, setError] = useState("")
  const [isLoading, setLoading] = useState(false)

  const { data: commandData, isLoading: commandLoading } = useFetch<CommandResponseType, CommandResponseType>({
    apiUrl: CUSTOMER_ADMIN_HOSTS_FAILED_COMMAND,
  })

  const command = commandData?.command || ""

  const handleRetryClick = () => {
    setError("")
    setLoading(true)
    retryUpgradeAllConnectors()
      .then(onModalClose)
      .catch((err) => {
        setLoading(false)
        setError(err?.message)
      })
  }

  return (
    <Modal
      title="Upgrade Failed (Packaged or Containerized)"
      onOk={handleRetryClick}
      onOkProps={{
        title: "Retry Upgrade",
        isLoading,
        disabled: isLoading,
      }}
      onCancel={() => {
        onModalClose()
        setError("")
      }}
      onCancelProps={{ disabled: isLoading }}
      contentClass={classes.contentClass}
      width={686}
      showFooter={!commandLoading}
    >
      {commandLoading ? (
        <DataGridLoader />
      ) : (
        <div className={classes.root}>
          <Alert
            variant="warning"
            content={
              <>
                <strong>Issue encountered during upgrade</strong>
                <br />
                As a result, the system will pause the upgrade process has paused for the remaining instances.
              </>
            }
          />

          <ENTextPassage className={classes.commandText}>
            Run the following command(s) in your connector to resolve the error in upgrading your instance{" "}
            <strong>{name}</strong> of Service Connector <strong>{connectorName}</strong>.
          </ENTextPassage>

          <ENTextPassage>
            <strong>Containerized Service Connector</strong>
          </ENTextPassage>

          <div className={classes.leftBorder}>
            <div className={classes.commandContainer}>
              <ENTextPassage>
                <em className={classes.text}>
                  {addEllipsis({ text: "sudo docker exec -it zt-connector sh", count: 180 })}
                </em>
              </ENTextPassage>

              <CopyIconWithTooltip
                text={command || ""}
                iconColor={theme.color.content.default}
                tooltipCssPosition="absolute"
              />
            </div>

            <div className={classes.commandContainer}>
              <ENTextPassage>
                <em className={classes.text}>{addEllipsis({ text: command, count: 180 })}</em>
              </ENTextPassage>

              <CopyIconWithTooltip
                text={command || ""}
                iconColor={theme.color.content.default}
                tooltipCssPosition="absolute"
              />
            </div>
          </div>

          <ENTextPassage>
            <strong>Packaged Service Connector</strong>
          </ENTextPassage>

          <div className={classes.leftBorder}>
            <div className={classes.commandContainer}>
              <ENTextPassage>
                <em className={classes.text}>{addEllipsis({ text: command, count: 180 })}</em>
              </ENTextPassage>

              <CopyIconWithTooltip
                text={command || ""}
                iconColor={theme.color.content.default}
                tooltipCssPosition="absolute"
              />
            </div>
          </div>

          <ErrorContainer message={error} open={!!error} handleClose={() => setError("")} />
        </div>
      )}
    </Modal>
  )
}

export default RetryConnectorInstanceUpgrade
