import { createUseStyles } from "react-jss"

export const useTroubleshootingStepStyles = createUseStyles((theme) => ({
  root: {
    display: "flex",
    gap: 24,
    paddingBottom: 24,
  },
  verticalLine: {
    height: "100%",
    padding: "8px 0",
    display: "flex",
    justifyContent: "center",
    "& div": {
      width: 1,
      backgroundColor: theme.color.border.divider,
    },
  },
  stepContent: {
    display: "flex",
    gap: 6,
    marginTop: 4,
  },
  statusCircle: {
    width: 20,
    height: 20,
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginRight: 6,
    marginTop: 2,
  },
  success: {
    backgroundColor: theme.color.background.successDefault,
  },
  failed: {
    backgroundColor: theme.color.background.dangerDefault,
  },
  inProgress: {
    backgroundColor: theme.color.neutralDark[9],
  },
  headingContainer: {
    display: "flex",
    gap: 24,
    alignItems: "center",
  },
  heading: {
    fontSize: 16,
    fontWeight: 600,
    color: theme.color.content.secondary,
    paddingBottom: 4,
  },
  currentHeading: {
    color: theme.color.content.accentDefault,
  },
  text: {
    fontSize: 14,
    color: theme.color.content.secondary,
  },
  cursorPointer: {
    cursor: "pointer",
  },
  textWithIcon: {
    display: "flex",
    alignItems: "center",
    gap: 8,
  },
}))
