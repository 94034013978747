import { createUseStyles } from "react-jss"

export const useRemoveModalStyles = createUseStyles((theme) => ({
  titleClass: {
    color: theme.color.error.main,
  },
  loader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: 170,
  },
  loaderGif: {
    height: 82,
  },
}))
