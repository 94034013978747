import { ALERTS } from "src/constants/apiUrlsCraas"
import { REQUEST_HEADERS } from "src/services"
import { getExternalApiUrl } from "src/utils"

type UseAlertsType = {
  shouldFetch?: boolean
  startTime: number
  endTime: number
  page?: number
  limit?: number
  refreshInterval?: number
  filterQuery?: string
  query?: string
}

export const getExternalApiRdc = (server: string) => {
  const envUrl: string = getExternalApiUrl() ?? ""
  let API_URL = envUrl

  if (envUrl && envUrl !== "") {
    let envDomain = envUrl.split(".")
    envDomain[0] += `-${server}`
    API_URL = envDomain.join(".")
  }

  return API_URL
}

/**
 * Fetches alerts from the external API with the specified parameters.
 *
 * @param {Object} params - The parameters for fetching alerts.
 * @param {number} [params.page=1] - The page number to fetch.
 * @param {number} [params.limit=50] - The number of alerts per page.
 * @param {Date} params.startTime - The start time for the alerts.
 * @param {Date} params.endTime - The end time for the alerts.
 * @returns {Promise<Object>} A promise that resolves to the JSON response containing the alerts.
 */
export const getAlerts = async ({ page = 1, limit = 50, startTime, endTime }: UseAlertsType) => {
  const API_URL = getExternalApiRdc("cas")

  const isoStartTime = new Date(startTime).toISOString()
  const isoEndTime = new Date(endTime).toISOString()

  const url = `${API_URL}${ALERTS}?startTime=${isoStartTime}&endTime=${isoEndTime}&page=${page}&limit=${limit}&order=DESC`

  const response = await fetch(url, {
    credentials: "include",
    headers: REQUEST_HEADERS,
  })

  return response.json()
}
