export const CRAAS_BASE_URL = `${process.env.NODE_ENV === "development" ? "" : window.location.origin}/craas`
const CONTROLLER = "/controller"
const RADIUS_MANAGER = "/radius-manager"
export const RADSEC_PROXY = `${RADIUS_MANAGER}/radsec/proxies`
export const RADSEC_PROXY_COMMANDS = `${RADIUS_MANAGER}/radsec/proxies/installation-command`
export const RADSEC_PROXY_TROUBLESHOOT_COMMAND = `/craas${RADIUS_MANAGER}/radsec/proxies/troubleshoot-command`
export const RADSEC_PROXY_MIGRATION_COMMAND = `/craas${RADIUS_MANAGER}/radsec/proxies/migration-command`
export const RADSEC_PROXY_SHOULD_INITIATE = `/craas${RADIUS_MANAGER}/radsec/proxies/should-initiate`
export const RADSEC_WITHOUT_PROXY = `${RADIUS_MANAGER}/without-proxy`
export const DISABLE_RADSEC_PROXY = (proxyId: string) => `${RADIUS_MANAGER}/radsec/proxies/disable/${proxyId}`
export const ENABLE_RADSEC_PROXY = (proxyId: string) => `${RADIUS_MANAGER}/radsec/proxies/enable/${proxyId}`
export const RENEW_RADSEC_PROXY = (proxyId: string) => `${RADIUS_MANAGER}/renew-proxy-certificate/${proxyId}`
export const REMOVE_RADSEC_PROXIES = (proxyId: string) => `${RADIUS_MANAGER}/radsec/proxies/${proxyId}`
export const FORCE_REMOVE_RADSEC_PROXIES = (proxyId: string) => `${RADIUS_MANAGER}/radsec/proxies/${proxyId}/force`
export const UPDATE_RADSEC_PROXY = (proxyId: string) => `${RADIUS_MANAGER}/radsec/proxies/${proxyId}`
export const RADSEC_PROXY_REMOVE_COMMAND = `${RADIUS_MANAGER}/radsec/proxies/remove-command`
export const GET_RADSEC_PROXY_REMOVE_COMMAND = (proxyId: string) => `${RADSEC_PROXY_REMOVE_COMMAND}?id=${proxyId}`
export const RADSEC_PROXY_CERTIFICATE_RENEWAL_COMMANDS = (proxyId: string) =>
  `${RADIUS_MANAGER}/radsec/proxies/update-command/${proxyId}`
export const RADSEC_PROXY_ALLOWED_DEVICES = (proxyId: string) =>
  `${RADIUS_MANAGER}/radsec/proxies/${proxyId}/network-devices-associations`

export const GET_DEVICE_ASSOCIATIONS = (deviceId: string) => `${CONTROLLER}/devices/associations/${deviceId}`

export const RADIUS_SERVERS_BASE = `/cnac/network-policy-engine/api/v1/npe/external/devices/`
export const RADIUS_SERVERS = (type: string) => `${RADIUS_SERVERS_BASE}?type=${type}`
export const RADIUS_SERVERS_DETAIL = `${RADIUS_MANAGER}/raas-regions/`
export const RADIUS_SERVERS_CERT_GENERATED_ON = `${RADIUS_MANAGER}/radsec/native`
export const RADIUS_SERVERS_GENERATE_CERTS = `${RADIUS_MANAGER}/radsec/native/generate-certificates/download`
export const RADIUS_SERVERS_CERTS_LIST = `${RADIUS_MANAGER}/radsec/native/server-certificates/`
export const CERTIFICATE_STATUS = `${RADIUS_MANAGER}/certificate-management/status`
export const CA_CERTIFICATE = `${RADIUS_MANAGER}/certificate-management/ca/attributes`
export const UPDATE_CA_CERTIFICATE = `${RADIUS_MANAGER}/certificate-management/ca`
export const RESET_CERTIFICATES = `${RADIUS_MANAGER}/certificate-management/reset`
export const SERVER_CERTIFICATE = `${RADIUS_MANAGER}/certificate-management/server/attributes`
export const INVALIDATE_CERTIFICATE = `${RADIUS_MANAGER}/certificate-management/server/invalidate`
export const DOWNLOAD_CA_CERTIFICATE = `${RADIUS_MANAGER}/certificate-management/ca/download`
export const DOWNLOAD_DEFAULT_CA_CERTIFICATE = `${RADIUS_MANAGER}/certificate-management/ca`
export const DOWNLOAD_SERVER_CERTIFICATE = `${RADIUS_MANAGER}/certificate-management/certificate/download`
export const UPDATE_SERVER_CERTIFICATES = `${RADIUS_MANAGER}/certificate-management/server`
export const MATCHING_CRITERIA_FOR_CLIENTS = `${RADIUS_MANAGER}/certificate-management/attributes-criteria`
export const CONNECT_WITH_OCSP_RESPONDER = `${RADIUS_MANAGER}/certificate-management/ocsp/responder`
export const UPDATE_DEFAULT_STRATEGY = (strategyName: string) =>
  `${RADIUS_MANAGER}/certificate-management/attributes-criteria/fallback-strategy/${strategyName}`
export const GET_DEFAULT_STRATEGY = `${RADIUS_MANAGER}/certificate-management/attributes-criteria/fallback-strategy`
export const DELETE_CONNECT_WITH_OCSP_RESPONDER = (id: string) =>
  `${RADIUS_MANAGER}/certificate-management/ocsp/responder/${id}`
export const CUSTOMER_ADMIN_DEVICES_RADSEC_PROXIES_STATUS = `/craas${RADIUS_MANAGER}/radsec/proxies/status`

// MONITOR
export const TROUBLESHOOTRUNTEST = "/craas/craas-auth/policy-simulator"
export const ALERTS = "/alert/v1/alerts"

// RADIUS TEMPLATE
export const GET_RADIUS_VSA = `/craas${RADIUS_MANAGER}/raas-regions/get-radius-attributes`

// PACKEt CAPTURE
export const PACKET_CAPTURE_RUN_TEST = `/craas${RADIUS_MANAGER}/packet-capture/initialize`
export const PACKET_CAPTURE_STATUS = `/craas${RADIUS_MANAGER}/packet-capture/status`
export const STOP_PACKET_CAPTURE = `/craas${RADIUS_MANAGER}/packet-capture/stop`
export const DOWNLOAD_PACKET_CAPTURE = `/craas${RADIUS_MANAGER}/packet-capture/download`
