import { DOWNLOAD_PACKET_CAPTURE, PACKET_CAPTURE_STATUS } from "src/constants/apiUrlsCraas"
import { getRequest } from "src/services"

export const getPacketCaptureStatus = async () => {
  const resp = await getRequest(PACKET_CAPTURE_STATUS)
  return resp?.data
}

export const downloadPacketCapture = async () => {
  const resp = await getRequest(DOWNLOAD_PACKET_CAPTURE, { "Content-Type": "application/zip" }, true, "blob")
  return resp?.data
}
