import { yupResolver } from "@hookform/resolvers/yup"
import dayjs, { Dayjs } from "dayjs"
import objectSupport from "dayjs/plugin/objectSupport"
import { default as timezone, default as timezonePlugin } from "dayjs/plugin/timezone"
import { ENTextPassage } from "en-react/dist/src/components/TextPassage"
import { useCallback, useEffect, useMemo, useState } from "react"
import { Controller, useForm } from "react-hook-form"
import { useDispatch } from "react-redux"
import ZtnaEnTimePicker from "src/components/ZtnaEnTimePicker"
import { COUNTRIES_LIST, POLICY_EVALUATION, SEARCH_APPLICATIONS, USER_DEVICES, WORK_SPACE_USERS } from "src/constants"
import { TIME_ZONES_LIST } from "src/constants/dropdownsData"
import useENDropdownPaginatedApiCall from "src/hooks/useENDropdownPaginatedApiCall"
import useFetch from "src/hooks/useFetch"
import { ApplicationGroupApplicationType } from "src/pages/ApplicationGroups/AddEditApplicationGroup/AddEditApplicationGroup.types"
import { UseSearchUsersResponseType } from "src/pages/UserGroups/AddEditUserGroup/AddEditUserGroup.type"
import CircularLoader from "src/shared/components/CicularLoader/CircularLoader"
import { ZtnaChipMultiSelect } from "src/shared/components/FormComponents"
import ENPaginatedSingleSelect from "src/shared/components/FormComponents/ENPaginatedSingleSelect/ENPaginatedSingleSelect"
import EnRadioGroup from "src/shared/components/FormComponents/EnRadioGroup"
import EnSelect from "src/shared/components/FormComponents/EnSelect"
import ZtnaButton from "src/shared/components/ZtnaButton"
import { setToasterState } from "src/store/ui/uiSlice"
import { DropDownType, OptionType } from "src/utils/utils.types"
import { addApplicationPolicyEvaluationValidation } from "src/utils/validations"
import { applicationPolicyEvaluation } from "./ApplicationPolicyEvaluationForm.service"
import { useApplicationPolicyEvaluationFormStyles } from "./ApplicationPolicyEvaluationForm.styles"
import { parseSelectedUserOption, parseUsersDropdown } from "./ApplicationPolicyEvaluationForm.utils"

type PolicyEvaluationDataType = {
  id: string
  device: {
    id: string
    name: string
    deviceType: string
    macAddress: string
    macOui: string
  }
  service: {
    id: string
    name: string
    useOriginalDomain: boolean
  }
  userId: number
  endTime: string | null
  timeZone: string
  createdAt: string
  startTime: string | null
  userEmail: string
  accessMode: "Agentless" | "Agentbased"
  workspaceId: number
  locationBasedCondition: string[]
}

export type ApplicationPolicyEvaluationFormType = {
  user: { id: string | number; email: string } | null
  application: string
  device: string
  accessMode: string
  location: (OptionType & DropDownType)[]
  timeZone: string
  startTime: Dayjs | null
  endTime: Dayjs | null
}

dayjs.extend(objectSupport)
dayjs.extend(timezone)

interface Props {
  isFetchingEvaluationResult: boolean
  setIsFetchingEvaluationResult: (val: boolean) => void
  setFetchEvaluationResult: (val: boolean) => void
  setEvaluationId: (val: string) => void
  setIsAgentless: (val: boolean) => void
  setIsDeviceUnselected: (val: boolean) => void
  setShowDnsResolution: (val: boolean) => void
}

const ApplicationPolicyEvaluationForm: React.FC<Props> = ({
  setFetchEvaluationResult,
  isFetchingEvaluationResult,
  setIsFetchingEvaluationResult,
  setEvaluationId,
  setIsAgentless,
  setIsDeviceUnselected,
  setShowDnsResolution,
}) => {
  const classes = useApplicationPolicyEvaluationFormStyles()

  const [countryList, setCountryList] = useState<(DropDownType & OptionType)[]>([])
  const isCountryListLoading = !countryList?.length
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [selectedUserExists, setSelectedUserExists] = useState(false)

  dayjs.extend(timezonePlugin)

  const timezoneOptions = useMemo(() => {
    return TIME_ZONES_LIST.map((item) => ({
      value: item.tzCode,
      label: item.label,
    }))
  }, [TIME_ZONES_LIST])

  const anyLocationObj = { label: "Any Location", value: "", id: "any-location-key", name: "Any Location" }

  useEffect(() => {
    setTimeout(() => {
      setCountryList([
        anyLocationObj,
        ...COUNTRIES_LIST.map((item) => ({
          id: item.value,
          label: item.label,
          value: item.value,
          name: item.label,
        })),
      ])
    }, 0)
  }, [])

  const {
    data: policyEvaluationData,
    isLoading: isPolicyEvaluationLoading,
    mutate,
  } = useFetch<PolicyEvaluationDataType>({
    apiUrl: POLICY_EVALUATION,
  })

  const {
    isLoading: loadingUsers,
    loadOptions: loadUserOptions,
    loadOptionsById,
  } = useENDropdownPaginatedApiCall<{
    payload?: { users: UseSearchUsersResponseType[]; from: number; to: number; total: number }
  }>({
    apiUrl: `${WORK_SPACE_USERS}?status=ACTIVATED`,
    fetchByIdUrl: `${WORK_SPACE_USERS}?search=${policyEvaluationData?.userEmail}`,
    searchParam: "search",
    useBaseUrl: true,
    pageSize: 50,
    paginationType: "offset",
    parser: parseUsersDropdown,
    fetchByIdParser: parseSelectedUserOption,
  })

  const dispatch = useDispatch()

  const defaultValues = {
    user: null,
    application: "",
    device: "",
    accessMode: "Agentbased",
    location: [anyLocationObj],
    timeZone: "",
    startTime: null,
    endTime: null,
  }

  const {
    control,
    watch,
    formState: { errors, isValid, isDirty },
    handleSubmit,
    reset,
    getValues,
    trigger,
    setValue,
  } = useForm<ApplicationPolicyEvaluationFormType>({
    defaultValues,
    mode: "onChange",
    resolver: yupResolver(addApplicationPolicyEvaluationValidation),
  })

  const { user, timeZone, location, startTime, endTime, accessMode, application, device } = watch()

  const { data: searchApplicationsData, isLoading: isSearchApplicationsLoading } = useFetch<
    ApplicationGroupApplicationType[]
  >({
    apiUrl: `${SEARCH_APPLICATIONS}?q=''&excludeSaas=true${
      accessMode === "Agentless" ? "&excludeCustomApps=true" : ""
    }`,
  })

  const getTimeByTimezone = (
    timezone: string,
    timeValue?: string | Dayjs,
    keepLocal: boolean = false,
  ): Dayjs | null => {
    if (!timezone) return null

    const zeroOffset = TIME_ZONES_LIST.filter((zone) => zone.utc === "+00:00").map((zone) => zone.tzCode)

    dayjs.tz.setDefault(timezone)

    if (zeroOffset.includes(timezone)) {
      return timeValue ? dayjs.utc(timeValue) : dayjs.utc()
    } else {
      return timeValue ? dayjs(timeValue).tz(timezone, keepLocal) : dayjs().tz(timezone, keepLocal)
    }
  }

  const isPolicyEvaluationDataNonEmpty = useMemo(() => {
    return policyEvaluationData && Object.keys(policyEvaluationData).length > 0
  }, [policyEvaluationData])

  const getSelectedUserData = async () => {
    const response = await loadOptionsById?.()
    if (response) {
      setSelectedUserExists(response?.selectedOptions[0]?.name === policyEvaluationData?.userEmail)
    }
  }

  useEffect(() => {
    getSelectedUserData()
  }, [policyEvaluationData?.userEmail])

  useEffect(() => {
    if (policyEvaluationData && countryList.length) {
      setValue(
        "user",
        selectedUserExists ? { id: policyEvaluationData?.userId, email: policyEvaluationData?.userEmail } : null,
      )

      setValue("application", policyEvaluationData?.service?.id || "")
      setValue("device", policyEvaluationData?.device?.id || "")
      setIsDeviceUnselected(!policyEvaluationData?.device?.id)
      setShowDnsResolution(
        policyEvaluationData?.accessMode === "Agentless" && policyEvaluationData?.service?.useOriginalDomain,
      )

      setValue(
        "location",
        !policyEvaluationData?.locationBasedCondition?.length
          ? [anyLocationObj]
          : policyEvaluationData?.locationBasedCondition
              .map((item) => countryList.find((i) => i.value === item))
              .filter((item): item is OptionType & DropDownType => item !== undefined && item !== null), // Ensure valid items
      )

      setValue("timeZone", policyEvaluationData?.timeZone || "")

      setValue("startTime", getTimeByTimezone(policyEvaluationData?.timeZone))

      setValue(
        "endTime",
        policyEvaluationData?.endTime
          ? getTimeByTimezone(policyEvaluationData?.timeZone, policyEvaluationData?.endTime, false)
          : null,
      )

      setValue("accessMode", policyEvaluationData?.accessMode || "Agentbased")

      if (isDirty) trigger()

      if (isPolicyEvaluationDataNonEmpty) {
        setIsFetchingEvaluationResult(true)
        setFetchEvaluationResult(true)
        setEvaluationId(policyEvaluationData.id)
        setIsAgentless(policyEvaluationData.accessMode === "Agentless")
      }
    }
  }, [countryList.length, JSON.stringify(policyEvaluationData), selectedUserExists])

  type SearchDevicesType = {
    id: string | number
    name: string
    macAddress: string
    deviceType: string
    macOui: string
  }

  const parser = (devices: SearchDevicesType[]) =>
    devices?.map((item) => ({
      ...item,
      deviceLabel: `${item.name} (${item.macAddress} - ${item.deviceType})`,
    }))

  const { data: devicesData } = useFetch<SearchDevicesType[]>({
    apiUrl: user?.id && accessMode !== "Agentless" ? USER_DEVICES(user?.id) : "",
    parser,
  })

  const onEvaluate = (data: ApplicationPolicyEvaluationFormType) => {
    setIsSubmitting(true)
    applicationPolicyEvaluation(data)
      .then(() => {
        setIsSubmitting(false)
        dispatch(
          setToasterState({
            message: "Application policy evaluated successfully.",
            type: "success",
            autoHide: true,
          }),
        )
        mutate()
        setIsFetchingEvaluationResult(true)
        setFetchEvaluationResult(true)
      })
      .catch((err) => {
        dispatch(
          setToasterState({
            message: err?.errors || err?.message,
            type: "danger",
            autoHide: false,
          }),
        )
        setIsSubmitting(false)
      })
  }

  const onUserChange = useCallback(
    (val: { label: string; value: string }) => {
      setValue("user", { id: val?.value, email: val?.label })
      setValue("device", defaultValues.device)
      trigger("device")
    },
    [defaultValues.device],
  )

  const removeEntryById = (array: any[], idToRemove: string) => array.filter((entry) => entry.value !== idToRemove)

  const isFormDisabled = isPolicyEvaluationLoading || isSubmitting || isFetchingEvaluationResult

  return (
    <div className={classes.root}>
      <div className={classes.form}>
        <>
          <div className={classes.fieldWrapper}>
            <div>
              <Controller
                name="user"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <ENPaginatedSingleSelect
                    label="User"
                    placeholder="Select User"
                    optionValue="id"
                    optionLabel="email"
                    value={value}
                    isSearchable
                    onChange={onUserChange}
                    loadOptions={loadUserOptions}
                    isLoading={loadingUsers}
                    fullObjectSelection
                    error={errors.user?.email?.message}
                    disabled={isFormDisabled}
                    enableServerSideSearch
                    returnObject
                  />
                )}
              />
            </div>

            <div className={classes.select}>
              <Controller
                name="application"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <EnSelect
                    label="Application"
                    options={searchApplicationsData || []}
                    optionValue="id"
                    optionLabel="name"
                    value={value}
                    onChange={onChange}
                    isSearchable
                    error={errors.application?.message}
                    disabled={isFormDisabled}
                    isLoading={isSearchApplicationsLoading}
                  />
                )}
              />
            </div>
          </div>

          <div className={classes.fieldWrapper}>
            <EnRadioGroup
              label="Access Mode"
              name="accessMode"
              value={accessMode}
              isDisabled={isFormDisabled}
              onChange={(option) => {
                option === "Agentless" && setValue("device", "")
                setValue("application", "", { shouldValidate: true })
                setValue("accessMode", option, { shouldValidate: true })
              }}
              options={[
                { id: "radio-agentlessMode", value: "Agentless", label: "Agentless" },
                { id: "radio-agentbasedMode", value: "Agentbased", label: "Agent-Based" },
              ]}
              className={classes.accessModeRadioGroup}
            />

            <div className={classes.select}>
              <Controller
                name="device"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <EnSelect
                    label="Device (Optional)"
                    options={devicesData || []}
                    optionValue="id"
                    optionLabel="deviceLabel"
                    value={value}
                    onChange={onChange}
                    disabled={user?.id === "" || isFormDisabled || accessMode === "Agentless"}
                  />
                )}
              />
              <ENTextPassage>
                <span className={classes.agentMessage}>
                  Ensure the Universal ZTNA Agent is connected and online prior to starting Application Troubleshooting.
                </span>
              </ENTextPassage>
            </div>
          </div>

          <div className={classes.fieldWrapper}>
            <div className={classes.select}>
              <Controller
                control={control}
                name="location"
                render={({ field: { onChange, value } }): JSX.Element => (
                  <ZtnaChipMultiSelect
                    label="User Location"
                    id="location-multiselect"
                    onChange={onChange}
                    placeholder="Select"
                    options={countryList || []}
                    value={value}
                    disabled={isFormDisabled}
                    isLoading={isCountryListLoading}
                    isOptionDisabled={(_option, selectValue) => selectValue?.some((item) => !item.value)}
                    onRemoveOption={(option) => {
                      const { location } = getValues()
                      const newLocationValue = removeEntryById(location, option.value)
                      setValue("location", newLocationValue, {
                        shouldDirty: true,
                        shouldValidate: true,
                      })
                    }}
                    closeMenuOnSelect={location?.some((item) => item?.label === anyLocationObj.label)}
                  />
                )}
              />
            </div>

            <div className={classes.timeBasedConditionWrapper}>
              <div className={classes.select}>
                <Controller
                  name="timeZone"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <EnSelect
                      label="Time-Based Condition: Time Zone (Optional)"
                      isSearchable
                      options={timezoneOptions}
                      optionValue="value"
                      optionLabel="label"
                      value={value}
                      onChange={(val) => {
                        onChange(val)
                        if (!startTime) {
                          setValue("startTime", getTimeByTimezone(timeZone))
                        }
                      }}
                      disabled={isFormDisabled}
                      isClearable
                      onClearDropdown={() => {
                        setValue("timeZone", defaultValues.timeZone)
                        setValue("startTime", defaultValues.startTime)
                        setValue("endTime", defaultValues.endTime)
                      }}
                    />
                  )}
                />
              </div>

              <div className={classes.timeFieldWrapper}>
                <div className={classes.select}>
                  <Controller
                    control={control}
                    name="startTime"
                    render={({ field: { onChange, value } }) => (
                      <ZtnaEnTimePicker
                        id="policy-eval-start-time"
                        label="Start Time"
                        value={value}
                        onChange={onChange}
                        disabled
                        optional
                      />
                    )}
                  />
                </div>

                <div className={classes.select}>
                  <Controller
                    control={control}
                    name="endTime"
                    render={({ field: { onChange, value } }) => (
                      <ZtnaEnTimePicker
                        id="policy-eval-end-time"
                        label="End Time"
                        value={value}
                        onChange={(val: any) => {
                          const newValue = getTimeByTimezone(timeZone, val, true)
                          onChange(newValue)
                          trigger("endTime")
                        }}
                        disabled={timeZone === "" || isFormDisabled}
                        optional
                        onTimeClearSelection={() => setValue("endTime", defaultValues.endTime)}
                      />
                    )}
                  />
                </div>
              </div>
            </div>
          </div>
        </>

        <div>
          <div className={classes.formFooter}>
            {policyEvaluationData?.createdAt && (
              <ENTextPassage>
                <em className={classes.lastEvaluation}>
                  Last Evaluation Run at {dayjs(policyEvaluationData?.createdAt).format("HH:mm MM/DD/YYYY")}
                </em>
              </ENTextPassage>
            )}

            {isPolicyEvaluationDataNonEmpty && (
              <ZtnaButton
                buttonType="secondary"
                disabled={
                  isFormDisabled ||
                  (user === null &&
                    application === "" &&
                    device === "" &&
                    accessMode === "Agentbased" &&
                    location?.[0]?.value === "" &&
                    timeZone === "" &&
                    startTime === null &&
                    endTime === null)
                }
                onClick={() => reset(defaultValues, { keepDefaultValues: true })}
                title="Clear"
              />
            )}

            <ZtnaButton
              buttonType="primary"
              onClick={handleSubmit(onEvaluate)}
              title={isPolicyEvaluationDataNonEmpty ? "Re-evaluate" : "Evaluate"}
              disabled={
                isFormDisabled || !isValid || loadingUsers || isSearchApplicationsLoading || isCountryListLoading
              }
              startIcon={isSubmitting && <CircularLoader isDark />}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default ApplicationPolicyEvaluationForm
