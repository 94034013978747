import clsx from "clsx"
import { memo } from "react"
import Modal from "src/shared/components/Modal"
import { ModalProps } from "src/shared/components/Modal/Modal.types"
import CircularLoader from "../CicularLoader/CircularLoader"
import { useRemoveModalStyles } from "./RemoveModal.styles"

interface RemoveModalProps {
  isLoading?: boolean
  isDeletable?: boolean
}

type ExtendedModalProps = ModalProps & RemoveModalProps

const RemoveModal: React.FC<React.PropsWithChildren<ExtendedModalProps>> = ({
  children,
  onOkProps,
  onCancelProps = { visible: true },
  isLoading,
  isDeletable = true,
  contentClass,
  ...props
}) => {
  const classes = useRemoveModalStyles()
  const { titleClass, showFooter = true } = props
  const showModalFooter = !isLoading && showFooter
  return (
    <Modal
      {...props}
      titleClass={clsx(classes.titleClass, titleClass)}
      contentClass={contentClass}
      onCancelProps={onCancelProps}
      disableMinHeight
      onOkProps={{
        title: onOkProps?.title || "Delete",
        disabled: onOkProps?.disabled,
        class: onOkProps?.class,
        isLoading: onOkProps?.isLoading,
        buttonType: onOkProps?.buttonType ? onOkProps?.buttonType : isDeletable ? "error" : undefined,
      }}
      showFooter={!isLoading && showModalFooter}
      key={`${showModalFooter}`}
    >
      {isLoading ? (
        <div className={classes.loader}>
          <CircularLoader size="xl" />
        </div>
      ) : (
        <>{children}</>
      )}
    </Modal>
  )
}

export default memo(RemoveModal)
