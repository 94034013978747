import { TemplateResult, unsafeCSS } from 'lit';
import { property, state } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';
import { ifDefined } from 'lit/directives/if-defined.js';
import { repeat } from 'lit/directives/repeat.js';
import { html, unsafeStatic } from 'lit/static-html.js';
import { nanoid } from 'nanoid';
import register from '../../directives/register';
import PackageJson from '../../package.json';
import { ENElement } from '../ENElement';
import { ENButton } from '../button/button';
import { ENCheckbox } from '../checkbox/checkbox';
import { ENDropdownPanel } from '../dropdown-panel/dropdown-panel';
import { ENDropdownSelectionPanel } from '../dropdown-selection-panel/dropdown-selection-panel';
import { PartialDataSource } from '../dropdown/dropdown.model';
import { ENFieldNote } from '../field-note/field-note';
import { ENIconChevronDown } from '../icon/icons/chevron-down';
import { ENIconClose } from '../icon/icons/close';
import { ENListItem } from '../list-item/list-item';
import { ENList } from '../list/list';
import { ENLoadingIndicator } from '../loading-indicator/loading-indicator';
import { ENSearchForm } from '../search-form/search-form';
import { ENTextField } from '../text-field/text-field';
import { ENCheckboxItem } from './../checkbox-item/checkbox-item';
import styles from './multi-select-dropdown.scss';

/**
 * Component: en-multi-select-dropdown
 * @slot - The components content
 */
export class ENMultiSelectDropdown extends ENElement {
  static readonly el = 'en-multi-select-dropdown';

  private readonly elementMap = register({
    elements: [
      [ENDropdownPanel.el, ENDropdownPanel],
      [ENFieldNote.el, ENFieldNote],
      [ENButton.el, ENButton],
      [ENIconChevronDown.el, ENIconChevronDown],
      [ENIconClose.el, ENIconClose],
      [ENTextField.el, ENTextField],
      [ENSearchForm.el, ENSearchForm],
      [ENList.el, ENList],
      [ENCheckbox.el, ENCheckbox],
      [ENListItem.el, ENListItem],
      [ENCheckboxItem.el, ENCheckboxItem],
      [ENDropdownSelectionPanel.el, ENDropdownSelectionPanel],
      [ENList.el, ENList],
      [ENListItem.el, ENListItem],
      [ENCheckboxItem.el, ENCheckboxItem],
      [ENLoadingIndicator.el, ENLoadingIndicator]
    ],
    suffix: (globalThis as any).enAutoRegistry === true ? '' : PackageJson.version
  });

  private readonly dropdownPanelEl = unsafeStatic(this.elementMap.get(ENDropdownPanel.el));
  private readonly fieldNoteEl = unsafeStatic(this.elementMap.get(ENFieldNote.el));
  private readonly iconChevronDownEl = unsafeStatic(this.elementMap.get(ENIconChevronDown.el));
  private readonly iconCloseEl = unsafeStatic(this.elementMap.get(ENIconClose.el));
  private readonly buttonEl = unsafeStatic(this.elementMap.get(ENButton.el));
  private readonly textFieldEl = unsafeStatic(this.elementMap.get(ENTextField.el));
  private readonly searchFormEl = unsafeStatic(this.elementMap.get(ENSearchForm.el));
  private readonly checkboxItemEl = unsafeStatic(this.elementMap.get(ENCheckboxItem.el));
  private readonly dropdownSelectionPanelEl = unsafeStatic(this.elementMap.get(ENDropdownSelectionPanel.el));
  private readonly listItemEl = unsafeStatic(this.elementMap.get(ENListItem.el));
  private readonly listEl = unsafeStatic(this.elementMap.get(ENList.el));
  private readonly checkboxEl = unsafeStatic(this.elementMap.get(ENCheckbox.el));
  private readonly loadingIndicatorEl = unsafeStatic(this.elementMap.get(ENLoadingIndicator.el));
  private debounceSeed: number = null;

  static get styles() {
    return unsafeCSS(styles.toString());
  }

  /**
   * isActive
   * Label is moved to top when set to true.
   */
  @property({ type: Boolean })
  isActive?: boolean;

  /**
   * isActiveDropdown
   * 1. Dropdown is open when set to true. Close when set to false
   */
  @property({ type: Boolean })
  isActiveDropdown?: boolean;

  /**
   * The unique id of the select field
   */
  @property()
  fieldId?: string;

  /**
   * The select field's title
   */
  @property()
  title: string;

  /**
   * The select field's label
   */
  @property()
  label = 'Label';

  /**
   * The select field's name attribute
   */
  @property()
  name?: string;

  /**
   * The select field's value attribute
   */
  @property({ type: Array })
  values?: Array<string | { label: string; value: any; [key: string]: unknown }> = [];

  /**
   * Placeholder attribute
   * - Specifies a short hint that describes the expected value of an <input> element
   */
  @property()
  placeholder?: string;

  /**
   * The dropdown field note
   */
  @property()
  fieldNote?: string;

  /**
   * The dropdown error note
   */
  @property()
  errorNote?: string;

  /**
   * Aria describedby
   * 1. Used to connect the field note in select field to the select menu for accessibility
   */
  @property()
  ariaDescribedBy?: string;

  /**
   * The select field's required attribute
   */
  @property({ type: Boolean })
  isRequired?: boolean = false;

  /**
   * Optional state
   * - Specifies that a field is optional and adds the text 'optional' to the label
   */
  @property({ type: Boolean })
  isOptional?: boolean;

  /**
   * The select field's disabled attribute
   */
  @property({ type: Boolean })
  isDisabled?: boolean;

  /**
   * Error state
   */
  @property({ type: Boolean })
  isError?: boolean;

  /**
   * Readonly attribute
   * - Specifies that an input field is read-only
   */
  @property({ type: Boolean })
  isReadonly?: boolean = true;

  /**
   * Set this property to customize dropdown panel min width
   */
  @property()
  dropdownPanelMinWidth?: string = '100%';

  /**
   * Set this property to customize dropdown panel max width
   */
  @property()
  dropdownPanelMaxWidth?: string = '100%';

  /**
   * Hide the label
   */
  @property({ type: Boolean })
  hideLabel?: boolean;

  /**
   * Add a search input to the dropdown panel
   */
  @property({ type: Boolean })
  hasSearch?: boolean = false;

  /**
   * If true enable chips view in textfield else comma seperated values will be shown
   */
  @property({ type: Boolean })
  enableChipsView?: boolean = false;

  /**
   * chipVariant
   * - **default** A chip with a high contrast background
   * - **secondary** A chip with a medium contrast background
   * - **tertiary** A chip with a low contrast background
   * - **green** A chip with a green background
   * - **red** A chip with a red background
   * - **blue** A chip with a blue background
   * - **amber** A chip with a amber background
   * - **purple** A chip with a purple background
   */
  @property()
  chipVariant?: 'default' | 'secondary' | 'tertiary' | 'green' | 'red' | 'blue' | 'amber' | 'purple' | 'neutral' = 'default';

  /**
   * Max height of chips area after which if chips exceed then scroll would be shown
   */
  @property()
  chipsAreaMaxHeight: string = '8rem';

  /**
   * If true, restrict chips area height to 8rem. Default is true
   */
  @property({ type: Boolean })
  restrictChipsAreaHeight: boolean = true;

  /**
   * In case you are showing selection count chip, then this property can be used to change suffix text.
   */
  @property()
  selectionCountChipSuffixText?: string = 'selected';

  /**
   * If true, then add cross icon on dropdown which enables clearing selection. Default value is false.
   */
  @property({ type: Boolean })
  enableClearSelection?: boolean = false;

  /**
   * If true, then apply changes in list item behaviour along with checkbox.
   */
  @property({ type: Boolean })
  enableCheckbox?: boolean = false;

  /**
   * If true, then allow user to select or unselect all items at once.
   */
  @property({ type: Boolean })
  enableSelectAll?: boolean = false;

  /**
   * selectAllSize
   * - **sm** Displays 30px width/height checkbox and 14px text size
   * - **md** Displays 40px width/height checkbox and 16px text size
   * Default is sm.
   */
  @property({ type: String })
  selectAllSize?: 'md' | 'sm' = 'sm';

  /**
   * **Dropdown alignment**
   * - **bottom** Dropdown panel appears on the bottom
   * - **top** Dropdown panel appears on the top
   * Default is bottom. Relevance of setting it only when `enableDynamicPositioning` set to false.
   */
  @property()
  align?: 'bottom' | 'top' = 'bottom';

  /**
   * If set to true, align chips to right. Default is false.
   */
  @property({ type: Boolean })
  alignChipsToRight?: boolean = false;

  /**
   * If true dynamically position dropdown panel. Otherwise position according to `align` property value. Default is true.
   */
  @property({ type: Boolean })
  enableDynamicPositioning?: boolean = true;

  /**
   * It takes CSS selector for container consisting dropdown. This container height and bottom is compared with dropdown panel height and bottom and accordingly position is determined. Default value is body. Relevance of setting it only if `enableDynamicPositioning` is set to true.
   */
  @property()
  dropdownPanelContainerSelector?: string = 'body';

  /**
   * Default is 10rem i.e 160px. This property can be used to customize dropdown panel max scroll height.
   */
  @property()
  dropdownPanelMaxScrollHeight?: string;

  /**
   * **Search Input Placeholder**
   */
  @property()
  searchInputPlaceholder?: string;

  /**
   * **Search Input Label**
   */
  @property()
  searchInputLabel?: string;

  /**
   * **exactSearchMatch**
   * - If true, match exactly otherwise search case insensitive. Default is false.
   */
  @property({ type: Boolean })
  exactSearchMatch?: boolean = false;

  /**
   * **debounceIntervalInMilliSecond**
   * - You can increase or reduce debounce interval in search input. Default is 100.
   */
  @property({ type: Number })
  debounceIntervalInMilliSecond?: number = 100;

  /**
   * **selectedLocalizedSuffix**
   * - To update the "selected" suffix. Default is 'selected'.
   */
  @property()
  selectedLocalizedSuffix?: string = 'selected';

  /**
   * Set child chip max width except count chip. This property  is required to set in order to control text overflow in chip.
   */
  @property({ type: String })
  chipsMaxWidth?: string = '95px';

  /**
   * Set child chip min width except count chip. This property is required to set in order to automatically determine chips visible and text overflow.
   */
  @property({ type: String })
  chipsMinWidth?: string = '95px';

  /**
   * Variant
   * - **primary** renders the multi-select-dropdown to be used on backgrounds with var(--en-theme-color-background-surface-elevation-1) (Dialogs Tables Panels etc)
   * - **secondary** renders the multi-select-dropdown to be used on backgrounds with var(--en-theme-color-background-surface-elevation-0) (The main body background)
   * - **tertiary** renders the text-field to be used on backgrounds with var(--en-theme-color-background-surface-elevation-2)
   */
  @property()
  variant?: 'primary' | 'secondary' | 'tertiary';

  /**
   * Dropdown Version
   * **v1** In this dropdown will be shown so that selected values chips will be shown in textfield, search will have search box with borders and selected chips will not be shown in dropdown.
   * **v2** In this dropdown will be shown so that text field will contain selected values count, selected chips will be shown in droddown, search look and feel will be inline without search box borders.
   */
  @property()
  dropdownVersion?: 'v1' | 'v2' = 'v1';

  /**
   * If true, show dropdown menu on counter chip. Default is false.
   */
  @property({ type: Boolean })
  showCounterChipDropdownMenu?: boolean = false;

  /**
   * Set it in order to change chip dropdown menu max height. Expected valid height string css value.
   */
  @property()
  chipDropdownMenuMaxHeight?: string = '205px';

  /**
   * Type variant
   * - **default** A chip with rounded corners
   * - **squared** A chip with squared corners
   * Default is squared
   */
  @property()
  chipType?: 'default' | 'squared' = 'squared';

  /**
   * If true, chips are dismissible, else not.
   * NOTE: This property is applicable only with dropdown version 2.
   */
  @property({ type: Boolean })
  areChipsDismissible?: boolean = true;

  /**
   * If true, show selected chip even when no item is selected. Default is false.
   * NOTE: This property is applicable only with dropdown version 2.
   */
  @property({ type: Boolean })
  showSelectedChipWhenNoItemIsSelected?: boolean = false;

  /**
   * If true enables lazy loading, i.e data will be loaded lazily as user scroll down. Default is false
   */
  @property({ type: Boolean })
  enableLazyLoading?: boolean = false;

  /* If true enables server side search. Default is false. */
  @property({ type: Boolean })
  enableServerSideSearch?: boolean = false;

  /* If true enable loader on lazy loading. Default is true. */
  @property({ type: Boolean })
  showLoaderOnLazyLoading?: boolean = true;

  /**
   * Set this property to false to show large loader. Default is true
   */
  @property({ type: Boolean })
  showSmallLoader?: boolean = true;

  /**
   * Set this property for showing loader in light theme. Default is false.
   */
  @property({ type: Boolean })
  showInvertedLoader?: boolean = false;

  /**
   * Expects asynchronous function if `enableLazyLoading` is set that
   * - Receive page number and search string
   * - Calls API
   * - Returns data in shape of Array<{label:string, value:string}>
   * where value must be unique. Default is null.
   */
  @property()
  lazyLoadingService: (page: number, search: string) => Promise<Array<PartialDataSource>> = null;

  /**
   * Dropdown Datasource
   * In case you are enabling lazy loading then it is mandatory to provide dataSource. Provide data equivalent to first page only.
   */
  @property({ type: Array })
  dataSource: Array<PartialDataSource> = [];

  /**
   * Scroll offset at which loading should start
   */
  @property({ type: Number })
  lazyLoadingScrollOffset = 0;

  @state()
  inputValue: string = '';

  @state()
  searchInputValue: string = '';

  /* Active item */
  private activeElements: Array<ENListItem | ENCheckboxItem> = [];

  private readonly initialListItems: Array<{ label: string; value: string }> = [];

  private initialListItemsOb: { [key: string]: string } = {};

  private attachedHandlers: Array<ENListItem | ENCheckboxItem> = [];

  private _slotChangeEventAttached: boolean = false;

  /**
   * It is used to hold isActiveDropdown property last status
   */
  private _isActiveDropdownLastStatus: boolean = false;

  /* To record last scroll position. It is used to control logic execution on up scroll */
  private _lastListScroll: number;

  /* When end is met, then this property helps to disable scroll event execution till time new results are not loaded */
  /* This prevents multiple API calls */
  private _lockScrollEvent: boolean = false;

  /* When end of search result is met, then this property helps to disable scroll event execution till time new results are not loaded */
  /* This prevents multiple API calls */
  private _lockSearchScrollEvent: boolean = false;

  /* When end of search result is met, then this property helps to disable scroll event in client side search, execution till time new results are not loaded */
  /* This prevents multiple API calls */
  private _lockClientSideSearchScrollEvent: boolean = false;

  /**
   * Stores the last loaded page. Initially it is 1 because first page data is expected from developer to pass in
   * web component.
   */
  private _lazyLoadingPage: number = 1;

  /**
   * Stores the last search result page. Initially it is 0 because we expect no search. It will be incremented only in case
   * scroll happens while search box have some search string
   */
  private _searchResultPage: number = 0;

  @state()
  private _setDropdownLoader: boolean = false;

  /**
   * Query all the list items
   */
  get listItems(): Array<ENListItem | ENCheckboxItem> {
    const queryContainer = this.enableLazyLoading ? this.shadowRoot.querySelector<HTMLElement>('.en-c-dropdown__panel') : this;
    let result: Array<ENListItem | ENCheckboxItem> = [...(queryContainer?.querySelectorAll<ENListItem>(this.elementMap.get(ENListItem.el)) || [])];
    if (this.enableCheckbox) {
      result = [...(queryContainer?.querySelectorAll<ENCheckboxItem>(this.elementMap.get(ENCheckboxItem.el)) || [])];
    }
    return result;
  }

  /**
   * Query all the selected labels
   */
  get selectedValueLabels(): Array<string> {
    const labels = [];
    for (const row of this.initialListItems) {
      const valueIndex = this.values?.findIndex((val) => val === row.value);
      if (valueIndex > -1) {
        labels[valueIndex] = row.label;
      }
    }
    return [...new Set(labels)];
    // return [...new Set(this.initialListItems?.filter((row) => this.values?.includes(row.value) || false).map((row) => row.label) || [])];
  }

  /**
   * Returns dropdown text field element.
   */
  get dropdownTextFieldEl(): Element {
    const dropdownTextField = this.shadowRoot.querySelector(this.elementMap.get(ENTextField.el));
    if (dropdownTextField) {
      const dropdownTextFieldEl = dropdownTextField.shadowRoot.querySelector('.en-c-text-field__input');
      return dropdownTextFieldEl;
    }
    return null;
  }

  /**
   * Returns dropdown text field width subtracted with left and right padding.
   */
  get dropdownTextFieldContentWidth(): number {
    const dropdownTextField = this.shadowRoot.querySelector(this.elementMap.get(ENTextField.el));
    if (dropdownTextField) {
      const dropdownTextFieldEl = dropdownTextField.shadowRoot.querySelector('.en-c-text-field__input');
      if (dropdownTextFieldEl) {
        let width = dropdownTextFieldEl.clientWidth;
        const { paddingLeft, paddingRight } = getComputedStyle(dropdownTextFieldEl);
        const parsedPaddingLeft = parseFloat(paddingLeft);
        const parsedPaddingRight = parseFloat(paddingRight);
        if (parsedPaddingLeft) width -= parsedPaddingLeft;
        if (parsedPaddingRight) width -= parsedPaddingRight;
        return width;
      }
    }
    return 0;
  }

  /**
   * Query the list element inside dropdown panel
   */
  get dropdownPanelBodyBlock(): HTMLDivElement {
    const dropdownPanel = this.shadowRoot.querySelector<ENDropdownPanel>('.en-c-dropdown__panel');
    const dropdownPanelBodyBlock = dropdownPanel.shadowRoot.querySelector<HTMLDivElement>('.en-c-dropdown-panel__body');
    return dropdownPanelBodyBlock;
  }

  /**
   * Initialize functions
   */
  constructor() {
    super();
    this.handleOnClickOutside = this.handleOnClickOutside.bind(this);
  }

  /**
   * Connected Callback lifecycle
   * 1. Close dropdown panel when you click outside of the element
   * 2. Autogenerate the fieldID
   */
  connectedCallback() {
    super.connectedCallback();
    document.addEventListener('mousedown', this.handleOnClickOutside, false); /* 1 */
    this.fieldId = this.fieldId || nanoid(); /* 2 */
  }

  /**
   * Disconnected callback lifecycle
   * 1. Remove event listeners
   */
  disconnectedCallback() {
    super.disconnectedCallback();
    document.removeEventListener('mousedown', this.handleOnClickOutside, false);
  }

  /**
   * @param el Element
   * @returns string consist of element font-weight, font-size and font-family
   */
  private getCanvasFont(el: Element) {
    if (el) {
      const { fontWeight, fontSize, fontFamily } = getComputedStyle(el);
      return `${fontWeight} ${fontSize} ${fontFamily}`;
    }
    return ``;
  }

  /**
   *
   * @param text String whose canvas length has to be measured
   * @param font Font weight, size and family of element which renders that string so that exact length can be measured
   * @returns Canvas render length of string
   */
  private getTextWidth(text: string, font: string) {
    // re-use canvas object for better performance
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    context.font = font;
    const metrics = context.measureText(text);
    return metrics.width;
  }

  private _updateNewListItems(multiSelectDropdownThis: ENMultiSelectDropdown) {
    console.log('LOGS', multiSelectDropdownThis.isActiveDropdown, multiSelectDropdownThis._isActiveDropdownLastStatus);
    if (
      multiSelectDropdownThis.isActiveDropdown &&
      multiSelectDropdownThis.isActiveDropdown !== multiSelectDropdownThis._isActiveDropdownLastStatus
    ) {
      multiSelectDropdownThis.handleOnActiveDropdown();
    }
    setTimeout(() => {
      multiSelectDropdownThis.addClickHandlers();
      multiSelectDropdownThis.updateInputValue();
    }, 0);
  }

  /**
   * Handle auto expand(isActiveDropdown : true default)
   * 1. Update values, intialListItems, initialListItemsOb and attachHandlers according to available list items
   */
  async firstUpdated() {
    await this.updateComplete;
    setTimeout(() => {
      const queryContainer = this.enableLazyLoading ? this.shadowRoot.querySelector<HTMLElement>('.en-c-dropdown__panel') : this;
      const list = this.enableCheckbox
        ? queryContainer?.querySelector<ENCheckboxItem>(this.elementMap.get(ENCheckboxItem.el))?.parentElement ||
          queryContainer?.querySelector<ENCheckbox>(this.elementMap.get(ENCheckbox.el))
        : queryContainer?.querySelector<ENList>(this.elementMap.get(ENList.el));
      const className = this.enableCheckbox ? '.en-c-checkbox__list' : '.en-c-list';
      const listSlot = (list?.shadowRoot?.querySelector(className).children?.[0] as HTMLSlotElement) || null;
      if (!this._slotChangeEventAttached) {
        const multiSelectDropdownThis = this;
        listSlot.addEventListener('slotchange', () => {
          /* 1 */
          const listItems = this.listItems;
          this.attachedHandlers = this.attachedHandlers.filter((element) => listItems.includes(element));
          const listItemValues = listItems.map(({ value }) => value);
          if (this.values?.length > 0) {
            const values = this.values;
            for (let ind = 0; ind < values.length; ++ind) {
              if (!listItemValues.includes(values[ind])) {
                this.values.splice(ind, 1);
              }
            }
          }
          if (this.initialListItems?.length > 0) {
            const initialListItems = this.initialListItems;
            for (let ind = initialListItems.length - 1; ind >= 0; --ind) {
              if (!listItemValues.includes(initialListItems[ind].value)) {
                if (initialListItems[ind].value in this.initialListItemsOb) {
                  delete this.initialListItemsOb[initialListItems[ind].value];
                }
                this.initialListItems.splice(ind, 1);
              }
            }
          }
          this._updateNewListItems(multiSelectDropdownThis);
        });
        this._slotChangeEventAttached = true;
      }
      const multiSelectDropdownThis = this;
      console.log('LOGS: Attaching click handlers');
      this._updateNewListItems(multiSelectDropdownThis);
    }, 0);
    if (this.enableLazyLoading) {
      setTimeout(() => {
        this._attachScrollEventToList();
      }, 0);
    }
  }

  /**
   * Attach scroll event to list
   * 1. If lock is set on scroll event, then disable scroll event execution. Lock is set during API call or when API stop returning data
   * 2. If user scrolling upwards, then no need to execute scroll event further
   * 3. Call lazy loading handler
   */
  private readonly _attachScrollEventToList = () => {
    const dropdownPanelBodyBlock = this.dropdownPanelBodyBlock;
    dropdownPanelBodyBlock.addEventListener('scroll', (evt: Event) => {
      if (
        (this.enableServerSideSearch && !!this.searchInputValue && this._lockSearchScrollEvent) ||
        this._lockScrollEvent ||
        this._lockClientSideSearchScrollEvent
      ) {
        /* 1 */
        return;
      }
      const element = evt.target as HTMLDivElement;
      if (element.scrollTop < this._lastListScroll) {
        /* 2 */
        return;
      }
      this._lastListScroll = element.scrollTop <= 0 ? 0 : element.scrollTop;
      if (element.scrollTop + element.offsetHeight >= element.scrollHeight - this.lazyLoadingScrollOffset) {
        /* 3 */
        console.log('1. Scroll meets end. Calling lazy loading handler...');
        this._handleLazyLoading();
      }
    });
  };

  /**
   * Merge new Data(loaded from API) with source ensuring repeating entries not loaded again.
   * 1. If API returns no data, then return source as it is assigning it new reference
   * 2. Create a dictionary or mapping of dropdown items value and dropdown items.
   * 3. If value in new data already exist in mapping created in step 2, it means that item already exist, then don't load it again
   * @param source Exisisting Datasource
   * @param newData New data loaded from API
   * @returns Merged data such that data has no entry that has repeating value
   */
  private readonly _mergeNewLoadedData = (source: Array<PartialDataSource>, newData: Array<PartialDataSource>) => {
    if (!Array.isArray(newData) || newData.length === 0) {
      /* 1 */
      return [...source];
    }
    const sourceOb: { [key: string]: PartialDataSource } = {};
    for (const item of source) {
      /* 2 */
      sourceOb[`${item.value}`] = item;
    }
    const result = [...source];
    for (const item of newData) {
      const itemValue = `${item.value}`;
      if (itemValue in sourceOb) {
        /* 3 */
        continue;
      }
      result.push(item);
    }
    return result;
  };

  /**
   * Handle Lazy loading
   * 1. Enabling Loader
   * 2. Calling data loading service
   * 3. If API stops returning data, then if it happen for search result, then it is possible that data is there but not for search result so set search based lock otherwise set permanent lock
   * 4. Merge api data with exisisting data.
   * 5. Calling addClickHandler to attach click event with newly added list items
   * 6. Removing lock from scroll event
   * 7. Handling service error and dispatch error event if service fails
   * 8. Removing loader
   * 9. Apply lock on search scroll if search result is empty but remove lock from main scroll because we are returning from there itself. Otherwise main scroll lock will be removed after dispatch.
   */
  private readonly _handleLazyLoading = (searchFn: Function = () => {}) => {
    console.log('2. Setting lock on scroll event...');
    this._lockScrollEvent = true;
    if (this.lazyLoadingService) {
      /* 1 */
      console.log('3. Enabling Loader...');
      this._setDropdownLoader = true;
      const applySearchChecks = this.enableServerSideSearch && !!this.searchInputValue;
      /* 2 */
      console.log('4. Calling lazy loading service...');
      this.lazyLoadingService(
        applySearchChecks ? this._searchResultPage + 1 : this._lazyLoadingPage + 1,
        applySearchChecks ? this.searchInputValue || '' : ''
      )
        .then((data: Array<PartialDataSource>) => {
          if (!data || data.length === 0) {
            console.log('5. *Data returned is empty, thus assuming that there is no more data and permanenty disabling scroll event');
            /* 3 */
            if (applySearchChecks) {
              /* 9 */
              this._lockSearchScrollEvent = true;
              this._lockScrollEvent = false;
            } else {
              this._lockScrollEvent = true;
            }
            return;
          }
          console.log('6. Merging API data with exisisting data');
          /* 4 */
          this.dataSource = this._mergeNewLoadedData(this.dataSource, data);
          if (applySearchChecks) {
            this._searchResultPage = this._searchResultPage + 1;
          } else {
            this._lazyLoadingPage = this._lazyLoadingPage + 1;
          }
          console.log('7. Dispatching lazyLoadingServiceSuccess event');
          this.dispatch({
            eventName: 'lazyLoadingServiceSuccess',
            detailObj: {
              apiData: [...data],
              dataSource: [...this.dataSource],
              lastScrollTop: this._lastListScroll,
              page: this._lazyLoadingPage,
              searchResultPage: this._searchResultPage,
              applySearchChecks,
              search: this.searchInputValue
            }
          });
          setTimeout(() => {
            /* 5 */
            console.log('9. Attaching click handlers and processing searched items...');
            this._updateNewListItems(this);
            if (searchFn) {
              searchFn();
            }
          }, 1);
          /* 6 */
          this._lockScrollEvent = false;
        })
        .catch((err) => {
          /* 7 */
          console.error('Lazy Loading Service Failed with error: ', err);
          this.dispatch({
            eventName: 'lazyLoadingServiceFailed',
            detailObj: { err, lastScrollTop: this._lastListScroll, page: this._lazyLoadingPage + 1, search: this.searchInputValue }
          });
        })
        .finally(() => {
          /* 8 */
          console.log('8. Disabling Loader...');
          this._setDropdownLoader = false;
        });
    }
  };

  private updateInputValue() {
    setTimeout(() => {
      const labels = this.selectedValueLabels;
      const joinedValue = labels.join(',');
      const joinedValueWidth = this.getTextWidth(joinedValue, this.getCanvasFont(this.dropdownTextFieldEl));
      this.inputValue =
        this.enableChipsView || joinedValueWidth <= this.dropdownTextFieldContentWidth
          ? joinedValue
          : `${labels.length} ${this.selectedLocalizedSuffix}`;
      const activeElements: (ENListItem | ENCheckboxItem)[] = [];
      this.listItems.forEach((element) => {
        /* 2 */
        const newSelectedValue = typeof element.value !== 'object' ? element.value : element.value?.value;
        if (!this.values?.includes(newSelectedValue)) {
          element.isActive = false;
          if (this.enableCheckbox) {
            (element as ENCheckboxItem).isChecked = false;
          }
        } else {
          element.isActive = true;
          if (this.enableCheckbox) {
            (element as ENCheckboxItem).isChecked = true;
          }
          activeElements.push(element);
        }
      });
      this.activeElements = activeElements;
    }, 1);
  }

  /**
   * Updated lifecycle
   * 1. Iterates over the changed properties of the component after an update.
   * 2. Checks if the changed property is 'value' and it has been modified.
   * 3. Find active list item corresponding to selected value and make it active.
   * @param changedProperties - A map of changed properties in the component after an update.
   */
  updated(changedProperties: Map<string, unknown>) {
    /* 1 */
    changedProperties.forEach((oldValue: string[], propName) => {
      /* 2 */
      if (propName === 'values' && this.values?.length !== oldValue?.length && this.values?.join('') !== oldValue?.join('')) {
        this.updateInputValue();
      }
    });
  }

  /**
   * Handle click outside the component
   * 1. If the nav is already closed then we don't care about outside clicks and we
   * can bail early
   * 2. By the time a user clicks on the page the shadowRoot will almost certainly be
   * defined, but TypeScript isn't that trusting and sees this.shadowRoot as possibly
   * undefined. To work around that we'll check that we have a shadowRoot (and a
   * rendered .host) element here to appease the TypeScript compiler. This should never
   * actually be shown or run for a human end user.
   * 3. Check to see if we clicked inside the active panel
   * 4. If the panel is active and we've clicked outside of the panel then it should
   * be closed.
   */
  handleOnClickOutside(event: MouseEvent) {
    /* 1 */
    if (!this.isActiveDropdown) {
      return;
    }
    /* 2 */
    if (!this.shadowRoot?.host) {
      throw Error('Could not determine panel context during click handler');
    }
    /* 3 */
    const didClickInside = event.composedPath().includes(this.shadowRoot.host);
    /* 4 */
    if (this.isActiveDropdown && !didClickInside) {
      this.toggleActive();
    }
  }

  /**
   * Set menu active state
   * 1. Toggle the active state between true and false
   */
  toggleActive() {
    if (this.isDisabled) {
      return false;
    }
    this._isActiveDropdownLastStatus = this.isActiveDropdown;
    this.isActiveDropdown = !this.isActiveDropdown; /* 1 */
    if (this.isActiveDropdown === true) {
      this.handleOnActiveDropdown();
      this.dispatch({ eventName: 'open', detailObj: { active: true } });
    } else {
      this.dispatch({ eventName: 'close', detailObj: { active: false } });
    }
  }

  /**
   * Handles the activation behavior of the dropdown:
   * 1. Positions the dropdown panel based on available viewport space
   * 2. Ensure activeElements state consist of all the selected list items
   * 3. When the dropdown is active and value is available focuses on the selected item & scrolls to view it
   * 4. When the dropdown is active and no value is available:
   *    4.1. If hasSearch and enableSelectAll are false, focuses on the first non-disabled element
   *    4.2. If enableSelectAll is true, then focus on select all checkbox.
   *    4.3. If enableSelectAll is false and hasSearch is true, then focus on search box.
   */
  handleOnActiveDropdown() {
    // To make sure that in lazy loading when component refresh then active dropdown logic not triggered again, as it moving scroll on last selected item.
    this._isActiveDropdownLastStatus = this.isActiveDropdown;
    setTimeout(() => {
      if (this.enableDynamicPositioning) {
        const dropdownPanel = this.shadowRoot.querySelector<HTMLElement>('.en-c-dropdown__panel')?.getBoundingClientRect();
        const body = document.querySelector(this.dropdownPanelContainerSelector);
        const bodyPosition = body?.getBoundingClientRect();
        if (!!bodyPosition && bodyPosition.height > dropdownPanel?.height && dropdownPanel?.bottom > bodyPosition.bottom) {
          /* 1 */
          this.align = 'top';
        }
      }

      const itemClassName = this.enableCheckbox ? '.en-c-checkbox-item' : '.en-c-list-item__link';

      if (Array.isArray(this.values) && this.values.length > 0 && this.listItems) {
        /* 2 */
        if (this.activeElements.length !== this.values.length) {
          if (this.activeElements.length > 0) {
            this.activeElements.forEach((element) => {
              element.isActive = false;
              if (this.enableCheckbox) {
                (element as ENCheckboxItem).isChecked = false;
              }
            });
          }
          this.activeElements = [];
          for (let ind = 0; ind < this.values.length; ind++) {
            const findListItemIndex = this.listItems.findIndex((listItem: ENListItem) => listItem.value === this.values[ind]);
            if (findListItemIndex > -1) {
              this.listItems[findListItemIndex].isActive = true;
              if (this.enableCheckbox) {
                (this.listItems[findListItemIndex] as ENCheckboxItem).isChecked = true;
              }
              this.activeElements.push(this.listItems[findListItemIndex]);
            }
          }
        }

        if (Array.isArray(this.activeElements) && this.activeElements.length > 0) {
          this.activeElements.forEach((activeElement, index) => {
            activeElement.isActive = true;
            if (index === 0) {
              (activeElement.shadowRoot.querySelector(itemClassName) as HTMLAnchorElement).focus(); /* 3 */
              const panel = this.shadowRoot
                .querySelector(this.elementMap.get(ENDropdownPanel.el))
                .shadowRoot.querySelector('.en-c-multi-select-dropdown-panel__body');
              const searchHeight = this.hasSearch
                ? this.shadowRoot
                    .querySelector(this.elementMap.get(ENDropdownPanel.el))
                    .shadowRoot.querySelector('.en-c-multi-select-dropdown-panel__header')?.clientHeight
                : 0;
              if (panel) {
                panel.scrollTop = activeElement?.offsetTop - searchHeight || 0;
              }
            }
          });
        }
      } else if (!this.hasSearch && !this.enableSelectAll) {
        /* 4.1 */
        const firstNonDisabled = Array.from(this.listItems).find((item: ENListItem) => !item.isDisabled);
        firstNonDisabled?.shadowRoot.querySelector<HTMLButtonElement | HTMLAnchorElement>(itemClassName).focus(); /* 4.1 */
      } else {
        if (this.enableSelectAll) {
          /* 4.2 */
          const selectAllCheckbox = this.shadowRoot.querySelector(this.elementMap.get(ENCheckboxItem.el));
          if (selectAllCheckbox) {
            selectAllCheckbox.shadowRoot.querySelector<HTMLInputElement>('input.en-c-checkbox-item__input').focus();
          }
        } else if (this.hasSearch) {
          /* 4.3 */
          const searchFormElement = this.shadowRoot.querySelector(this.elementMap.get(ENSearchForm.el));
          if (searchFormElement) {
            const textField = searchFormElement.shadowRoot.querySelector(this.elementMap.get(ENTextField.el));
            if (textField) {
              const searchInput = textField.shadowRoot.querySelector<HTMLInputElement>('.en-c-text-field__input');
              if (searchInput) {
                searchInput.focus();
              }
            }
          }
        }
      }
    }, 0);
  }

  /**
   * Handle on select input keydown
   * 1. If key selected is enter or spacebar, toggle the menu open/close
   */
  handleOnKeydown(e: KeyboardEvent) {
    if (e.code === 'Enter' || e.code === 'Space') {
      this.toggleActive();
    }
  }

  /**
   * Handle on keydown
   * 1. If the panel is open and escape is keyed, close the menu and return focus to the trigger button
   * 2. Find the last item in the list. Set the last element to that item to define variable
   * 3. If the last element is defined, once Tab is selected after that the panel will close
   */
  handleOnKeydownDropdownPanel(e: KeyboardEvent) {
    if (this.isActiveDropdown === true && e.code === 'Escape') {
      /* 1 */
      this.toggleActive();
    }

    const itemClassName = this.enableCheckbox ? '.en-c-checkbox-item' : '.en-c-list-item';

    if (this.listItems) {
      let lastFocusableElement;
      for (let i = this.listItems.length - 1; i >= 0; i--) {
        if (!this.listItems[i].shadowRoot.querySelector(`${itemClassName}.en-is-disabled`)) {
          lastFocusableElement = this.listItems[i];
          break;
        }
      }

      let lastElement;
      if (document.activeElement === lastFocusableElement) {
        lastElement = lastFocusableElement; /* 2 */
      }
      if (lastElement) {
        if (this.isActiveDropdown === true && e.code === 'Tab') {
          this.toggleActive(); /* 3 */
        }
      }
    }
  }

  /**
   * handleItemSelect Handles the list item click or enter event.
   * @param element List item or Checkbox item
   * @param applyCheckedCondition If true then checkbox is explicitly set to checked otherwise not. It is required to set if click happen on checkbox parent rather than on checkbox
   * 1. If event occurs on already selected element.
   * 2. If event occurs on unselected element.
   * 3. Unselect inactive elements.
   * 4. If all values are unselected.
   * 5. If altleast one value is selected
   */
  handleItemSelect(element: ENListItem | ENCheckboxItem, applyCheckedCondition = false) {
    const newSelectedValue = typeof element.value !== 'object' ? element.value : element.value?.value; /* 4 */
    const findValueIndex = this.values.findIndex((value) => value === newSelectedValue);
    if (findValueIndex > -1) {
      /* 1 */
      if (this.activeElements?.[findValueIndex]) {
        const toRemoveElement = this.activeElements[findValueIndex];
        if (toRemoveElement) {
          toRemoveElement.isActive = false;
          toRemoveElement.blur();
        }
      }
      element.isActive = false;
      const listItemlink = element.shadowRoot.querySelector('.en-c-list-item__link');
      if (listItemlink) {
        (listItemlink as HTMLElement)?.blur();
      }
      if (applyCheckedCondition) {
        (element as ENCheckboxItem).isChecked = false;
      }
      this.values.splice(findValueIndex, 1);
      this.activeElements.splice(findValueIndex, 1);
    } else {
      /* 2 */
      this.values.push(newSelectedValue);
      this.activeElements.push(element);
      element.isActive = true;
      if (applyCheckedCondition) {
        (element as ENCheckboxItem).isChecked = true;
      }
    }
    const notActive = this.listItems.filter((item: ENListItem | ENCheckboxItem) => !this.activeElements.includes(item));
    notActive.forEach((item: ENListItem | ENCheckboxItem) => {
      /* 3 */
      item.isActive = false;
    });
    if (this.values.length === 0) {
      /* 4 */
      this.isActive = false;
      this.inputValue = '';
    } else {
      /* 5 */
      this.isActive = true;
      const labels = this.selectedValueLabels;
      const joinedValue = labels.join(',');
      const joinedValueWidth = this.getTextWidth(joinedValue, this.getCanvasFont(this.dropdownTextFieldEl));
      this.inputValue =
        this.enableChipsView || joinedValueWidth <= this.dropdownTextFieldContentWidth
          ? joinedValue
          : `${labels.length} ${this.selectedLocalizedSuffix}`;
    }

    this.dispatch({
      eventName: 'selectValue',
      detailObj: {
        selectedValues: this.values,
        selectedLabels: this.selectedValueLabels,
        activeElements: this.activeElements
      }
    });
  }

  /**
   * Lifecycle method triggered when the component is first updated on the page
   * 1. Attaches click and keyboard(ENTER/ESCAPE) handlers to ENListItem components within the dropdown that do not contain children
   * 2. Initialize initialListItems and initialListItemsOb that are used to extract labels irrespective of list item hidden or not. NOTE: for hidden list item innerText is empty, so to prevent that problem this variable is initialized.
   */
  addClickHandlers() {
    /* 1 */
    const listItems = this.listItems;
    console.log('LOGS:', listItems);
    if (listItems) {
      listItems.forEach((element: ENListItem | ENCheckboxItem) => {
        if (!this.attachedHandlers.includes(element)) {
          // const itemLabel = `${element.innerText}`.trim();
          const itemLabel = `${element.textContent}`.replace(/\s+/g, ' ').trim();
          const itemValue = typeof element.value !== 'string' ? element.value?.value : element.value;
          /* 2 */
          this.initialListItems.push({
            label: itemLabel,
            value: itemValue
          });
          this.initialListItemsOb[itemValue] = itemLabel;
          const clickListItemTrigger = element?.shadowRoot.querySelector(
            this.enableCheckbox ? '.en-c-checkbox-item__input' : '.en-c-list-item__link'
          );
          if (clickListItemTrigger) {
            clickListItemTrigger?.addEventListener('click', () => {
              this.handleItemSelect(element);
            });
          }
          const keyboardListItemTrigger = element?.shadowRoot.querySelector(this.enableCheckbox ? '.en-c-checkbox-item' : '.en-c-list-item');
          keyboardListItemTrigger?.addEventListener('keydown', (evt: KeyboardEvent) => {
            if (!this.enableCheckbox && !(evt.shiftKey && evt.key === 'Tab')) {
              evt.preventDefault();
            }
            if (evt.code === 'Enter') {
              this.handleItemSelect(element, this.enableCheckbox);
            }
            if (this.isActiveDropdown === true && evt.code === 'Escape') {
              this.toggleActive();
            }
          });
          this.attachedHandlers.push(element);
        }
      });
    }
  }

  /**
   * Handles close icon click
   * 1. Set value to empty.
   * 2. Set isActive to false, so that Label can come to original position and select text field value to empty.
   * 3. Close dropdown if it is open.
   * 4. Remove list item selection from dropdown and in case of checkbox item mark them unchecked.
   * 5. Save temporary version of activeElements and set it empty
   * 6. Dispatch clear dropdown event so that any other handling if required can be done by developer.
   * @param evt Mouse Click Event
   */
  handleCloseIconClick(evt: MouseEvent) {
    evt.preventDefault();
    evt.stopPropagation();

    if (this.isDisabled) {
      return false;
    }

    if (!this.enableClearSelection) {
      return false;
    }

    /* 1 */
    const lastValues = this.values;
    this.values = [];
    /* 2 */
    const lastInputValue = this.inputValue;
    this.inputValue = '';
    this.isActive = false;
    /* 3 */
    if (this.isActiveDropdown) {
      this.toggleActive();
    }

    this.listItems.forEach((element) => {
      /* 4 */
      element.isActive = false;
      if (this.enableCheckbox) {
        (element as ENCheckboxItem).isChecked = false;
      }
    });

    /* 5 */
    let lastActiveElements;
    if (this.activeElements) {
      lastActiveElements = this.activeElements;
      this.activeElements = [];
    }

    /* 6 */
    this.dispatch({
      eventName: 'clearDropdown',
      detailObj: { lastSelectedValues: lastValues, lastSelectedListItems: lastActiveElements, lastInputValue }
    });
  }

  /**
   * Handles close icon click
   * 1. Set value to empty.
   * 2. Set isActive to false, so that Label can come to original position and select text field value to empty.
   * 3. Close dropdown if it is open.
   * 4. Remove list item selection from dropdown and in case of checkbox item mark them unchecked.
   * 5. Save temporary version of activeElements and set it empty
   * 6. Dispatch clear dropdown event so that any other handling if required can be done by developer.
   * @param evt Mouse Click Event
   */
  handleChipDismiss(evt: CustomEvent) {
    const { indexOfValueDismissed } = evt.detail;
    const findValueIndex = indexOfValueDismissed;
    const element = this.activeElements[findValueIndex];
    if (findValueIndex > -1) {
      /* 1 */
      if (this.activeElements?.[findValueIndex]) {
        const toRemoveElement = this.activeElements[findValueIndex];
        if (toRemoveElement) {
          toRemoveElement.isActive = false;
          toRemoveElement.blur();
        }
      }
      const listItemlink = element.shadowRoot.querySelector('.en-c-list-item__link');
      if (listItemlink) {
        (listItemlink as HTMLElement)?.blur();
      }
      (element as ENCheckboxItem).isChecked = false;
      this.values.splice(findValueIndex, 1);
      this.activeElements.splice(findValueIndex, 1);
    }
    const notActive = this.listItems.filter((item: ENListItem | ENCheckboxItem) => !this.activeElements.includes(item));
    notActive.forEach((item: ENListItem | ENCheckboxItem) => {
      /* 3 */
      item.isActive = false;
    });
    if (this.values.length === 0) {
      /* 4 */
      this.isActive = false;
      this.inputValue = '';
    } else {
      /* 5 */
      this.isActive = true;
      const labels = this.selectedValueLabels;
      const joinedValue = labels.join(',');
      const joinedValueWidth = this.getTextWidth(joinedValue, this.getCanvasFont(this.dropdownTextFieldEl));
      this.inputValue =
        this.enableChipsView || joinedValueWidth <= this.dropdownTextFieldContentWidth
          ? joinedValue
          : `${labels.length} ${this.selectedLocalizedSuffix}`;
    }

    this.dispatch({
      eventName: 'selectValue',
      detailObj: {
        selectedValues: this.values,
        selectedLabels: this.selectedValueLabels,
        activeElements: this.activeElements
      }
    });
  }

  /**
   * Handle when select all checkbox clicked or unclicked
   * 1. If checkbox is marked checked.
   * 2. Mark all list items checked and make them active.
   * 3. If checkbox is marked unchecked.
   * 4. Uncheck all list items and make them inactive.
   * 5. Update select dropdown active state and input value.
   * 6. Dispatch selectAll event.
   * @param evt
   */
  handleAllCheckboxClick(evt: MouseEvent) {
    evt.preventDefault();
    const checkboxElement = evt.target as ENCheckboxItem;
    if (checkboxElement.isChecked && this.listItems) {
      /* 1 */
      const newValues: string[] = [],
        activeElements: Array<ENListItem | ENCheckboxItem> = [];
      this.listItems.forEach((element) => {
        /* 2 */
        const newSelectedValue = typeof element.value !== 'object' ? element.value : element.value?.value;
        newValues.push(newSelectedValue);
        activeElements.push(element);
        element.isActive = true;
        if (this.enableCheckbox) {
          (element as ENCheckboxItem).isChecked = true;
        }
      });
      this.values = newValues;
      this.activeElements = activeElements;
    } else if (!checkboxElement.isChecked && this.listItems) {
      /* 3 */
      this.values = [];
      this.activeElements = [];
      this.listItems.forEach((element) => {
        /* 4 */
        element.isActive = false;
        if (this.enableCheckbox) {
          (element as ENCheckboxItem).isChecked = false;
        }
      });
    }
    if (this.values.length === 0) {
      /* 5 */
      this.isActive = false;
      this.inputValue = '';
    } else if (this.values.length > 0) {
      /* 5 */
      this.isActive = true;
      const labels = this.selectedValueLabels;
      const joinedValue = labels.join(',');
      const joinedValueWidth = this.getTextWidth(joinedValue, this.getCanvasFont(this.dropdownTextFieldEl));
      this.inputValue =
        this.enableChipsView || joinedValueWidth <= this.dropdownTextFieldContentWidth
          ? joinedValue
          : `${labels.length} ${this.selectedLocalizedSuffix}`;
    }

    /* 6 */
    this.dispatch({
      eventName: 'selectAllValue',
      detailObj: {
        selectedValues: this.values,
        selectedLabels: this.selectedValueLabels,
        activeElements: this.activeElements,
        selectAllIsChecked: checkboxElement.isChecked
      }
    });
  }

  processSearchItems = (searchValue: string, isEnterKey: boolean = false) => {
    let noMatchesFound = true;

    this.listItems.forEach((element: ENListItem | ENCheckboxItem) => {
      const itemValue = typeof element.value !== 'string' ? element.value?.value : element.value;
      let searchStrToCompare = '';
      if (element.search) {
        searchStrToCompare = this.exactSearchMatch ? `${element.search}` : `${element.search}`.toLowerCase();
      }
      const valueToCompare = this.exactSearchMatch ? `${this.initialListItemsOb[itemValue]}` : `${this.initialListItemsOb[itemValue]}`.toLowerCase();

      // Store the result of value comparison
      const valueMatch = valueToCompare.includes(searchValue);
      // Store the result of search string comparison
      const searchMatch = !!searchStrToCompare && (searchStrToCompare.includes(searchValue) || searchValue?.includes(searchStrToCompare));

      if (valueMatch || searchMatch) {
        element.hide = false;
        noMatchesFound = false; // At least one item is visible.
      } else {
        element.hide = true;
      }
    });

    if (isEnterKey && noMatchesFound) {
      this.dispatch({
        eventName: 'noMatchesFound',
        detailObj: {
          value: this.searchInputValue,
          listItems: []
        }
      });
    }
  };

  /**
   * Handle search based list item filtering with debouncing in place
   * 1. Clear debounce seed which cancels old search operation.
   * 2. Initialize debounce seed to new search operation with 100ms default latency.
   * 3. If exactSearchMatch is disabled then search value is trimmed and lowered case to perform case insensitive search.
   * 4. If search value is empty then unhide all the list item.
   * 5. If search value is present then hide all the list item that not satisfy search criteria and unhide all search that satisfy search criteria.
   * 6. If label not matches search and there is search attribute on list then also look into it for value
   * @param evt Event
   */
  handleSearchFormChange(evt: any) {
    if (this.debounceSeed) {
      /* 1 */
      clearTimeout(this.debounceSeed);
      this.debounceSeed = null;
    }
    this.searchInputValue = evt.detail && evt.detail.value !== undefined && evt.detail.value !== null ? `${evt.detail.value}` : '';
    /* 2 */
    this.debounceSeed = setTimeout(() => {
      let searchValue = this.searchInputValue;
      if (searchValue && !this.exactSearchMatch) {
        /* 3 */
        searchValue = searchValue.trim();
        searchValue = searchValue.toLowerCase();
      }
      if (searchValue === '') {
        /* 4 */
        this.listItems.forEach((element: ENListItem | ENCheckboxItem) => {
          element.hide = false;
        });
        if (this.enableServerSideSearch) {
          this._searchResultPage = 0;
          this._lockSearchScrollEvent = false;
        } else {
          this._lockClientSideSearchScrollEvent = false;
        }
      } else {
        /* 5 */
        if (!this.enableServerSideSearch) {
          this._lockClientSideSearchScrollEvent = true;
        }

        this.processSearchItems(searchValue);

        if (this.enableServerSideSearch) {
          console.log('1. Loading Server side search result...');
          this._handleLazyLoading(() => this.processSearchItems(searchValue));
        } else {
          this.processSearchItems(searchValue);
        }
      }
      this.dispatch({
        eventName: 'searchInputChange',
        detailObj: {
          value: this.searchInputValue,
          listItems: this.listItems
        }
      });
    }, this.debounceIntervalInMilliSecond);
  }

  handleSearchKeydown(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      event.preventDefault();
      const searchValue = (event.target as HTMLInputElement).value;
      this.processSearchItems(searchValue, true);
    }
  }

  render() {
    const componentClassNames = this.componentClassNames('en-c-dropdown', {
      'en-is-disabled': this.isDisabled,
      'en-is-required': this.isRequired,
      'en-is-error': this.isError,
      'en-is-active': this.isActive === true,
      'en-is-active-dropdown': this.isActiveDropdown === true,
      'en-has-search': this.hasSearch,
      'en-has-hidden-label': this.hideLabel === true,
      'en-c-dropdown--align-bottom': this.align === 'bottom',
      'en-c-dropdown--align-top': this.align === 'top'
    });

    if (this.variant === undefined) {
      this.variant = 'primary';
    }

    return html`
      <div class="${componentClassNames}">
        <div class="en-c-dropdown__container">
          <${this.textFieldEl}
            class="en-c-dropdown__input"
            .alignChipsToRight=${this.dropdownVersion === 'v2' || this.alignChipsToRight}
            paddingEndOffset=${this.dropdownVersion === 'v1' ? (this.enableClearSelection && !!this.inputValue ? 24 : 0) : 0}
            type="text"
            label="${this.label}"
            id="${this.fieldId}"
            name="${ifDefined(this.name)}"
            variant="${this.variant}"
            value="${ifDefined(!this.enableChipsView ? this.inputValue : undefined)}"
            values="${ifDefined(this.enableChipsView ? (this.dropdownVersion === 'v1' ? this.inputValue : this.selectedValueLabels.length === 0 ? (this.showSelectedChipWhenNoItemIsSelected ? `0 of ${this.listItems.length} ${this.selectionCountChipSuffixText}` : '') : `${this.selectedValueLabels.length} of ${this.listItems.length} ${this.selectionCountChipSuffixText}`) : undefined)}"
            ?hideLabel="${this.hideLabel}"
            ?isReadonly=${this.isReadonly}
            ?isRequired="${this.isRequired}"
            ?isOptional="${this.isOptional}"
            ?isDisabled="${this.isDisabled}"
            ?isError="${this.isError}"
            aria-describedby="${ifDefined(this.ariaDescribedBy)}"
            placeholder="${ifDefined(this.placeholder)}"
            @click=${this.toggleActive}
            @keydown=${this.handleOnKeydown}
            ?isActive="${this.isActive}"
            enableSlotAfterClick=${true}
            chipsMinWidth=${this.dropdownVersion === 'v1' ? this.chipsMinWidth : 'fit-content'}
            chipsMaxWidth=${this.dropdownVersion === 'v1' ? this.chipsMaxWidth : 'fit-content'}
            chipVariant=${this.dropdownVersion === 'v2' ? 'default' : this.chipVariant}
            .showCounterChipDropdownMenu=${this.showCounterChipDropdownMenu}
            chipDropdownMenuMaxHeight=${this.chipDropdownMenuMaxHeight}
          >
            ${this.dropdownVersion === 'v2' ? html`<div slot="before"><slot name="icon-before"></slot></div>` : html``}
            ${
              this.enableClearSelection && this.dropdownVersion === 'v1'
                ? html`<div slot="after" style="display:flex;align-items:center">
              ${
                this.inputValue
                  ? html`<${this.buttonEl}  ?hideText=${true} @click=${this.handleCloseIconClick} variant="quaternary">
                <${this.iconCloseEl} slot="after" size="md"></${this.iconCloseEl}>
              </${this.buttonEl}>`
                  : html``
              }
              <${this.iconChevronDownEl} slot="after" class="en-c-dropdown__icon-arrow"></${this.iconChevronDownEl}>
            </div>`
                : html`<${this.iconChevronDownEl} slot="after" class="en-c-dropdown__icon-arrow"></${this.iconChevronDownEl}>`
            }

          </${this.textFieldEl}>
          <${this.dropdownPanelEl} @keydown=${this.handleOnKeydownDropdownPanel} class=${classMap({ 'en-c-dropdown__panel': true, 'en-c-hide': !this.isActiveDropdown })} ?hasHeader=${
            this.hasSearch || this.enableSelectAll
          } ?hasScroll=${true} bodyTabIndex=${-1} style="--en-dropdown-panel-min-width:${this.dropdownPanelMinWidth};--en-dropdown-panel-max-width:${this.dropdownPanelMaxWidth};${!!this.dropdownPanelMaxScrollHeight ? `--en-dropdown-panel-max-scroll-height:${this.dropdownPanelMaxScrollHeight}` : ''}">
            ${
              this.dropdownVersion === 'v1'
                ? this.hasSearch || this.enableSelectAll
                  ? html`<div
                      class="${classMap({ 'en-c-dropdown__header': true, 'align-horizontal': this.hasSearch && this.enableSelectAll })}"
                      slot="header"
                      tabindex="-1"
                    >
                      ${this.enableSelectAll
                        ? html`<${this.checkboxItemEl} size="${this.selectAllSize}" .enableLabelFullWidth=${
                            this.hasSearch ? false : true
                          } name='select-all-checkbox' ?isIndeterminate=${
                            this.values.length > 0 && this.values.length < this.listItems.length
                          } ?isChecked=${this.values.length > 0 && this.values.length === this.listItems.length} @change=${
                            this.handleAllCheckboxClick
                          }>${this.hasSearch ? `` : `Select All`}</${this.checkboxItemEl}>`
                        : html``}
                      ${this.hasSearch
                        ? html` <${this.searchFormEl} style="${this.enableSelectAll ? 'flex:1' : ''}" value="${this.searchInputValue}" 
                         @keydown="${this.handleSearchKeydown}" 
                        @change=${this.handleSearchFormChange} ?isEmpty=${true} placeholder="${ifDefined(this.searchInputPlaceholder)}" 
                        label="${ifDefined(this.searchInputLabel)}"> </${this.searchFormEl}> `
                        : html``}
                    </div>`
                  : html``
                : html`
              <${this.dropdownSelectionPanelEl} style="--en-dropdown-selection-panel-chips-area-max-height:${this.restrictChipsAreaHeight ? this.chipsAreaMaxHeight : 'fit-content'}" .disableDebouncing=${true} @search=${this.handleSearchFormChange} @chipDismiss=${this.handleChipDismiss} @clearSelection=${this.handleCloseIconClick} values="${this.selectedValueLabels.join(',')}" .hasSearch=${this.hasSearch} .enableClearSelection=${this.enableClearSelection} chipType="${this.chipType}" .areChipsDismissible=${this.areChipsDismissible} chipsMinWidth="${this.chipsMinWidth}" chipsMaxWidth="${this.chipsMaxWidth}" chipVariant="${this.chipVariant}" ></${this.dropdownSelectionPanelEl}>
            `
            }
            ${
              !this.enableLazyLoading
                ? html`<slot></slot>`
                : this.enableCheckbox
                  ? html`
                  <${this.checkboxEl} rowGap="sm">
                    ${repeat(
                      this.dataSource,
                      (row) => `${row.label}_${row.value}`,
                      (row) => html`
                              <${this.checkboxItemEl} .enableFocusOutline=${true} .enableLabelFullWidth=${true} .tabableItem=${true} .ariaItemLabel="${row.label}" value="${row.value}">${row.label}</${this.checkboxItemEl}>`
                    )}
                    ${this.showLoaderOnLazyLoading && this._setDropdownLoader ? html`<div><${this.loadingIndicatorEl} .small=${this.showSmallLoader} .inverted=${this.showInvertedLoader}></${this.loadingIndicatorEl}></div>` : html``}
                  </${this.checkboxEl}>
                  `
                  : html`
                  <${this.listEl}>
                    ${repeat(
                      this.dataSource,
                      (row) => `${row.label}_${row.value}`,
                      (row) => html`
                              <${this.listItemEl} value="${row.value}">${row.label}</${this.listItemEl}>`
                    )}
                    ${this.showLoaderOnLazyLoading && this._setDropdownLoader ? html`<div><${this.loadingIndicatorEl} .small=${this.showSmallLoader} .inverted=${this.showInvertedLoader}></${this.loadingIndicatorEl}></div>` : html``}
                  </${this.listEl}>`
            }
          </${this.dropdownPanelEl}>
          </div>
        ${
          this.fieldNote || this.slotNotEmpty('field-note')
            ? html`
              <slot name="field-note">
                <${this.fieldNoteEl} ?isDisabled=${this.isDisabled} id=${ifDefined(this.ariaDescribedBy)}> ${this.fieldNote} </${this.fieldNoteEl}>
              </slot>
            `
            : html``
        }
        ${
          (this.errorNote || this.slotNotEmpty('error')) && this.isError
            ? html`
              <slot name="error">
                <${this.fieldNoteEl} ?isDisabled=${this.isDisabled} ?isError=${true}> ${this.errorNote} </${this.fieldNoteEl}>
              </slot>
            `
            : html``
        }
      </div>
    ` as TemplateResult<1>;
  }
}

if ((globalThis as any).enAutoRegistry === true && customElements.get(ENMultiSelectDropdown.el) === undefined) {
  customElements.define(ENMultiSelectDropdown.el, ENMultiSelectDropdown);
}

declare global {
  interface HTMLElementTagNameMap {
    'en-multi-select-dropdown': ENMultiSelectDropdown;
  }
}
