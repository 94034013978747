import { FILTER_COLUMN_NETWORK_DEVICE, FILTER_NETWORK_DEVICE } from "src/constants"
import { RADIUS_SERVERS_CERT_GENERATED_ON } from "src/constants/apiUrlsCraas"
import { getRequest } from "src/services"
import { getCraasRequest } from "src/services/axios"
import { toCamelCase } from "src/utils"

/**
 * Fetches certificates from the server with pagination.
 *
 * @param {number} page - The page number to retrieve.
 * @param {number} limit - The number of items per page.
 * @returns {Promise<any>} A promise that resolves to the server response containing the certificates.
 */
export const getCertificates = async (page: number, limit: number) => {
  const response = await getCraasRequest({ url: `${RADIUS_SERVERS_CERT_GENERATED_ON}?page=${page}&limit=${limit}` })
  return response
}

/**
 * Fetches filtered network devices from the server.
 *
 * @param {number} page - The page number to retrieve.
 * @param {number} limit - The number of items per page.
 * @param {any} filterQuery - The query parameters for filtering the network devices.
 * @param {any} order - The order parameters for sorting the network devices.
 * @returns {Promise<{ data: any[], total: number } | []>} A promise that resolves to an object containing the filtered network devices and the total number of elements, or an empty array if the request fails.
 * @throws Will throw an error if the request fails.
 */
export const getFilteredNetworkDevices = async (page: number, limit: number, filterQuery: any, order: any) => {
  let url = `${FILTER_NETWORK_DEVICE}?${filterQuery}&pageNumber=${page}&pageSize=${limit}`
  if (order) {
    url += `&${order}`
  }
  try {
    const response = await getRequest(url)
    if (response?.status === 200) {
      const payload = response?.data
      return {
        data: payload.content,
        total: payload?.totalElements,
      }
    }
    return []
  } catch (error) {
    throw error
  }
}

/**
 * Fetches the filter values for a specified network device column.
 *
 * @param {string} columnName - The name of the column to fetch filter values for.
 * @returns {Promise<any[]>} A promise that resolves to an array of filter values.
 * @throws Will throw an error if the request fails.
 */
export const getNetworkDeviceFilter = async (columnName: string) => {
  let colName = toCamelCase(columnName)
  const url = `${FILTER_COLUMN_NETWORK_DEVICE}?columnName=${colName}`
  try {
    const response = await getRequest(url)
    if (response?.status === 200) {
      const results = response.data.data ?? response.data?.payload?.values
      return results
    }
    return []
  } catch (error) {
    throw error
  }
}
