import { PropertyValueMap, TemplateResult, unsafeCSS } from 'lit';
import { property, query, state } from 'lit/decorators.js';
import { html, unsafeStatic } from 'lit/static-html.js';
import register from '../../directives/register';
import PackageJson from '../../package.json';
import { ENElement } from '../ENElement';
import { ENButton } from '../button/button';
import { ENCheckboxItem } from '../checkbox-item/checkbox-item';
import { ENDropdownSelectionPanel } from '../dropdown-selection-panel/dropdown-selection-panel';
import { ENIconSearch } from '../icon/icons/search';
import { ENLoadingIndicator } from '../loading-indicator/loading-indicator';
import { ENTextField } from '../text-field/text-field';
import { CheckboxItem, ENTreeCheckbox, Icon } from '../tree-checkbox/tree-checkbox';
import styles from './tree-dropdown-panel.scss';

/**
 * Component: en-tree-dropdown-panel
 * @slot - The components content
 */
export class ENTreeDropdownPanel extends ENElement {
  static el = 'en-tree-dropdown-panel';

  private elementMap = register({
    elements: [
      [ENButton.el, ENButton],
      [ENTreeCheckbox.el, ENTreeCheckbox],
      [ENTextField.el, ENTextField],
      [ENIconSearch.el, ENIconSearch],
      [ENDropdownSelectionPanel.el, ENDropdownSelectionPanel],
      [ENLoadingIndicator.el, ENLoadingIndicator],
      [ENCheckboxItem.el, ENCheckboxItem]
    ],
    suffix: (globalThis as any).enAutoRegistry === true ? '' : PackageJson.version
  });

  private treeCheckboxEl = unsafeStatic(this.elementMap.get(ENTreeCheckbox.el));
  private buttonEl = unsafeStatic(this.elementMap.get(ENButton.el));
  private textfieldEl = unsafeStatic(this.elementMap.get(ENTextField.el));
  private iconSearchEl = unsafeStatic(this.elementMap.get(ENIconSearch.el));
  private dropdownSelectionPanelEl = unsafeStatic(this.elementMap.get(ENDropdownSelectionPanel.el));
  private loadingIndicatorEl = unsafeStatic(this.elementMap.get(ENLoadingIndicator.el));

  static get styles() {
    return unsafeCSS(styles.toString());
  }

  /**
   * checkboxItems
   * It contains hierarchy of data has to be shown in tree.
   * For EC1 Developer: Keep its initial value same as _checkboxItems state.
   * checkbox items should have UNIQUE LABELS or if label repeats then key should be unique. Type definition is as follow:
      <pre>
      Array<{
        // label must not contain '_'. If for some reason '_' is required in label then must set key WITHOUT '_'.
        label: string;
        // key CANNOT not contain '_'. Otherwise it will break logic
        key?: string;
        icon?: Icon;
        readonly?: boolean;
        type?: 'SELECT_ALL' | undefined;
        defaultExpanded?: boolean;
        defaultChecked?: boolean;
        children?: CheckboxItem[];
      }>
      </pre>
   */
  @property({ type: Array })
  checkboxItems?: Array<CheckboxItem> = [];

  /**
   * enableSelectAll
   * If set to true, then Select All functionality will be enabled, otherwise disabled. Default is true. Use it with care only where it is required.
   */
  @property({ type: Boolean })
  enableSelectAll?: boolean = true;

  /**
   * If true, then checkbox view will be shown otherwise list view will be shown
   */
  @property({ type: Boolean })
  listView?: boolean = false;

  /**
   * Default is false.
   * If true then selecting child will not select parent. Neither it will set indeterminate state nor checked state on parent if child is selected
   * However if parent is selected then all child in it will be selected.
   */
  @property({ type: Boolean })
  disableParentSelectionOnChildSelection?: boolean = false;

  /**
   * Tree checkbox allows to select multiple items. But if this property is set to true, then at a time only single item can be selected and
   * on clicking on parent, child items will not be selected. Default is false. It should be used with list view.
   * If this is enabled, then you can set max 1 defaultChecked and only on child item. If you do it some other way in that case it may not work correctly.
   */
  @property({ type: Boolean })
  enableSingleSelect?: boolean = false;

  /**
   * If true, then parent can also be selected. This property is relevant only for list view. Default is false.
   */
  @property({ type: Boolean })
  isParentInListSelectable?: boolean = false;

  /**
   * selectAllLabel
   * Default is (Select All). Using this property you can customize it.
   */
  @property()
  selectAllLabel?: string = '(Select All)';

  /**
   * selectAllKey
   * Default is (Select All). Key is used to uniquly identify checkbox item.
   */
  @property()
  selectAllKey?: string = '(Select All)';

  /**
   * selectAllIcon
   * Default is undefined. Using this property you can add icon on select all
   */
  @property({ type: String })
  selectAllIcon?: Icon;

  /**
   * selectAllDefaultExpanded
   * Default is true. Using this property make select All default expanded or collapsed.
   */
  @property({ type: Boolean })
  selectAllDefaultExpanded?: boolean = true;

  /**
   * size
   * - Default size is 16px text and 40px checkbox width/height
   * - **sm** renders a smaller size (14px text and 30px checkbox width/height) than default (16px text and 40px checkbox width/height)
   */
  @property()
  size?: 'sm' | 'md' = 'sm';

  /**
   * If true then show Reset button
   */
  @property({ type: Boolean })
  showResetButton?: boolean = true;

  /**
   * If true then show Done button
   */
  @property({ type: Boolean })
  showDoneButton?: boolean = true;

  /**
   * If true then disable Reset button. Default is false.
   */
  @property({ type: Boolean })
  disableResetButton?: boolean = false;

  /**
   * If true then disable Done button. Default is false.
   */
  @property({ type: Boolean })
  disableDoneButton?: boolean = false;

  /**
   * If true, then show header and search box inside it. Default is true.
   */
  @property({ type: Boolean })
  enableSearch?: boolean = true;

  /**
   * Set true if case-insensitive search has to be performed. Default is true.
   */
  @property({ type: Boolean })
  caseInSensitiveSearch?: boolean = true;

  /**
   * If this property would be enabled, then selecting parent will not select its all child and selecting all child of parent will not select parent.
   * selectAll if enabled will work as it is even with this property.
   */
  @property({ type: Boolean })
  doNotApplyParentChildSelection?: boolean = false;

  /**
   * Dropdown Version
   * **v1** In this dropdown will be shown so that selected values chips will be shown in textfield, search will have search box with borders and selected chips will not be shown in dropdown.
   * **v2** In this dropdown will be shown so that text field will contain selected values count, selected chips will be shown in droddown, search look and feel will be inline without search box borders.
   */
  @property()
  dropdownVersion?: 'v1' | 'v2' = 'v1';

  /**
   * If true, restrict chips area height to 8rem. Default is true
   */
  @property({ type: Boolean })
  restrictChipsAreaHeight: boolean = true;

  /**
   * Max height of chips area after which if chips exceed then scroll would be shown
   */
  @property()
  chipsAreaMaxHeight: string = '8rem';

  /**
   * If true, then add cross icon on dropdown which enables clearing selection. Default value is false.
   */
  @property({ type: Boolean })
  enableClearSelection?: boolean = false;

  /**
   * Type variant
   * - **default** A chip with rounded corners
   * - **squared** A chip with squared corners
   * Default is squared
   */
  @property()
  chipType?: 'default' | 'squared' = 'squared';

  /**
   * If true, chips are dismissible, else not.
   * NOTE: This property is applicable only with dropdown version 2.
   */
  @property({ type: Boolean })
  areChipsDismissible?: boolean = true;

  /**
   * Set child chip max width except count chip. This property  is required to set in order to control text overflow in chip.
   */
  @property({ type: String })
  chipsMaxWidth?: string = '115px';

  /**
   * Set child chip min width except count chip. This property is required to set in order to automatically determine chips visible and text overflow.
   */
  @property({ type: String })
  chipsMinWidth?: string = '115px';

  /**
   * chipVariant
   * - **default** A chip with a high contrast background
   * - **secondary** A chip with a medium contrast background
   * - **tertiary** A chip with a low contrast background
   * - **green** A chip with a green background
   * - **red** A chip with a red background
   * - **blue** A chip with a blue background
   * - **amber** A chip with a amber background
   * - **purple** A chip with a purple background
   */
  @property()
  chipVariant?: 'default' | 'secondary' | 'tertiary' | 'green' | 'red' | 'blue' | 'amber' | 'purple' | 'neutral' = 'default';

  /**
   * Dropdown Input value. This value is expected to come from tree-dropdown-panel which get value from tree-dropdown
   * NOTE: Limitation of this approach is that tree-dropdown-panel cannot be used alone to implement UI same as dropdown version 2
   * You have to implement tree-dropdown component to get this feature.
   */
  @property()
  dropdownInputValue?: string = '';

  /**
   * Chip data-info attribute. It should be set in same order of values
   */
  @property()
  chipInfos: string = '';

  /**
   * By default chip show tooltip when text overflow. But if you want to show tooltip always on chip, in that case
   * you can use this property. Pass tooltip text that has to be shown always in it. Order of text items must be same as `values` property
   */
  @property()
  chiptooltipTexts?: string = '';

  /**
   * By default chip show tooltip when text overflow. But if you want to show tooltip always on chip, in that case
   * you can use this property. This property is relevant to set only when tooltipText will be set as these both properties work together
   * Order of text items must be same as `values` property
   */
  @property()
  chipTexts?: string = '';

  /**
   * If true enables lazy loading, i.e data will be loaded lazily as user scroll down. Default is false
   */
  @property({ type: Boolean })
  enableLazyLoading?: boolean = false;

  /* If true enable loader on lazy loading. Default is true. */
  @property({ type: Boolean })
  showLoaderOnLazyLoading?: boolean = true;

  /**
   * Set this property to false to show large loader. Default is true
   */
  @property({ type: Boolean })
  showSmallLoader?: boolean = true;

  /**
   * Set this property for showing loader in light theme. Default is false.
   */
  @property({ type: Boolean })
  showInvertedLoader?: boolean = false;

  /**
   * Sometimes browsers are not able to detect correctly if scroll reaches bottom or meet
   * offset or not. The error of detection was found to be small in chrome. In order to fix
   * it a fixOffset of 0.5 is defined. It is advised to not touch this setting unless very much required.
   * If required to set it, set to value <=1 only. If larger offset is required, contact Web Components Team.
   */
  @property({ type: Number })
  marginalFixOffset?: number = 0.5;

  /**
   * Expects asynchronous function if `enableLazyLoading` is set that
   * - Receive page number
   * - Calls API
   * - Returns data in shape of Array<CheckboxItem>
   * Default is null.
   */
  @property()
  lazyLoadingService: (page: number) => Promise<Array<CheckboxItem>> = null;

  /**
   * Scroll offset at which loading should start
   */
  @property({ type: Number })
  lazyLoadingScrollOffset = 0;

  /**
   * Disable it after first render if tree-dropdown is getting rendered multiple times.
   */
  @property({ type: Boolean })
  disableScrollDispatchEventInUpdated?: boolean = false;

  /**
   * It is used to disable/enable reset button
   */
  @state()
  checkboxItemsChanged?: boolean = false;

  @state()
  private _setDropdownLoader: boolean = false;

  /**
   * Contains the searched value
   */
  @state()
  private searchInputValue?: string = '';

  /**
   * Initial checkboxItems state which is initialized to _checkboxItems state on reset.
   * It is also updated when this.checkboxItems is updated
   */
  @state()
  private _initialCheckboxItems?: Array<CheckboxItem> = [];

  /**
   * It contains ids that will be passed to tree-checkbox component in order to
   */
  @state()
  private _addAccordionActiveIds?: Array<string> = [];

  /* It points to this.checkboxItems. Instead directly making change in this.checkboxItems seperate state is created in order
  to maintain immutability of this.checkboxItems */
  @state()
  private _checkboxItems?: Array<CheckboxItem> = [];

  /**
   * Use to clear search timeout
   */
  private _clearTimeSeed: number = null;

  /* To record last scroll position. It is used to control logic execution on up scroll */
  private _lastListScroll: number;

  /* When end is met, then this property helps to disable scroll event execution till time new results are not loaded */
  /* This prevents multiple API calls */
  private _lockScrollEvent: boolean = false;

  /**
   * Stores the last loaded page. Initially it is 1 because first page data is expected from developer to pass in
   * web component.
   */
  private _lazyLoadingPage: number = 0;

  /**
   * Query the tree checkbox component
   */
  @query('.en-c-tree-dropdown-panel__body--checkbox-items')
  treeCheckboxQueryEl: ENTreeCheckbox;

  /**
   * Query the search field component
   */
  @query('.en-c-tree-dropdown-panel__header--search-field')
  searchTextFieldQueryEl: ENTextField;

  /**
   * Query the list element inside dropdown panel
   */
  get dropdownPanelBody(): HTMLElement {
    const dropdownPanelBody = this.shadowRoot.querySelector<HTMLElement>('.en-c-tree-dropdown-panel__body');
    return dropdownPanelBody;
  }

  /**
   * Handle auto expand(isActiveDropdown : true default)
   */
  firstUpdated() {
    if (this.enableLazyLoading) {
      setTimeout(() => {
        this._attachScrollEventToList();
      }, 0);
    }
  }

  /**
   * 1. Travese changedProperties array
   * 2. If new reference to this.checkboxItems is passed. NOTE: There is possible case that items load from API and update little bit after.
   * Both intial and current checkboxItems state r updated.
   * @param _changedProperties
   */
  protected updated(_changedProperties: PropertyValueMap<any> | Map<PropertyKey, unknown>): void {
    _changedProperties.forEach((oldValue, propName) => {
      /* 1 */
      if (propName === 'checkboxItems' && this.checkboxItems !== oldValue) {
        /* 2 */
        console.log('update Lifecycle getting called...');
        this._initialCheckboxItems = JSON.parse(JSON.stringify(this.checkboxItems));
        this._checkboxItems = JSON.parse(JSON.stringify(this.checkboxItems));
        if (this.enableLazyLoading) {
          setTimeout(() => {
            const dropdownPanelBody = this.dropdownPanelBody;
            if (
              !this.disableScrollDispatchEventInUpdated &&
              this._lazyLoadingPage === 0 &&
              this.checkboxItems.length === 0 &&
              dropdownPanelBody.scrollHeight <= dropdownPanelBody.clientHeight
            ) {
              dropdownPanelBody.dispatchEvent(new CustomEvent('scroll'));
            }
          }, 100);
        }
      }
    });
  }

  /**
   * Attach scroll event to list
   * 1. If lock is set on scroll event, then disable scroll event execution. Lock is set during API call or when API stop returning data or when user has searched something
   * 2. If user scrolling upwards, then no need to execute scroll event further
   * 3. Call lazy loading handler
   */
  private _attachScrollEventToList = () => {
    const dropdownPanelBody = this.dropdownPanelBody;
    dropdownPanelBody.addEventListener('scroll', (evt: Event) => {
      if (!!this.searchInputValue || this._lockScrollEvent) {
        /* 1 */
        return;
      }
      const element = evt.target as HTMLDivElement;
      if (element.scrollTop < this._lastListScroll) {
        /* 2 */
        return;
      }
      this._lastListScroll = element.scrollTop <= 0 ? 0 : element.scrollTop;
      if (element.scrollTop + element.offsetHeight + this.marginalFixOffset >= element.scrollHeight - this.lazyLoadingScrollOffset) {
        /* 3 */
        console.log('1. Scroll meets end. Calling lazy loading handler...');
        this._handleLazyLoading();
      }
    });
  };

  /**
   * Merge checkboxItems
   * @param source
   * @param newData
   */
  private _mergeCheckboxItems = (source: CheckboxItem, newData: CheckboxItem) => {
    const sourceKey = source.key || source.label;
    const newDataKey = newData.key || newData.label;
    if (sourceKey === newDataKey) {
      const sourceChildKeysMapping: { [key: string]: CheckboxItem } = {};
      if (!source.children) {
        source.children = [];
      }
      source.children?.forEach((item) => {
        sourceChildKeysMapping[item.key || item.label] = item;
      });
      for (const item of newData.children) {
        const itemKey: string = item.key || item.label;
        if (!(itemKey in sourceChildKeysMapping)) {
          source.children.push(item);
        } else {
          this._mergeCheckboxItems(sourceChildKeysMapping[itemKey], item);
        }
      }
    }
  };

  /**
   * Merge new Data(loaded from API) with source ensuring repeating entries not loaded again.
   * 1. If API returns no data, then return source as it is assigning it new reference
   * 2. Create a dictionary or mapping of dropdown items value and dropdown items.
   * 3. If value in new data already exist in mapping created in step 2, it means that item already exist, then don't load it again
   * @param source Exisisting Datasource
   * @param newData New data loaded from API
   * @returns Merged data such that data has no entry that has repeating value
   */
  private _mergeNewLoadedData = (source: Array<CheckboxItem>, newData: Array<CheckboxItem>) => {
    if (!Array.isArray(newData) || newData.length === 0) {
      /* 1 */
      return [...source];
    }
    const sourceOb: { [key: string]: CheckboxItem } = {};
    for (const item of source) {
      /* 2 */
      sourceOb[`${item.key || item.label}`] = item;
    }
    const result = [...source];
    for (const item of newData) {
      const itemValue = `${item.key || item.label}`;
      if (itemValue in sourceOb) {
        /* 3 */
        this._mergeCheckboxItems(sourceOb[itemValue], item);
        continue;
      }
      result.push(item);
    }
    return result;
  };

  /**
   * Handle Lazy loading
   * 1. Enabling Loader
   * 2. Calling data loading service
   * 3. If API stops returning data, then if it happen for search result, then it is possible that data is there but not for search result so set search based lock otherwise set permanent lock
   * 4. Merge api data with exisisting data.
   * 5. Calling addClickHandler to attach click event with newly added list items
   * 6. Removing lock from scroll event
   * 7. Handling service error and dispatch error event if service fails
   * 8. Removing loader
   * 9. Apply lock on search scroll if search result is empty but remove lock from main scroll because we are returning from there itself. Otherwise main scroll lock will be removed after dispatch.
   */
  private _handleLazyLoading = () => {
    console.log('2. Setting lock on scroll event...');
    this._lockScrollEvent = true;
    if (this.lazyLoadingService) {
      /* 1 */
      console.log('3. Enabling Loader...');
      this._setDropdownLoader = true;
      /* 2 */
      console.log('4. Calling lazy loading service...');
      this.lazyLoadingService(this._lazyLoadingPage + 1)
        .then((data: Array<CheckboxItem>) => {
          if (!data || data.length === 0) {
            console.log('5. *Data returned is empty, thus assuming that there is no more data and permanenty disabling scroll event');
            /* 3 */
            this._lockScrollEvent = true;
            return;
          }
          console.log('6. Merging API data with exisisting data');
          /* 4 */
          this.checkboxItems = this._mergeNewLoadedData(this.checkboxItems, data);
          this._lazyLoadingPage = this._lazyLoadingPage + 1;
          console.log('7. Dispatching lazyLoadingServiceSuccess event');
          setTimeout(() => {
            /* 5 */
            console.log('9. Processing newly added items');
            const treeCheckboxItems = this.treeCheckboxQueryEl.shadowRoot.querySelectorAll<ENCheckboxItem>(this.elementMap.get(ENCheckboxItem.el));
            const treeCheckboxCheckedItems = Array.from(treeCheckboxItems);
            this.dispatch({
              eventName: 'lazyLoadingServiceSuccess',
              detailObj: {
                apiData: [...data],
                dataSource: [...this.checkboxItems],
                lastScrollTop: this._lastListScroll,
                page: this._lazyLoadingPage,
                search: this.searchInputValue,
                checkboxItemEls: treeCheckboxCheckedItems
              }
            });
            /* 6 */
            this._lockScrollEvent = false;
          }, 1);
        })
        .catch((err) => {
          /* 7 */
          console.error('Lazy Loading Service Failed with error: ', err);
          this.dispatch({
            eventName: 'lazyLoadingServiceFailed',
            detailObj: { err, lastScrollTop: this._lastListScroll, page: this._lazyLoadingPage + 1, search: this.searchInputValue }
          });
        })
        .finally(() => {
          /* 8 */
          console.log('8. Disabling Loader...');
          this._setDropdownLoader = false;
        });
    }
  };

  /**
   * Common function called on both child and parent node click.
   * 1. Logic to handle reset state of application
   */
  private _handleChangeInCheckboxItems = (isParent: boolean, evt: CustomEvent) => {
    /* 1 */
    this.checkboxItemsChanged = true;
    this.dispatch({ eventName: 'checked', detailObj: { isParent, ...evt.detail } });
  };

  /**
   * Handle reset functionality.
   * 1. If search is enabled then set search to empty, revert tree to old checkbox items and reset accordion expand states. (This flow is similar to if empty value is searched)
   * 2. Reset checked status to what was earlier set by user.
   * 3. Deep copy initial state, just to make sure nothing reflect from old changes.
   * 4. Reset checkboxItemsChanged to false which will again disable Reset button.
   */
  private _handleReset = () => {
    if (this.searchTextFieldQueryEl) {
      /* 1 */
      this.searchTextFieldQueryEl.value = '';
      this.treeCheckboxQueryEl.setSearchCheckboxItems();
      this.treeCheckboxQueryEl.resetActiveAccordionIds();
    }
    if (this.treeCheckboxQueryEl) {
      /* 2 */
      this.treeCheckboxQueryEl.resetCheckedStatus();
    }
    /* 3 */
    this._checkboxItems = JSON.parse(JSON.stringify(this._initialCheckboxItems));
    /* 4 */
    this.checkboxItemsChanged = false;
    this.dispatch({ eventName: 'reset', detailObj: { initialCheckboxItems: this._initialCheckboxItems } });
  };

  /**
   * Handle Done button click
   */
  private _handleDone = () => {
    this.dispatch({ eventName: 'done' });
  };

  /**
   * Handle parent node click
   */
  private _handleParentItemClicked = (evt: CustomEvent) => {
    this._handleChangeInCheckboxItems(true, evt);
  };

  /**
   * Handle child node click
   */
  private _handleChildItemClicked = (evt: CustomEvent) => {
    this._handleChangeInCheckboxItems(false, evt);
  };

  /**
   * Compare 2 string such that check for if left is equal to or substring of right and vice versa.
   * @param left Left string to compare
   * @param right Right string to compare
   * @param caseInSensitive If true, comparison will be case case-insensitive. Default is true
   * @returns
   */
  private _strCompare(left: string, right: string, caseInSensitive = true) {
    const leftStr = caseInSensitive ? left.toLowerCase() : left;
    const rightStr = caseInSensitive ? right.toLowerCase() : right;
    return leftStr.includes(rightStr) || rightStr.includes(leftStr);
  }

  /**
   * Uncheck single item. But this method will handle only tree checkbox part, not other things such as chips, dropdown text field etc.
   * @param key
   * @param isId
   */
  public uncheckItem = (key: string, isId: boolean = false) => {
    if (this.treeCheckboxQueryEl && !!key) {
      this.treeCheckboxQueryEl.uncheckItem(key, isId);
      this.checkboxItemsChanged = true;
    }
  };

  /**
   * Uncheck all the items. But this method will handle only tree checkbox part, not other things such as chips, dropdown text field etc.
   */
  public uncheckAll = () => {
    if (this.treeCheckboxQueryEl) {
      this.treeCheckboxQueryEl.uncheckAll();
    }
  };

  /**
   * Keep searched item and its hierarchy and filter out all the other hierarchies and even other children in same hierarchy.
   * 1. Logic to handle what all parents are required to expand to show searched item.
   * 2. If search not matched
   * 3. If child node, then push index in array removeValFromIndex so that can be removed later. NOTE: we cannot remove at this point because if we remove it, index will disturb.
   * 4. In case of parent filter recursively.
   * 5. Removing non searched hierarchy or non search child node form searched hierarchy
   * @param value Searched value
   * @param valueFindInItems This is that array of checkbox item whose label either matches search or any of its children recursivly matches search result. This data structure will be updated with final result.
   * @returns valueFindInItems - This is required to maintain recursion flow
   */
  private _filterOnlySearchedCheckboxItems(value: string, valueFindInItems: Array<CheckboxItem>) {
    if ((valueFindInItems || []).length === 0) return valueFindInItems || [];
    const removeValFromIndex = [];
    for (let ind = 0; ind < valueFindInItems.length; ++ind) {
      const checkboxItem = valueFindInItems[ind];
      if (!!checkboxItem.children) {
        /* 1 */
        const checkboxItemAccordionId = this.treeCheckboxQueryEl.getValidIdString(`id_${checkboxItem.key || checkboxItem.label}`);
        this._addAccordionActiveIds.push(checkboxItemAccordionId);
      }
      if (!this._strCompare(value, checkboxItem.label, this.caseInSensitiveSearch)) {
        /* 2 */
        if (!checkboxItem.children) {
          /* 3 */
          removeValFromIndex.push(ind);
        } else {
          /* 4 */
          valueFindInItems[ind].children = this._filterOnlySearchedCheckboxItems(value, valueFindInItems[ind].children);
        }
      }
    }
    /* 5 */
    const removeValFromIndexSet = new Set(removeValFromIndex);
    valueFindInItems = valueFindInItems.filter((_, ind) => !removeValFromIndexSet.has(ind));
    return valueFindInItems;
  }

  /**
   * This function identify all checkboxItems which may be or may not directly but recursivly satisfy search result.
   * so if checkboxItems is [{..1..}, {..2..}], and let say 1 satisfy search result either at first level itself or at nth level,
   * then valueFindInItems will be updated to [{..1..}].
   * 1. Traversing the checkboxItems
   * 2. Compare searched value with item label and if matched then copy DEEP COPY of checkboxItem in valueFindInItems. NOTE This is MANDATORY to keep DEEP
   * COPY of checkboxItem in valueFindInItems. This is because in further processing(filtering) you may require to splice certain children of checkboxItem.
   * If you will NOT DEEP COPY, that spliced result will also reflect in original checkboxItem which is not expected.
   * 3. We are returning status. This status indicates that if one item satisfy search in hierarchy, then there is no need to dig deep in that hierarchy. This prevents adding of
   * duplicate checkboxItem in valueFindInItems. Let's say there is hierarchy:
   * P1
   *  - P2
   *     -C1
   *     -C2
   * Now if you searched for 2 and it finds 2 at P2, then this status will ensure that it will not further dig P2 in order to find 2 in it. As anyways we are going to show P2.
   * 4. If item label not matches search and item is parent then scan its child for possible search result.
   * @param value Searched value
   * @param checkboxItems Checkbox items. It must always complete array of checkboxItems in non recursive mode, but in recursive mode it can subset of checkboxItem(not s).
   * @param valueFindInItems This data structure will be updated with final result.
   * @param checkboxItemsIndex This index points to checkboxItem index inside which it is processing. It means
   * if original checkboxItems is [{label:'L1', ..., children:[{label: 'L2'...}]}]. Now let's say in recursive flow
   * we traverse to subset of original checkboxItems i.e checkboxItems[0].children. While traversing subset, we will forget the
   * index of original checkboxItems(here 0), so in order to remember it we will set index of original checkboxItems in it. Obviously in very first iteration
   * it will be undefined.
   * @returns boolean status which will be true if in that function call any of the checkboxitem label matches search result.
   * Each function call represents a different level of tree
   * L1
   * . L2
   * . . L3
   * . . .
   * . . .
   * . . .
   * Very first function call represent level L1, then next function call represent L2 and then next function call L3.
   */
  private _searchValueRecursivlyInCheckboxItems(
    value: string,
    checkboxItems: Array<CheckboxItem>,
    valueFindInItems: Array<CheckboxItem>,
    checkboxItemsIndex: number = undefined
  ) {
    let searchStatus = false;
    for (let ind = 0; ind < checkboxItems.length; ++ind) {
      /* 1 */
      const checkboxItem = checkboxItems[ind];
      if (this._strCompare(value, checkboxItem.label, this.caseInSensitiveSearch)) {
        /* 2 */
        valueFindInItems.push(JSON.parse(JSON.stringify(this._checkboxItems[checkboxItemsIndex === undefined ? ind : checkboxItemsIndex])));
        /* 3 */
        searchStatus = true;
      } else if (!!checkboxItem.children) {
        /* 4 */
        const insertedStatus = this._searchValueRecursivlyInCheckboxItems(
          value,
          checkboxItem.children,
          valueFindInItems,
          checkboxItemsIndex === undefined ? ind : checkboxItemsIndex
        );
        if (insertedStatus) {
          searchStatus = true;
        }
      }
    }
    return searchStatus;
  }

  /**
   * Search event listener with debouncing support where debounce period is set to 100ms.
   * 1. If user typed another character within 100ms then clear previous timeout so that new timeout can be set.
   * 2. If empty value or value with only spaces passed, then remove search filtered results and reset to original data and reset accordion expand states as it was set previous to search.
   * 3. valueFindInItems consist of filtered search result. This array reference is passed in search and filter recursive functions and those functions are expected to update it in place.
   * 4. Update accordion expand state. So as user will search something so all the nodes lies before the searched value in hierarchy are expanded. Those nodes not lies in the hierarchy are removed.
   * 5. Set search result in tree-checkbox web component using method setSearchCheckboxItems(public method of tree-checkbox web component).
   * @param evt Input Change Custom Event
   */
  private _handleSearch = (evt: CustomEvent) => {
    if (this._clearTimeSeed) {
      /* 1 */
      clearTimeout(this._clearTimeSeed);
      this._clearTimeSeed = null;
    }
    this._clearTimeSeed = setTimeout(() => {
      let { value } = evt.detail;
      value = value.trim();
      this.searchInputValue = value;
      if (!value) {
        /* 2 */
        this.treeCheckboxQueryEl.setSearchCheckboxItems();
        this.treeCheckboxQueryEl.resetActiveAccordionIds();
        return;
      }
      /* 3 */
      let valueFindInItems: Array<CheckboxItem> = [];
      this._searchValueRecursivlyInCheckboxItems(value, this._checkboxItems, valueFindInItems);
      valueFindInItems = this._filterOnlySearchedCheckboxItems(value, valueFindInItems);
      /* 4 */
      this.treeCheckboxQueryEl.addAndRemoveActiveAccordionItems([
        ...this._addAccordionActiveIds,
        this.treeCheckboxQueryEl.getValidIdString(`id_${this.selectAllLabel}`)
      ]);
      this._addAccordionActiveIds = [];
      /* 5 */
      this.treeCheckboxQueryEl.setSearchCheckboxItems(valueFindInItems);
    }, 100);
  };

  /**
   * Get data from dropdown selection panel, process it in such a way so that it can be used by chip dismiss event in tree-dropdown
   * @param evt
   * @returns
   */
  private _handleChipDismiss = (evt: CustomEvent) => {
    const { indexOfValueDismissed } = evt.detail;
    if (indexOfValueDismissed === undefined) return false;
    const dropdownSelectionPanelElement = evt.target as HTMLElement;
    if (dropdownSelectionPanelElement) {
      const chipsInfos = dropdownSelectionPanelElement.getAttribute('chipinfos');
      const chipTexts = dropdownSelectionPanelElement.getAttribute('chiptexts');
      if (!!chipsInfos) {
        const chipWiseInfos = chipsInfos.split(',');
        const chipWiseTexts = chipTexts.split(',');
        if (!!chipWiseInfos[indexOfValueDismissed]) {
          this.dispatch({
            eventName: 'chipDismiss',
            detailObj: { chipContent: chipWiseTexts[indexOfValueDismissed], dataInfo: chipWiseInfos[indexOfValueDismissed] }
          });
        }
      }
    }
  };

  private _handleClearSelection = (evt: CustomEvent) => {
    const dropdownSelectionPanelElement = evt.target as HTMLElement;
    if (dropdownSelectionPanelElement) {
      const chipsInfos = dropdownSelectionPanelElement.getAttribute('chipinfos');
      const chipTexts = dropdownSelectionPanelElement.getAttribute('chiptexts');
      if (!!chipsInfos) {
        this.dispatch({
          eventName: 'clearSelection',
          detailObj: { chipsContent: chipTexts === '' ? [] : chipTexts.split(','), dataInfos: chipsInfos === '' ? [] : chipsInfos.split(',') }
        });
      }
    }
  };

  render() {
    const componentClassNames = this.componentClassNames('en-c-tree-dropdown-panel', {
      'en-show-footer': this.showDoneButton || this.showResetButton,
      'en-show-header': this.enableSearch
    });

    return html`
      <div class="${componentClassNames}">
        ${
          this.enableSearch && this.dropdownVersion === 'v1'
            ? html`
        <header class="en-c-tree-dropdown-panel__header">
          <${this.textfieldEl} placeholder="Search..."
            class="en-c-tree-dropdown-panel__header--search-field"
            name="search-field"
            variant="primary"
            .hideLabel=${true}
            label="Search"
            .forceWidthBeforeSlot=${24}
            style="--en-text-field-icon-height:20px;--en-text-field-icon-width:20px"
            @change=${this._handleSearch}
          >
            <${this.iconSearchEl} slot="before" size="sm"></${this.iconSearchEl}>
          </${this.textfieldEl}>
        </header>
        `
            : html``
        }

        ${
          this.dropdownVersion === 'v2'
            ? html`
        <${this.dropdownSelectionPanelEl}
          style="--en-dropdown-selection-panel-chips-area-max-height:${this.restrictChipsAreaHeight ? this.chipsAreaMaxHeight : 'fit-content'}"
          .disableDebouncing=${true}
          values="${this.dropdownInputValue}"
          .hasSearch=${this.enableSearch}
          .enableClearSelection=${this.enableClearSelection}
          chipType="${this.chipType}"
          .areChipsDismissible=${this.areChipsDismissible}
          chipsMinWidth="${this.chipsMinWidth}"
          chipsMaxWidth="${this.chipsMaxWidth}"
          chipVariant="${this.chipVariant}"
          chipInfos="${this.chipInfos}"
          chipTexts="${this.chipTexts}"
          chiptooltipTexts="${this.chiptooltipTexts}"
          @search=${this._handleSearch}
          @chipDismiss=${this._handleChipDismiss}
          @clearSelection=${this._handleClearSelection}>
          </${this.dropdownSelectionPanelEl}>
        `
            : html``
        }

        <section class="en-c-tree-dropdown-panel__body">
          <${this.treeCheckboxEl}
            class="en-c-tree-dropdown-panel__body--checkbox-items"
            @parentItemClicked=${this._handleParentItemClicked}
            @childItemClicked=${this._handleChildItemClicked}
            .checkboxItems=${this._checkboxItems}
            .enableSelectAll=${this.enableSelectAll}
            selectAllLabel="${this.selectAllLabel}"
            selectAllKey="${this.selectAllKey}"
            selectAllIcon="${this.selectAllIcon}"
            .selectAllDefaultExpanded=${this.selectAllDefaultExpanded}
            size="${this.size}"
            .listView=${this.listView}
            .enableSingleSelect=${this.enableSingleSelect}
            .isParentInListSelectable=${this.isParentInListSelectable}
            .disableParentSelectionOnChildSelection=${this.disableParentSelectionOnChildSelection}
            dropdownVersion="${this.dropdownVersion}"
            .doNotApplyParentChildSelection=${this.doNotApplyParentChildSelection}
            .enableLazyLoading=${this.enableLazyLoading}
            .showLoaderOnLazyLoading=${this.showLoaderOnLazyLoading}
            .showSmallLoader=${this.showSmallLoader}
            .showInvertedLoader=${this.showInvertedLoader}
            .lazyLoadingService=${this.lazyLoadingService}
            >
            </${this.treeCheckboxEl}>
            ${this.showLoaderOnLazyLoading && this._setDropdownLoader ? html`<div class="en-loading-indicator-container"><${this.loadingIndicatorEl} .small=${this.showSmallLoader} .inverted=${this.showInvertedLoader}></${this.loadingIndicatorEl}></div>` : html``}
        </section>
        ${
          this.showDoneButton || this.showResetButton
            ? html`
                <footer class="en-c-tree-dropdown-panel__footer">
                  ${this.showResetButton
                    ? html`
            <section class="en-c-tree-dropdown-panel__footer--left">
              <${this.buttonEl} variant="tertiary" @click=${this._handleReset} .isDisabled=${this.disableResetButton || !this.checkboxItemsChanged}>Reset</${this.buttonEl}>
            </section>
            `
                    : html``}
                  ${this.showDoneButton
                    ? html`
              <section class="en-c-tree-dropdown-panel__footer--right">
              <${this.buttonEl} .isDisabled=${this.disableDoneButton} @click=${this._handleDone} variant="primary">Done</${this.buttonEl}>
            </section>
            `
                    : html``}
                </footer>
              `
            : html``
        }
      </div>
    ` as TemplateResult<1>;
  }
}

if ((globalThis as any).enAutoRegistry === true && customElements.get(ENTreeDropdownPanel.el) === undefined) {
  customElements.define(ENTreeDropdownPanel.el, ENTreeDropdownPanel);
}

declare global {
  interface HTMLElementTagNameMap {
    'en-tree-dropdown-panel': ENTreeDropdownPanel;
  }
}
