import { PropertyValues, TemplateResult, unsafeCSS } from 'lit';
import { property, state } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';
import { repeat } from 'lit/directives/repeat.js';
import { html, unsafeStatic } from 'lit/static-html.js';
import register from '../../directives/register';
import PackageJson from '../../package.json';
import { ENButton } from '../button/button';
import { ENChipGroup } from '../chip-group/chip-group';
import { ENChip } from '../chip/chip';
import { ENElement } from '../ENElement';
import { ENIconSearch } from '../icon/icons/search';
import { ENTextField } from '../text-field/text-field';
import styles from './dropdown-selection-panel.scss';

/**
 * Component: en-dropdown-selection-panel
 * @slot - The components content
 */
export class ENDropdownSelectionPanel extends ENElement {
  static el = 'en-dropdown-selection-panel';

  private elementMap = register({
    elements: [
      [ENChipGroup.el, ENChipGroup],
      [ENChip.el, ENChip],
      [ENTextField.el, ENTextField],
      [ENIconSearch.el, ENIconSearch],
      [ENButton.el, ENButton]
    ],
    suffix: (globalThis as any).enAutoRegistry === true ? '' : PackageJson.version
  });

  private chipEl = unsafeStatic(this.elementMap.get(ENChip.el));
  private chipGroupEl = unsafeStatic(this.elementMap.get(ENChipGroup.el));
  private textFieldEl = unsafeStatic(this.elementMap.get(ENTextField.el));
  private iconSearchEl = unsafeStatic(this.elementMap.get(ENIconSearch.el));
  private buttonEl = unsafeStatic(this.elementMap.get(ENButton.el));

  static get styles() {
    return unsafeCSS(styles.toString());
  }

  /**
   * Comma seperated chips values
   */
  @property()
  values?: string = '';

  /**
   * Chip data-info attribute. It should be set in same order of values
   */
  @property()
  chipInfos: string = '';

  /**
   * By default chip show tooltip when text overflow. But if you want to show tooltip always on chip, in that case
   * you can use this property. Pass tooltip text that has to be shown always in it. Order of text items must be same as `values` property
   */
  @property()
  chiptooltipTexts?: string = '';

  /**
   * By default chip show tooltip when text overflow. But if you want to show tooltip always on chip, in that case
   * you can use this property. This property is relevant to set only when tooltipText will be set as these both properties work together
   * Order of text items must be same as `values` property
   */
  @property()
  chipTexts?: string = '';

  /**
   * chipVariant
   * - **default** A chip with a low contrast background
   * - **secondary** A chip with a medium contrast background
   * - **green** A chip with a green background
   * - **red** A chip with a red background
   * - **blue** A chip with a blue background
   * - **amber** A chip with a amber background
   * - **purple** A chip with a purple background
   */
  @property()
  chipVariant?: 'default' | 'secondary' | 'green' | 'red' | 'blue' | 'amber' | 'purple' | 'neutral' = 'secondary';

  /**
   * Set child chip max width except count chip. This property  is required to set in order to control text overflow in chip.
   */
  @property({ type: String })
  chipsMaxWidth?: string = '100px';

  /**
   * Set child chip min width except count chip. This property is required to set in order to automatically determine chips visible and text overflow.
   */
  @property({ type: String })
  chipsMinWidth?: string = '100px';

  /**
   * If true, then add cross icon on dropdown which enables clearing selection. Default value is false.
   */
  @property({ type: Boolean })
  enableClearSelection?: boolean = false;

  /**
   * If true, chips are dismissible, else not.
   */
  @property({ type: Boolean })
  areChipsDismissible?: boolean = true;

  /**
   * If true, then search will be shown else not. Default is false.
   */
  @property({ type: Boolean })
  hasSearch?: boolean = false;

  /**
   * If set to true, debouncing will not be applied in search. Default is false.
   */
  @property({ type: Boolean })
  disableDebouncing?: boolean = false;

  /**
   * Type variant
   * - **default** A chip with rounded corners
   * - **squared** A chip with squared corners
   * Default is squared
   */
  @property()
  chipType?: 'default' | 'squared' = 'squared';

  private _debounceSearchTimeout: number = null;
  private _debounceTimeoutInterval = 100;

  @state()
  chipsAreaWidth = '0px';

  /**
   * Handle chip dismiss event
   */
  private _handleChipDismiss = (evt: CustomEvent, value: string, index: number) => {
    if (!this.areChipsDismissible || evt.detail.component === 'tooltip') {
      return false;
    }
    const splitValues = this.values.split(',');
    splitValues.splice(index, 1);
    this.values = splitValues.join(',');
    this.dispatch({ eventName: 'chipDismiss', detailObj: { chipDismissEvt: evt, valueDismissed: value, indexOfValueDismissed: index } });
  };

  /**
   * Handle clear selection
   * @param evt
   * @returns
   */
  private _handleClearSelection = (evt: CustomEvent) => {
    if (this.values?.trim() === '') {
      return false;
    }
    this.values = '';
    this.dispatch({ eventName: 'clearSelection', detailObj: { clearSelectionEvt: evt } });
  };

  /**
   * Handle search
   * @param evt
   */
  private _handleSearch = (evt: CustomEvent) => {
    if (this.disableDebouncing) {
      const searchedValue = evt.detail.value;
      this.dispatch({ eventName: 'search', detailObj: { value: searchedValue, searchEvt: evt } });
      return;
    }
    if (this._debounceSearchTimeout) {
      clearTimeout(this._debounceSearchTimeout);
    }
    this._debounceSearchTimeout = setTimeout(() => {
      const searchedValue = evt.detail.value;
      this.dispatch({ eventName: 'search', detailObj: { value: searchedValue, searchEvt: evt } });
    }, this._debounceTimeoutInterval);
  };

  /**
   * Set width of chip group so that it can render chips in all the available space.
   */
  private _initializeSelectionBody = () => {
    const selectionBody = this.shadowRoot.querySelector<HTMLElement>('.en-c-dropdown-selection-panel__selection--body');
    if (selectionBody) {
      this.chipsAreaWidth = selectionBody.offsetWidth === 0 ? '100%' : `${selectionBody.offsetWidth}px`;
    }
  };

  /**
   * firstUpdated lifecycle method call after first update and only once in whole lifecycle of component.
   * 1. If there are values, then set width of chip group so that it can render chips in all the available space.
   */
  protected firstUpdated(): void {
    const chipValues = this.values === '' ? [] : this.values.split(',');
    if (chipValues.length > 0) {
      /* 1 */
      setTimeout(() => {
        this._initializeSelectionBody();
      }, 100);
    }
  }

  /**
   * Updated lifecycle method. Call in every update lifecycle method.
   * 1. If there are values, then set width of chip group so that it can render chips in all the available space.
   * @param _changedProperties List of changed properties
   */
  protected updated(_changedProperties: PropertyValues): void {
    _changedProperties.forEach((oldValue: string, propName) => {
      if (propName === 'values' && this.values !== oldValue) {
        const chipValues = this.values === '' ? [] : this.values.split(',');
        if (chipValues.length > 0) {
          /* 1 */
          setTimeout(() => {
            this._initializeSelectionBody();
          }, 0);
        }
      }
    });
  }

  render() {
    const componentClassNames = this.componentClassNames('en-c-dropdown-selection-panel', {});
    const chipValues = this.values === '' ? [] : this.values.split(',');
    const chipTextsAr = !this.chipTexts ? [] : this.chipTexts.split(',');
    const chiptooltipTextsAr = !this.chiptooltipTexts ? [] : this.chiptooltipTexts.split(',');
    const chipInfosAr = !this.chipInfos ? [] : this.chipInfos.split(',');
    return html`
      <div class="${componentClassNames}">
        ${
          this.hasSearch
            ? html`<div class="en-c-dropdown-selection-panel__search">
          <${this.textFieldEl} @change=${this._handleSearch} .forceWidthBeforeSlot=${24} .noBorder=${true} placeholder="Search" .hideLabel=${true}>
            <${this.iconSearchEl} slot="before"></${this.iconSearchEl}>
          </${this.textFieldEl}>
        </div>`
            : html``
        }
        <div class="en-c-dropdown-selection-panel__selection">
          <div class="en-c-dropdown-selection-panel__selection--header">
            <div class="header__left">Selected (${chipValues.length})</div>
            ${this.enableClearSelection ? html`<div class="header__right"><${this.buttonEl} variant="tertiary" .isDisabled=${chipValues.length === 0} @click=${this._handleClearSelection}>Clear selection</${this.buttonEl}></div>` : html``}
          </div>
          <div class=${classMap({ 'en-c-dropdown-selection-panel__selection--body': true, 'en-hide': chipValues.length === 0 })}>
            <${this.chipGroupEl} .setTooltipCSSPositionFixed=${true} style="--en-chip-group-dev-width:${this.chipsAreaWidth}" .textOverflow=${true} chipsMinWidth="${
              this.chipsMinWidth
            }" chipsMaxWidth="${
              this.chipsMaxWidth
            }" .addSomeDelayInTextOverflowHandling=${true} .autoDetermineChipsVisible=${true} .disableChipWrapping=${false}>
                    ${repeat(
                      chipValues,
                      (value, index) => `${value}${index}`,
                      (value, index) =>
                        html`<${this.chipEl} chipText="${chipTextsAr[index] || ''}" tooltipText="${chiptooltipTextsAr[index] || ''}" dataInfo="${chipInfosAr[index] || ''}"  .setTooltipCSSPositionFixed=${true} type="${this.chipType}" @close=${(evt: CustomEvent) => this._handleChipDismiss(evt, value, index)} variant="${this.chipVariant}" .isDismissible=${this.areChipsDismissible || false} data-testid="chip${index}">${value}</${this.chipEl}`
                    )}

            </${this.chipGroupEl}>
          </div>
        </div>
      </div>
    ` as TemplateResult<1>;
  }
}

if ((globalThis as any).enAutoRegistry === true && customElements.get(ENDropdownSelectionPanel.el) === undefined) {
  customElements.define(ENDropdownSelectionPanel.el, ENDropdownSelectionPanel);
}

declare global {
  interface HTMLElementTagNameMap {
    'en-dropdown-selection-panel': ENDropdownSelectionPanel;
  }
}
