import { ENDropdown as ENDropdownType } from "en-web-components/dist/en/components/dropdown/dropdown"
import React, { memo } from "react"
import { Control, Controller, FieldError } from "react-hook-form"
import { packetCaptureFormType } from "src/pages/Monitor/PacketCapture/PacketCapture"
import ENPaginatedMultiSelect from "src/shared/components/FormComponents/ENPaginatedMultiSelect"

interface SwitchesSelectProps {
  control: Control<packetCaptureFormType, any>
  error: FieldError | undefined | string
  loadSwitchesOptions: ENDropdownType["lazyLoadingService"]
  switchesDataLoading: boolean
  isSubmitting: boolean
}

interface AccessPointsSelectProps {
  control: Control<packetCaptureFormType, any>
  error: FieldError | undefined | string
  isSubmitting: boolean
  loadAccessPointsOptions: ENDropdownType["lazyLoadingService"]
  accessPointsDataLoading: boolean
}

export const SwitchesSelect: React.FC<SwitchesSelectProps> = memo(
  ({ control, error, loadSwitchesOptions, switchesDataLoading, isSubmitting }) => {
    return (
      <Controller
        control={control}
        name="switches"
        render={({ field: { onChange, value } }): JSX.Element => (
          <ENPaginatedMultiSelect
            label="Switch(es)"
            id="switches-multi-select"
            onChange={onChange}
            placeholder="Select Switch"
            loadOptions={loadSwitchesOptions}
            values={value}
            disabled={isSubmitting}
            isLoading={switchesDataLoading}
            error={error}
            enableServerSideSearch
            returnObject
            lazyLoadingScrollOffset={50}
            isOptional
          />
        )}
      />
    )
  },
)

export const AccessPointsSelect: React.FC<AccessPointsSelectProps> = memo(
  ({ control, error, isSubmitting, loadAccessPointsOptions, accessPointsDataLoading }) => {
    return (
      <Controller
        control={control}
        name="accessPoints"
        render={({ field: { onChange, value } }): JSX.Element => (
          <ENPaginatedMultiSelect
            label="Access Point(s)"
            id="access-points-multi-select"
            onChange={onChange}
            placeholder="Select Access Point"
            loadOptions={loadAccessPointsOptions}
            values={value}
            disabled={isSubmitting}
            isLoading={accessPointsDataLoading}
            error={error}
            enableServerSideSearch
            returnObject
            isOptional
            lazyLoadingScrollOffset={50}
          />
        )}
      />
    )
  },
)
