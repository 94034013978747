import { ENMultiSelectDropdown } from "en-react/dist/src/components/MultiSelectDropdown"
import { ENMultiSelectDropdown as ENMultiSelectDropdownType } from "en-web-components/dist/en/components/multi-select-dropdown/multi-select-dropdown"
import { useEffect, useRef, useState } from "react"
import { DROPDOWN_PAGE_SIZE } from "src/utils/constants"
import { ENPaginatedMultiSelectType } from "../FormComponents.types"

const ENPaginatedMultiSelect: React.FC<ENPaginatedMultiSelectType> = ({
  id,
  values = [],
  error,
  onChange,
  placeholder = "Select",
  disabled = false,
  loadOptions,
  isLoadingOptions = false,
  fieldNote,
  label,
  enableClientSideSearch = false,
  enableServerSideSearch = false,
  lazyLoadingScrollOffset = DROPDOWN_PAGE_SIZE,
  debounceIntervalInMilliSecond = 800,
  isOptional = false,
  enableChipsView = true,
  returnObject = false,
  dropdownVersion = "v2",
  enableClearSelection = true,
}): JSX.Element => {
  const dropdownRef = useRef<ENMultiSelectDropdownType>(null)
  const [dataSource, setDataSource] = useState<any[]>([])

  const setInitialDataSource = async () => {
    setDataSource([{ label: "Loading...", value: undefined }])
    const response = await loadOptions(1, "")
    dropdownRef.current?.firstUpdated()
    setDataSource(response)
  }

  useEffect(() => {
    setInitialDataSource()
  }, [])

  return (
    <ENMultiSelectDropdown
      ref={dropdownRef}
      label={label}
      values={returnObject ? values.map((val) => val.value) : values}
      onSelectValue={(selectedValue: any) => {
        const detail = selectedValue.detail
        if (returnObject) {
          onChange(
            detail.selectedValues.map((val: any, idx: number) => ({
              label: detail.selectedLabels[idx],
              value: val,
            })),
          )
        } else {
          onChange(detail.selectedValues)
        }
      }}
      onClearDropdown={() => onChange([])}
      areChipsDismissible={true}
      dropdownVersion={dropdownVersion}
      placeholder={placeholder}
      enableCheckbox
      // @ts-ignore
      enableLazyLoading
      lazyLoadingScrollOffset={lazyLoadingScrollOffset}
      showLoaderOnLazyLoading
      lazyLoadingService={loadOptions}
      onLazyLoadingServiceSuccess={(response: any) => setDataSource(response.detail.dataSource)}
      dataSource={dataSource}
      isDisabled={/* !dataSource?.length ||  */ disabled}
      fieldNote={fieldNote}
      isError={!!error}
      errorNote={error}
      enableServerSideSearch={enableServerSideSearch}
      hasSearch={enableClientSideSearch || enableServerSideSearch}
      debounceIntervalInMilliSecond={debounceIntervalInMilliSecond}
      data-testid={id}
      isOptional={isOptional}
      enableChipsView={enableChipsView}
      enableClearSelection={enableClearSelection}
    />
  )
}

export default ENPaginatedMultiSelect
