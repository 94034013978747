import { TemplateResult, unsafeCSS } from 'lit';
import { property, queryAssignedNodes } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';
import { ifDefined } from 'lit/directives/if-defined.js';
import { html, unsafeStatic } from 'lit/static-html.js';
import { nanoid } from 'nanoid';
import register from '../../directives/register';
import PackageJson from '../../package.json';
import { ENElement } from '../ENElement';
import { ENCheckboxItem } from '../checkbox-item/checkbox-item';
import { ENFieldNote } from '../field-note/field-note';
import styles from './checkbox.scss';

/**
 * Component: en-checkbox
 * - Checkboxes let users select one or more items from a list, or turn an item on or off. The “Checkbox” component should never be used on its own and designers should instead reference the “Checkbox Group” component.
 * @slot - The component content
 * @slot "field-note" - If content is slotted, it will display in place of the fieldNote property
 * @slot "error" - If content is slotted, it will display in place of the errorNote property
 */
export class ENCheckbox extends ENElement {
  static el = 'en-checkbox';

  private elementMap = register({
    elements: [[ENFieldNote.el, ENFieldNote]],
    suffix: (globalThis as any).enAutoRegistry === true ? '' : PackageJson.version
  });

  private fieldNoteEl = unsafeStatic(this.elementMap.get(ENFieldNote.el));

  static get styles() {
    return unsafeCSS(styles.toString());
  }

  /**
   * Error state
   * - Changes the component's treatment to represent an error state
   */
  @property({ type: Boolean })
  isError?: boolean;

  /**
   * Disabled attribute
   * - Changes the component's treatment to represent a disabled state
   */
  @property({ type: Boolean })
  isDisabled?: boolean;

  /**
   * Required attribute
   * - Sets the checkbox to be required for validation
   */
  @property({ type: Boolean })
  isRequired?: boolean;

  /**
   * Hide legend?
   * - If true, hides the legend from displaying
   */
  @property({ type: Boolean })
  hideLegend?: boolean;

  /**
   * Label
   * - Displays inside the legend
   */
  @property()
  label?: string;

  /**
   *  Error message
   * - An error field note that displays below the checkbox input
   */
  @property()
  errorNote?: string;

  /**
   * Field note
   * - The helper text that displays below the checkbox input
   */
  @property()
  fieldNote?: string;

  /**
   * Id attribute
   * - The ID used for A11y and to associate the label with the input
   */
  @property()
  fieldId?: string;

  /**
   * aria-describedby attribute
   * - Applied to the field note or error note for A11y
   */
  @property()
  ariaDescribedBy?: string;

  /**
   * Variant
   * - **default** Displays the checkbox items in a column
   * - **horizontal** Displays the checkbox items in a row
   */
  @property()
  variant?: 'horizontal';

  /**
   * rowGap
   * - **sm** Displays gap of 0.5 times of base unit size(default is 8px) between the items.
   * Default is undefined which means default row gap is 0.
   */
  @property()
  rowGap?: 'sm';

  /**
   * Query all the checkbox-item's
   */
  @queryAssignedNodes({ flatten: true })
  private checkboxItems: Array<ENCheckboxItem>;

  /**
   * Connected callback
   * 1. Dynamically sets the fieldId and ariaDescribedBy for A11y
   */
  connectedCallback() {
    super.connectedCallback();
    /* 1 */
    this.fieldId = this.fieldId || nanoid();
    if (this.fieldNote) {
      this.ariaDescribedBy = this.ariaDescribedBy || nanoid();
    }
  }

  /**
   * First updated lifecycle
   * 1. If isRequired is true, set isRequired on all the checkbox items
   * 2. If isDisabled is true, set isDisabled on all the checkbox items
   */
  firstUpdated() {
    this.checkboxItems.forEach((checkboxItems) => {
      /* 1 */
      if (this.isRequired) {
        checkboxItems.isRequired = this.isRequired;
      }
      /* 2 */
      if (this.isDisabled) {
        checkboxItems.isDisabled = this.isDisabled;
      }
    });
  }

  render() {
    const componentClassNames = this.componentClassNames('en-c-checkbox', {
      'en-is-error': this.isError === true,
      'en-is-disabled': this.isDisabled === true,
      'en-has-hidden-legend': this.hideLegend,
      'en-c-checkbox--horizontal': this.variant === 'horizontal'
    });

    return html`
      <fieldset class="${componentClassNames}">
        ${this.label && html` <legend class="en-c-checkbox__legend" aria-describedby="${this.ariaDescribedBy}">${this.label}</legend> `}
        <div class="${classMap({ 'en-c-checkbox__list': true, 'en-row-gap-sm': this.rowGap === 'sm' })}">
          <slot></slot>
        </div>
        ${this.fieldNote || this.slotNotEmpty('field-note')
          ? html`
              <slot name="field-note">
                <${this.fieldNoteEl} ?isDisabled=${this.isDisabled} id=${ifDefined(this.ariaDescribedBy)}> ${this.fieldNote} </${this.fieldNoteEl}>
              </slot>
            `
          : html``}
        ${(this.errorNote || this.slotNotEmpty('error')) && this.isError
          ? html`
              <slot name="error">
                <${this.fieldNoteEl} ?isDisabled=${this.isDisabled} ?isError=${true}> ${this.errorNote} </${this.fieldNoteEl}>
              </slot>
            `
          : html``}
      </fieldset>
    ` as TemplateResult<1>;
  }
}

if ((globalThis as any).enAutoRegistry === true && customElements.get(ENCheckbox.el) === undefined) {
  customElements.define(ENCheckbox.el, ENCheckbox);
}

declare global {
  interface HTMLElementTagNameMap {
    'en-checkbox': ENCheckbox;
  }
}
