import { ENTreeDropdown } from "en-react/dist/src/components/TreeDropdown"
import React, { useCallback } from "react"
import { Controller } from "react-hook-form"
import { TreeDropdownInputType } from "../FormComponents.types"

const EnTreeDropdown = React.memo(
  ({
    control,
    checkboxItems,
    enableLazyLoading,
    lazyLoadingService,
    name,
    label,
    id,
    placeholder,
    variant,
    enableSelectAll,
    showResetButton,
    showDoneButton,
    enableSingleSelect,
    dropdownPanelContainerSelector,
    enableDynamicPositioning,
    lazyLoadingScrollOffset,
    disableScrollDispatchEventInUpdated = false,
    isParentInListSelectable,
    onTreeDropdownChange,
    error,
    isDisabled,
    isOptional,
    enableSearch = true,
  }: TreeDropdownInputType & { onTreeDropdownChange: (selectedItems: any) => void }) => {
    const handleOnChange = useCallback(
      (selectedItems: any) => {
        onTreeDropdownChange(selectedItems)
      },
      [onTreeDropdownChange],
    )

    return (
      <Controller
        control={control}
        name={name}
        render={({ field: { onChange, onBlur, value, ref } }) => (
          <ENTreeDropdown
            checkboxItems={checkboxItems}
            fieldId={id}
            placeholder={placeholder}
            variant={variant as "primary" | "secondary" | "tertiary" | undefined}
            label={label}
            name={name}
            enableLazyLoading={enableLazyLoading}
            lazyLoadingService={lazyLoadingService}
            enableSelectAll={enableSelectAll}
            showResetButton={showResetButton}
            showDoneButton={showDoneButton}
            enableSingleSelect={enableSingleSelect}
            dropdownPanelContainerSelector={dropdownPanelContainerSelector}
            enableDynamicPositioning={enableDynamicPositioning}
            lazyLoadingScrollOffset={lazyLoadingScrollOffset}
            isParentInListSelectable={isParentInListSelectable}
            disableScrollDispatchEventInUpdated={disableScrollDispatchEventInUpdated}
            onTreeDropdownChange={handleOnChange}
            isError={error}
            chipsMaxWidth="160px"
            isDisabled={isDisabled}
            isOptional={isOptional}
            enableSearch={enableSearch}
          />
        )}
      />
    )
  },
  (prevProps, nextProps) => {
    return (
      prevProps.checkboxItems === nextProps.checkboxItems &&
      prevProps.enableLazyLoading === nextProps.enableLazyLoading &&
      prevProps.lazyLoadingService === nextProps.lazyLoadingService &&
      prevProps.name === nextProps.name &&
      prevProps.label === nextProps.label &&
      prevProps.id === nextProps.id &&
      prevProps.placeholder === nextProps.placeholder &&
      prevProps.variant === nextProps.variant &&
      prevProps.enableSelectAll === nextProps.enableSelectAll &&
      prevProps.showResetButton === nextProps.showResetButton &&
      prevProps.showDoneButton === nextProps.showDoneButton &&
      prevProps.enableSingleSelect === nextProps.enableSingleSelect &&
      prevProps.dropdownPanelContainerSelector === nextProps.dropdownPanelContainerSelector &&
      prevProps.enableDynamicPositioning === nextProps.enableDynamicPositioning &&
      prevProps.lazyLoadingScrollOffset === nextProps.lazyLoadingScrollOffset &&
      prevProps.isParentInListSelectable === nextProps.isParentInListSelectable &&
      prevProps.isError === nextProps.isError &&
      prevProps.isDisabled === nextProps.isDisabled &&
      prevProps.isOptional === nextProps.isOptional
    )
  },
)

export default EnTreeDropdown
