import { FilterModel } from "ag-grid-enterprise"
import dayjs from "dayjs"
import advancedFormat from "dayjs/plugin/advancedFormat"
import customParseFormat from "dayjs/plugin/customParseFormat"
import duration from "dayjs/plugin/duration"
import relativeTime from "dayjs/plugin/relativeTime"
import timezone from "dayjs/plugin/timezone"
import utc from "dayjs/plugin/utc"
import { LayoutPoints } from "src/constants/layout"
import { XIQSitesPayloadType } from "src/pages/Sites/Sites.types"
import { AuthenticationPiePayloadTypes } from "src/services/api/swrHooks/useAuthenticationTypesChart"
import { AnalyticsConnectionPayloadTypes } from "src/services/api/swrHooks/useDashboardAnalyticsConnect"
import theme from "src/theme"
import { APPLICATION_PROTOCOLS_LIST, WEB_ACCESSIBLE_PROTOCOLS } from "./constants"
import { OptionType, ServiceProtocolListType } from "./utils.types"

dayjs.extend(relativeTime)
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(customParseFormat)
dayjs.extend(advancedFormat)
dayjs.extend(duration)

type AnalyticsConnectionPayloadType = {
  dataPoints: {
    timestamp: string
    up?: number
    down?: number
    partiallyDown?: number
    total?: number
  }[]
}

type AnalyticsHostsRelayNodesPerformanceAnomiliesDataPointType = {
  timestamp: string
  value: number
  name: string
  connectorName: string
  instanceName: string
}

type AnalyticsHostsRelayNodesPerformanceAnomiliesPayloadType = {
  cpuPerformanceStats: { dataPoints: AnalyticsHostsRelayNodesPerformanceAnomiliesDataPointType[] }
  memoryPerformanceStats: { dataPoints: AnalyticsHostsRelayNodesPerformanceAnomiliesDataPointType[] }
}

type StatsType = {
  count: number
  upPercentage: number
  downPercentage: number
  upCount: number
  downCount: number
}

type ResourcesDistributionsPayloadType = {
  sites: StatsType
  hosts: StatsType
  relayNodes: StatsType
  services: StatsType
  projects: StatsType
}

const PERFORMANCE_INTERVAL = {
  today: 3600000,
  twentyFourHr: 360000,
  last24Hours: 360000,
  oneHr: 600000,
  twoHr: 600000,
  last1Hour: 600000,
  last2Hour: 600000,
  last7Days: 86400000,
  last30Days: 86400000,
}

export type ChartsTimeIntervalFilterType = keyof typeof PERFORMANCE_INTERVAL

/**
 * Formats the time points based on the provided filter.
 *
 * @param {Object} params - The parameters for formatting time points.
 * @param {string} [params.x] - The x value representing the time in string format.
 * @param {number} [params.y] - The y value representing the time point.
 * @param {ChartsTimeIntervalFilterType} params.filter - The filter type to determine the date format.
 * @returns {Object} The formatted time points with x and y values.
 * @returns {string|number} returns x as formatted date string or original x value if no format is found.
 * @returns {number} returns y as it is.
 */
export const formatTimePoints = ({
  x,
  y,
  filter,
}: {
  x?: string
  y?: number
  filter: ChartsTimeIntervalFilterType
}) => {
  const dateFormatMap = {
    today: "h A",
    twentyFourHr: "h A",
    last24Hours: "h A",
    oneHr: "H:mm A",
    twoHr: "H:mm A",
    last1Hour: "H:mm A",
    last2Hour: "H:mm A",
    last7Days: "DD/MM/YYYY",
    last30Days: "DD/MM/YYYY",
  }

  if (x === undefined || y === undefined) return { x: 0, y: 0 }

  const dateFormat = dateFormatMap[filter]

  if (dateFormat) return { x: dayjs.utc(x).local().format(dateFormat), y }

  return { x, y }
}

/**
 * Generates initials from a full name.
 *
 * @param {string} [fullname] - The full name from which to generate initials.
 * @returns {string | undefined} The initials derived from the full name, or `undefined` if the input is not a valid string.
 *
 * @example
 * ```
 * getInitials("John Doe"); // "JD"
 * getInitials("Jane"); // "J"
 * getInitials(""); // undefined
 * getInitials(undefined); // undefined
 * ```
 */
export const getInitials = (fullname?: string): string | undefined => {
  if (!fullname || typeof fullname !== "string") return undefined

  const names = fullname.split(" ")
  const firstChar = names[0].substring(0, 1).toUpperCase()
  const secondChar = names.length > 1 ? names[names.length - 1].substring(0, 1).toUpperCase() : ""

  const initials = `${firstChar}${secondChar}`

  return initials
}

/**
 * Retrieves the value of a specified cookie by name.
 *
 * @param {string} cname - The name of the cookie to retrieve.
 * @returns {string} The value of the specified cookie. Returns an empty string if the cookie is not found.
 */
export const getCookie = (cname: string): string => {
  const name = `${cname}=`
  const decodedCookie = decodeURIComponent(document.cookie)
  const ca = decodedCookie.split(";")
  for (let i = 0; i < ca.length; i += 1) {
    let c = ca[i]
    while (c.charAt(0) === " ") {
      c = c.substring(1)
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length)
    }
  }
  return ""
}

/**
 * Deletes a cookie by setting its value to an empty string and its Max-Age to 0.
 *
 * @param cname - The name of the cookie to delete.
 */
export const deleteCookie = (cname: string) => {
  document.cookie = `${cname}=; Max-Age=0`
}

/**
 * Checks if the provided data is an array.
 *
 * @param data - The data to check.
 * @returns True if the data is an array, false otherwise.
 */
const isArray = (data: any): boolean => {
  return Array.isArray(data)
}

/**
 * Checks if the given data is an object.
 *
 * This function determines if the provided data is an object by verifying that:
 * - The data is equal to `Object(data)`.
 * - The data is not an array.
 * - The data is not a function.
 *
 * @param {any} data - The data to check.
 * @returns {boolean} - Returns `true` if the data is an object, otherwise `false`.
 */
const isObject = (data: any): boolean => {
  return data === Object(data) && !isArray(data) && typeof data !== "function"
}

export const toSnakeCase = (s: string) => s.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`)

export const addSpaceToKey = (s: string) => s.replace(/[A-Z]/g, (letter) => `-${letter.toLowerCase()}`)

/**
 * Converts a given string to camelCase.
 *
 * This function takes a string with words separated by hyphens or underscores
 * and converts it to camelCase format. For example, "hello-world" or "hello_world"
 * will be converted to "helloWorld".
 *
 * @param s - The input string to be converted to camelCase.
 * @returns The camelCase formatted string.
 */
export const toCamelCase = (s: string): string => {
  return s.replace(/([-_][a-z])/gi, (letter) => {
    return letter.toUpperCase().replace("-", "").replace("_", "")
  })
}

/**
 * Converts the keys of an object or array to camel case recursively.
 *
 * @param o - The object or array to convert.
 * @returns A new object or array with keys converted to camel case.
 *
 * @remarks
 * This function checks if the input is an object or an array. If it is an object,
 * it creates a new object with keys converted to camel case and values processed
 * recursively. If it is an array, it maps each element to the function recursively.
 * If the input is neither an object nor an array, it returns the input as is.
 *
 * @example
 * ```typescript
 * const obj = { 'first_name': 'John', 'last_name': 'Doe' };
 * const result = keysToCamelCase(obj);
 * console.log(result); // { firstName: 'John', lastName: 'Doe' }
 * ```
 */
export const keysToCamelCase = (o: any) => {
  if (o instanceof Blob) {
    return o
  }
  if (isObject(o)) {
    const n: any = {}

    Object.keys(o).forEach((k: string) => {
      n[toCamelCase(k)] = keysToCamelCase(o[k])
    })

    return n
  }
  if (isArray(o)) {
    return o.map((i: any) => {
      return keysToCamelCase(i)
    })
  }

  return o
}

/**
 * Converts the keys of an object to capitalized format with spaces.
 *
 * @param obj - The object whose keys need to be capitalized.
 * @returns A new object with the keys capitalized and spaces added.
 *
 * @example
 * ```typescript
 * const input = { firstName: 'John', lastName: 'Doe' };
 * const result = keysToCapitialize(input);
 * console.log(result); // { 'First Name': 'John', 'Last Name': 'Doe' }
 * ```
 */
export const keysToCapitialize = (obj: any) => {
  const objEntries = Object.entries(obj)
  const capsEntries = objEntries.map((entry) => [
    addSpaceToKey(entry[0]).replace(/(^\w{1})|(\s+\w{1})/g, (letter) => letter.toUpperCase()),
    entry[1],
  ])

  return Object.fromEntries(capsEntries)
}

/**
 * Recursively converts the keys of an object to snake_case.
 *
 * @param o - The object or array to be converted. Can be of any type.
 * @returns A new object or array with all keys converted to snake_case.
 *
 * @remarks
 * - If the input is an object, it will iterate over its keys and convert each key to snake_case.
 * - If the input is an array, it will recursively apply the conversion to each element.
 * - If the input is neither an object nor an array, it will return the input as is.
 *
 * @example
 * ```typescript
 * const camelCaseObject = { myKey: 'value', nestedObject: { anotherKey: 'value' } };
 * const snakeCaseObject = keysToSnakeCase(camelCaseObject);
 * console.log(snakeCaseObject); // { my_key: 'value', nested_object: { another_key: 'value' } }
 * ```
 */
export const keysToSnakeCase = (o: any) => {
  if (isObject(o)) {
    const n: any = {}

    Object.keys(o).forEach((k: string) => {
      n[toSnakeCase(k)] = keysToSnakeCase(o[k])
    })

    return n
  }
  if (isArray(o)) {
    return o.map((i: any) => {
      return keysToSnakeCase(i)
    })
  }

  return o
}

/**
 * Checks if a given value is present in an array of strings or booleans.
 *
 * @param value - The value to check, which can be a string or a boolean.
 * @param data - The array of strings or booleans to search within.
 * @returns A boolean indicating whether the value is present in the array.
 */
export const isInArray = (value: string | boolean, data: (string | boolean)[]): boolean => {
  const updatedValue = typeof value === "boolean" ? value : value?.toLowerCase()
  return !!data?.some((element) => {
    const updatedElement = typeof element === "boolean" ? element : element?.toLowerCase()
    return updatedValue === updatedElement
  })
}

/**
 * Filters an array of objects based on a query string and a specified key.
 *
 * @param {Object} params - The parameters for the function.
 * @param {any[]} params.data - The array of objects to be filtered.
 * @param {string} [params.query] - The query string to filter the data. If not provided, the original data is returned.
 * @param {string} [params.key="name"] - The key in the objects to match the query against. Defaults to "name".
 * @returns {any[]} The filtered array of objects where the specified key's value contains the query string.
 */
export const findData = ({ data, query, key = "name" }: { data: any; query?: string; key?: string }) => {
  if (!query) {
    return data
  }
  return data.filter((item: any) => {
    const valueToMatch = item && item[key]?.toLowerCase()
    const parsedQuery = query?.trim()?.toLowerCase()
    return valueToMatch.indexOf(parsedQuery) !== -1
  })
}

/**
 * Formats a timestamp based on the provided time interval filter.
 *
 * @param {string | number} value - The timestamp to format. It can be a string or a number.
 * @param {ChartsTimeIntervalFilterType} filter - The filter that determines the formatting style.
 *
 * @returns {string | number} - The formatted timestamp as a string, or the original value if no matching filter is found.
 *
 * The function supports the following filters and their corresponding formats:
 * - "last1Hour": Formats the timestamp as "H:mm A".
 * - "last2Hour": Formats the timestamp as "H:mm A".
 * - "last24Hours": Formats the timestamp as "h A".
 * - "today": Formats the timestamp as "h A".
 * - "last7Days": Formats the timestamp as "DD/MM/YYYY".
 * - "last30Days": Formats the timestamp as "DD/MM/YYYY".
 */
export const timestampLabelFormatting = (value: string | number, filter: ChartsTimeIntervalFilterType) => {
  if (filter === "last1Hour") {
    return dayjs(value).format("H:mm A")
  }
  if (filter === "last2Hour") {
    return dayjs(value).format("H:mm A")
  }
  if (filter === "last24Hours") {
    return dayjs(value).format("h A")
  }
  if (filter === "today") {
    return dayjs(value).format("h A")
  }
  if (filter === "last7Days") {
    return dayjs(value).format("DD/MM/YYYY")
  }
  if (filter === "last30Days") {
    return dayjs(value).format("DD/MM/YYYY")
  }

  return value
}

/**
 * Maps performance anomalies data to a chart-friendly format.
 *
 * @param data - The performance anomalies data which can be of type `AnalyticsHostsRelayNodesPerformanceAnomiliesPayloadType` or `undefined`.
 * @returns An object containing datasets for CPU and Memory performance stats, formatted for charting.
 *
 * The returned object has the following structure:
 * {
 *   datasets: [
 *     {
 *       backgroundColor: string, // Color for the CPU dataset
 *       label: string,           // Label for the CPU dataset
 *       data: Array<{           // Array of data points for CPU performance
 *         x: number,            // Timestamp in milliseconds
 *         y: number,            // CPU performance value
 *         connectorName: string, // Name of the connector
 *         instanceName: string  // Name of the instance
 *       }>
 *     },
 *     {
 *       backgroundColor: string, // Color for the Memory dataset
 *       label: string,           // Label for the Memory dataset
 *       data: Array<{           // Array of data points for Memory performance
 *         x: number,            // Timestamp in milliseconds
 *         y: number,            // Memory performance value
 *         connectorName: string, // Name of the connector
 *         instanceName: string  // Name of the instance
 *       }>
 *     }
 *   ]
 * }
 */
export const mapPerformanceAnomiliesData = (
  data: AnalyticsHostsRelayNodesPerformanceAnomiliesPayloadType | undefined,
) => {
  const cpuPoints = data?.cpuPerformanceStats?.dataPoints.map((item) => ({
    x: dayjs.utc(item.timestamp).local().valueOf(),
    y: item.value,
    connectorName: item.connectorName,
    instanceName: item.instanceName,
  }))

  const memoryPoints = data?.memoryPerformanceStats?.dataPoints.map((item) => ({
    x: dayjs.utc(item.timestamp).local().valueOf(),
    y: item.value,
    connectorName: item.connectorName,
    instanceName: item.instanceName,
  }))

  const chartData = {
    datasets: [
      { backgroundColor: "#f8b16f", label: "CPU", data: cpuPoints },
      {
        backgroundColor: "#ef817f",
        label: "Memory",
        data: memoryPoints,
      },
    ],
  }
  return chartData
}

const CONNECTION_UP_SERIES_CONFIG = {
  backgroundColor: theme.color.success.main,
  borderColor: theme.color.success.main,
  fill: "origin",
  label: "Up",
  data: [],
}

const CONNECTION_DOWN_SERIES_CONFIG = {
  backgroundColor: theme.color.error.dark,
  borderColor: theme.color.error.dark,
  fill: "origin",
  label: "Down",
  data: [],
}

const CONNECTION_PARTIALLY_DOWN_SERIES_CONFIG = {
  backgroundColor: theme.color.warning[500],
  borderColor: theme.color.warning[500],
  fill: "origin",
  label: "Partially Down",
  data: [],
}

/**
 * Maps network data to line chart types.
 *
 * @param {Object} params - The parameters for the function.
 * @param {AnalyticsConnectionPayloadTypes} [params.data] - The data to be mapped.
 * @param {ChartsTimeIntervalFilterType} params.filter - The filter to be applied.
 * @param {"Authentication" | "Network"} params.entityType - The type of entity, either "Authentication" or "Network".
 * @returns {Array} The mapped chart data.
 */
export const mapNetworkDataLineCharttypes = ({
  data,
  filter,
  entityType,
}: {
  data?: AnalyticsConnectionPayloadTypes
  filter: ChartsTimeIntervalFilterType
  entityType: "Authentication" | "Network"
}) => {
  const keysSet = new Set()
  // eslint-disable-next-line array-callback-return
  data?.dataPoints?.map((item: any) => {
    if (item) Object.keys(item?.authTypes ?? item?.authStates).map((key) => keysSet.add(key))
  })
  const keysList = [...keysSet]
  const timezoneName = dayjs.tz.guess()
  const updatedData = data?.dataPoints?.map((trg) => {
    const duplicatTrg = { ...trg }
    duplicatTrg.timestamp = parseInt(dayjs.utc(duplicatTrg.timestamp).tz(timezoneName).format("x"))
    if (duplicatTrg?.authStates) {
      duplicatTrg.authStates = checkKeys(duplicatTrg.authStates, keysList)
    }
    if (duplicatTrg?.authTypes) {
      duplicatTrg.authTypes = checkKeys(duplicatTrg.authTypes, keysList)
    }
    return duplicatTrg
  })

  const result = {
    dataPoints: updatedData,
  }

  const series = keysList.map((value, index) => {
    const color = fetchColor(index)
    const points = result?.dataPoints?.map((point) => {
      let values = Object.values(point?.authTypes ?? point?.authStates)
      let sum = values.reduce((accumulator, value) => {
        return accumulator + value
      }, 0)
      let currentValue = point?.authTypes?.[value as string] || point?.authStates?.[value as string] || 0
      let pointPercentage = (currentValue / sum) * 100
      return {
        x: point?.timestamp,
        y: pointPercentage,
        z: point?.authTypes?.[value as number] || point?.authStates?.[value as number] || 0,
      }
    })
    return {
      type: "line",
      name: value === "PAP" || value === "EAP-TTLS" ? "802.1X - EAP-TTLS (PAP)" : value,
      color:
        value === "Accepted"
          ? theme.color.forest[11]
          : value === "Disconnected"
          ? theme.color.amber[13]
          : value === "Rejected"
          ? theme.color.red[11]
          : color,
      data: points,
    }
  })

  const chartData = series

  return chartData
}

/**
 * Maps data to a format suitable for a donut chart.
 *
 * @param {Object} params - The parameters for the function.
 * @param {AuthenticationPiePayloadTypes} [params.data] - The data to be mapped.
 * @param {ChartsTimeIntervalFilterType} params.filter - The filter applied to the data.
 * @param {"Analytics" | "Application"} params.entityType - The type of entity for which the data is being mapped.
 * @returns {Array<Object>} The mapped data formatted for a donut chart.
 */
export const mapDataDonutChartTypes = ({
  data,
  filter,
  entityType,
}: {
  data?: AuthenticationPiePayloadTypes
  filter: ChartsTimeIntervalFilterType
  entityType: "Analytics" | "Application"
}) => {
  let result = Object.fromEntries(Object.entries(data!).filter((e) => e[0] !== "total"))
  let dataset = []
  let i = 0
  for (const [key, value] of Object.entries(result!)) {
    let points = {
      name:
        key === "PAP" || key === "EAP-TTLS"
          ? "802.1X - EAP-TTLS (PAP)"
          : entityType === "Application"
          ? key === "telnet"
            ? getTitleCase(key)
            : key.toUpperCase()
          : key.charAt(0).toUpperCase() + key.slice(1),
      y: value,
      color: fetchColor(i),
    }
    i++
    dataset.push(points)
  }
  let series = [{ data: dataset }]

  const chartData = series
  return chartData
}

/**
 * Ensures that all specified keys exist in the given object.
 * If a key does not exist, it is added with a default value of 0.
 *
 * @param value - The object to check and update.
 * @param key - An array of keys to ensure in the object.
 * @returns A new object with all specified keys ensured.
 */
const checkKeys = (value: any, key: any) => {
  const dupObj = { ...value }
  key.forEach((key: any) => {
    if (!dupObj[key]) {
      dupObj[key] = 0
    }
  })
  return dupObj
}

/**
 * Fetches a color from a predefined list based on the provided index.
 *
 * @param {number} index - The index of the color to fetch.
 * @returns {string} The color corresponding to the provided index.
 *
 * @remarks
 * The function returns a color from a predefined list of colors. If the index is out of bounds,
 * it will return `undefined`.
 *
 * @example
 * ```typescript
 * const color = fetchColor(0); // Returns the first color in the list
 * ```
 */
export const fetchColor = (index: number) => {
  const colors = [
    theme.color.green[13],
    theme.color.purple[9],
    theme.color.magenta[11],
    theme.color.orange[11],
    theme.color.cyan[11],
    theme.color.amber[13],
    theme.color.red[11],
    theme.color.green[11],
    theme.color.purple[11],
    theme.color.magenta[9],
    "#165DFF",
    "#20C5BB",
    "#F8B16F",
    "#8F66C2",
    theme.color.error.light,
    "#118F7A",
    "#93A4C2",
    "#BA5700",
    "#74C274",
    "#722ED1",
    "#EBCD7A",
    "#AA336A",
    "#483248",
    "#915F6D",
  ]
  return colors[index]
}
export const mapConnectionData = (
  data: AnalyticsConnectionPayloadType | undefined,
  filter: ChartsTimeIntervalFilterType,
) => {
  const parsedDataPoints: {
    [key: string]: {
      up: number
      down: number
      partiallyDown: number
      timestampMillis: number
    }
  } = {}
  const upPoints: { x: string | number; y: number; timestampMillis: number }[] = []
  const downPoints: { x: string | number; y: number; timestampMillis: number }[] = []
  const partiallyDownPoints: { x: string | number; y: number; timestampMillis: number }[] = []

  /**
   * Step: 1 --> Convert all time data into hashmap
   */
  data?.dataPoints.forEach((item) => {
    const timeStampLabel = timestampLabelFormatting(item.timestamp, filter)
    if (!parsedDataPoints[timeStampLabel]) {
      parsedDataPoints[timeStampLabel] = {
        up: 0,
        down: 0,
        partiallyDown: 0,
        timestampMillis: dayjs(item.timestamp).valueOf(),
      }
    }
    if (item.up) {
      parsedDataPoints[timeStampLabel].up += item.up
    }
    if (item.down) {
      parsedDataPoints[timeStampLabel].down += item.down
    }
    if (item.partiallyDown) {
      parsedDataPoints[timeStampLabel].partiallyDown += item.partiallyDown
    }
  })

  /**
   * Step: 2 --> Make seperate series for up, down and partiallyDown points
   */
  Object.keys(parsedDataPoints).forEach((dataKey) => {
    upPoints.push({
      x: dataKey,
      y: parsedDataPoints[dataKey].up,
      timestampMillis: parsedDataPoints[dataKey].timestampMillis,
    })
    downPoints.push({
      x: dataKey,
      y: parsedDataPoints[dataKey].down,
      timestampMillis: parsedDataPoints[dataKey].timestampMillis,
    })
    partiallyDownPoints.push({
      x: dataKey,
      y: parsedDataPoints[dataKey].partiallyDown,
      timestampMillis: parsedDataPoints[dataKey].timestampMillis,
    })
  })

  const datasets = []

  /**
   * Step: 3 --> Push into datasets and then sort them in ascending order based on the unix timestamp
   */
  if (upPoints.length > 0) {
    datasets.push({
      ...CONNECTION_UP_SERIES_CONFIG,
      data: upPoints.sort((a, b) => a.timestampMillis - b.timestampMillis),
    })
  }
  if (downPoints.length > 0) {
    datasets.push({
      ...CONNECTION_DOWN_SERIES_CONFIG,
      data: downPoints.sort((a, b) => a.timestampMillis - b.timestampMillis),
    })
  }
  if (partiallyDownPoints.length > 0) {
    datasets.push({
      ...CONNECTION_PARTIALLY_DOWN_SERIES_CONFIG,
      data: partiallyDownPoints.sort((a, b) => a.timestampMillis - b.timestampMillis),
    })
  }

  return {
    datasets,
  }
}

/**
 * Maps resource distribution information to chart data format.
 *
 * @param {ResourcesDistributionsPayloadType | undefined} data - The resource distribution payload data.
 * @returns {Object} The chart data object formatted for use with charting libraries.
 *
 * @property {Array} datasets - The datasets array containing the chart data.
 * @property {string} datasets[].label - The label for the dataset.
 * @property {Array} datasets[].data - The data points for the chart.
 * @property {string} datasets[].data[].x - The label for the data point.
 * @property {number} datasets[].data[].y - The value for the data point.
 * @property {string} datasets[].backgroundColor - The background color for the dataset.
 * @property {string} datasets[].borderColor - The border color for the dataset.
 * @property {number} datasets[].borderWidth - The border width for the dataset.
 */
export const mapResourceDistributionInfo = (data: ResourcesDistributionsPayloadType | undefined) => {
  const chartData = {
    datasets: [
      {
        label: "Count",
        data: [
          { x: "Sites", y: data?.sites?.count || 0 },
          { x: "Connectors", y: data?.hosts?.count || 0 },
          { x: "Relays", y: data?.relayNodes?.count || 0 },
          { x: "Services", y: data?.services?.count || 0 },
          { x: "Projects", y: data?.projects?.count || 0 },
        ],
        backgroundColor: "#d55c97",
        borderColor: "#d55c97",
        borderWidth: 1,
      },
    ],
  }
  return chartData
}

const DATA_USAGE_INTERVAL = {
  today: 600,
  twentyFourHr: 600,
  last24Hours: 600,
  oneHr: 60,
  twoHr: 60,
  last1Hour: 60,
  last2Hour: 60,
  last7Days: 43200,
  last30Days: 86400,
}

/**
 * Generates the start and end times for a data usage chart based on the provided filter value.
 *
 * @param {ChartsTimeIntervalFilterType} filterValue - The filter value indicating the time interval for the chart.
 * @returns {{ endTime: string, startTime: string, interval: number }} An object containing the start time, end time, and interval for the data usage chart.
 *
 * @example
 * // Returns the start and end times for the last 24 hours
 * const result = getTimeLineForDataUsageChart("last24Hours");
 * // result: { startTime: "2023-10-01 12:00:00", endTime: "2023-10-02 12:00:00", interval: 3600 }
 */
export const getTimeLineForDataUsageChart = (filterValue: ChartsTimeIntervalFilterType) => {
  let endTime = dayjs().utc().format("YYYY-MM-DD HH:mm:ss")
  let startTime = dayjs().startOf("day").utc().format("YYYY-MM-DD HH:mm:ss")
  const interval = DATA_USAGE_INTERVAL[filterValue]
  if (filterValue === "last24Hours") {
    startTime = dayjs().utc().subtract(24, "hour").format("YYYY-MM-DD HH:mm:ss")
  } else if (filterValue === "last7Days") {
    startTime = dayjs().utc().subtract(6, "day").format("YYYY-MM-DD HH:mm:ss")
  } else if (filterValue === "last30Days") {
    startTime = dayjs().utc().subtract(30, "day").format("YYYY-MM-DD HH:mm:ss")
  } else if (filterValue === "last1Hour") {
    startTime = dayjs().utc().subtract(1, "hour").format("YYYY-MM-DD HH:mm:ss")
  } else if (filterValue === "last2Hour") {
    startTime = dayjs().utc().subtract(2, "hour").format("YYYY-MM-DD HH:mm:ss")
  } else if (filterValue === "oneHr") {
    startTime = dayjs().utc().format("YYYY-MM-DD HH:mm:ss")
    endTime = dayjs().utc().add(1, "hour").format("YYYY-MM-DD HH:mm:ss")
  } else if (filterValue === "twoHr") {
    startTime = dayjs().utc().format("YYYY-MM-DD HH:mm:ss")
    endTime = dayjs().utc().add(2, "hour").format("YYYY-MM-DD HH:mm:ss")
  } else if (filterValue === "twentyFourHr") {
    startTime = dayjs().utc().format("YYYY-MM-DD HH:mm:ss")
    endTime = dayjs().utc().add(24, "hour").format("YYYY-MM-DD HH:mm:ss")
  }

  return {
    endTime,
    startTime,
    interval,
  }
}

/**
 * Calculates the percentage of a count relative to a total count.
 *
 * @param totalCount - The total count value.
 * @param count - The count value to calculate the percentage for.
 * @returns The percentage of the count relative to the total count. Returns 0 if the total count is 0.
 */
export const calculatePercentage = (totalCount: number, count: number) => {
  if (totalCount === 0) {
    return 0
  }
  return (count / totalCount) * 100
}

/**
 * Calculates the percentage of a value relative to a total and rounds it to one decimal place.
 * If the total is zero, it returns 0 to avoid division by zero.
 *
 * @param {number} total - The total value to calculate the percentage from.
 * @param {number} value - The value to calculate the percentage of.
 * @returns {string} - The calculated percentage as a string, rounded to one decimal place.
 */
export const percentCalculation = (total: number, value: number) => {
  if (total === 0) {
    return 0
  } else {
    const percentage = (value / total) * 100
    const roundedPercentage = Math.round(percentage * 10) / 10
    return roundedPercentage % 1 === 0 ? roundedPercentage.toFixed(0) : roundedPercentage.toFixed(1)
  }
}

/**
 * Converts a number of bytes into a human-readable string with the appropriate unit.
 *
 * @param {number} bytes - The number of bytes to be converted.
 * @param {number} [decimals=0] - The number of decimal places to include in the formatted string.
 * @returns {string} A string representing the number of bytes in a human-readable format with the appropriate unit.
 *
 * @example
 * ```typescript
 * formatBytes(1024); // "1 KB"
 * formatBytes(1234, 2); // "1.21 KB"
 * formatBytes(1048576); // "1 MB"
 * ```
 */
export const formatBytes = (bytes: number, decimals = 0) => {
  if (!+bytes) return "0 Bytes"
  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]
  const i = Math.floor(Math.log(bytes) / Math.log(k))
  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
}

/**
 * Constructs a query string from an array of filter objects.
 *
 * @param filters - An array of filter objects, each containing a `key` and a `value` string.
 * @returns A query string constructed from the provided filters.
 *
 * @example
 * ```typescript
 * const filters = [
 *   { key: 'name', value: 'John' },
 *   { key: 'age', value: '30' }
 * ];
 * const queryString = getFilters(filters);
 * console.log(queryString); // Output: "name=John&age=30"
 * ```
 */
export const getFilters = (filters: { key: string; value: string }[]): string => {
  let filtersString = ""
  if (!filters || !filters.length) {
    return filtersString
  }
  filters.forEach(({ key, value }, idx) => {
    const shouldAppendAnd = filters.length > idx + 1 ? "&" : ""
    if (value) filtersString += `${key}=${value}${shouldAppendAnd}`
  })

  return filtersString
}

/**
 * Returns the maximum Y-axis placement value from a given layout.
 *
 * @param {LayoutPoints[]} layout - An array of layout points, where each point contains a Y-axis value.
 * @returns {number} The maximum Y-axis value found in the layout.
 */
export const getMaxLayoutYAxisPlacement = (layout: LayoutPoints[]) => {
  const yValues = layout.map((i) => i.y)

  return Math.max(...yValues)
}

type filterType = "expired" | "aboutToExpire" | "services" | "users"

type filterResponseType = "exclude_by" | "filter_by"
export const filterOption = (type: filterType): filterResponseType => {
  switch (type) {
    case "aboutToExpire":
      return "exclude_by"
    default:
      return "filter_by"
  }
}

/**
 * Retrieves a list of service protocols based on whether the environment is agentless.
 *
 * @param {boolean} isAgentless - A flag indicating if the environment is agentless.
 * @returns {ServiceProtocolListType[]} - A list of service protocols. If the environment is agentless,
 * protocols not included in `WEB_ACCESSIBLE_PROTOCOLS` will be marked as disabled.
 */
export const getProtocolsList = (isAgentless: boolean): ServiceProtocolListType[] =>
  isAgentless
    ? APPLICATION_PROTOCOLS_LIST.map((protocol) =>
        WEB_ACCESSIBLE_PROTOCOLS.includes(protocol.value) ? protocol : { ...protocol, isDisabled: true },
      )
    : APPLICATION_PROTOCOLS_LIST

const ImageExtensions = ["jpeg", "jpg", "png", "bmp", "tif", "tiff"]

/**
 * Checks if the given file extension corresponds to an image format.
 *
 * @param {string} [fileExtension] - The file extension to check.
 * @returns {boolean} - Returns `true` if the file extension is an image format, otherwise `false`.
 */
export const isImage = (fileExtension?: string): boolean => {
  if (!fileExtension) return false
  return ImageExtensions.includes(fileExtension)
}

/**
 * Converts an array of strings or unknown values into a dropdown-compatible array by matching
 * elements with a specified key in a dropdown array and merging with extra properties.
 *
 * @template DropDownType - The type of the objects in the dropdown array.
 * @param {Array<unknown | string>} array - The array of strings or unknown values to be converted.
 * @param {Array<DropDownType>} dropdownArray - The array of dropdown objects to match against.
 * @param {keyof DropDownType} keyToCompare - The key in the dropdown objects to compare with the array elements.
 * @param {Record<string, any>} [extra={}] - Additional properties to merge into the matched dropdown objects.
 * @returns {Array<DropDownType | any>} - The resulting array of dropdown objects with merged properties.
 */
export const getStringArrayToDropdown = <DropDownType = any>(
  array: (unknown | string)[],
  dropdownArray: DropDownType[],
  keyToCompare: keyof DropDownType,
  extra: Record<string, any> = {},
): (DropDownType | any)[] =>
  array
    .map((item) => {
      const current = dropdownArray.find((obj) => obj[keyToCompare] === item)
      if (current) {
        return { ...current, ...extra }
      } else {
        return undefined
      }
    })
    .filter((el) => el !== undefined && el !== null)

/**
 * Converts a given string to title case.
 *
 * @param word - The string to be converted to title case.
 * @returns The input string with the first letter in uppercase and the rest in lowercase.
 */
export const getTitleCase = (word: string): string => {
  const [firstLetter = "", ...rest] = word
  return `${firstLetter.toUpperCase()}${rest.join("").toLowerCase()}`
}
// Format a number by adding commas to it
export const formatNumber = (number: number) => number.toLocaleString()

/**
 * Determines if there are more items in the array than the specified maximum and returns a subset of the array.
 *
 * @param {unknown[]} array - The array to check and slice.
 * @param {number} [max=5] - The maximum number of items to display. Defaults to 5.
 * @returns {{ showViewMore: boolean, items: unknown[] }} An object containing a boolean indicating if there are more items than the maximum and the sliced array of items.
 */
export const isViewMore = (array: unknown[], max: number = 5) => {
  const maxDisplayedRecord = max
  return { showViewMore: array.length - max > 0, items: array?.slice(0, maxDisplayedRecord) }
}

/**
 * Checks if an object is empty. An object is considered empty if all its properties are either
 * undefined, null, or empty objects themselves.
 *
 * @param obj - The object to check.
 * @returns `true` if the object is empty, `false` otherwise.
 */
export const isEmptyObject = (obj: Record<string, any>): boolean => {
  for (let key in obj) {
    if (obj.hasOwnProperty(key)) {
      if (typeof obj[key] === "object" && obj[key] !== null) {
        if (!isEmptyObject(obj[key])) {
          return false
        }
      } else if (obj[key] !== undefined && obj[key] !== null && obj[key].length !== 0) {
        return false
      }
    }
  }
  return true
}

/**
 * Checks if a given string represents an integer with no leading zero.
 *
 * @param {string} strNumber - The string to check.
 * @returns {boolean} - Returns true if the string represents an integer with no leading zero, otherwise false.
 */
export const isIntegerWithNoLeadingZero = (strNumber: string) => {
  if (strNumber.startsWith("0") && strNumber !== "0") {
    return false // Has leading zero
  }

  return Number.isInteger(Number(strNumber))
}

export const getXiqUrl = () => {
  return `${localStorage.getItem("xiqUrl")}/login`
}

export const getExternalApiUrl = () => {
  return localStorage.getItem("xcdUrl")
}

export const getExternalLogoutUrl = () => {
  return localStorage.getItem("xiqLogoutUrl")
}

/**
 * Checks if the provided data is considered "something" (i.e., not null, undefined, or empty).
 *
 * @param {any} data - The data to check.
 * @returns {boolean} - Returns true if the data is considered "something", otherwise false.
 */
export const isSomething = (data: any) => {
  if (data === null || data === undefined) return false

  if (typeof data === "number") return true

  if (typeof data === "string" && data.length === 0) return false

  if (typeof data === "object" && Object.keys(data).length === 0) return false

  if (Array.isArray(data) && data.length === 0) return false

  return true
}

/**
 * Capitalizes the first letter of each word in the given string.
 *
 * @param {string} str - The string to capitalize.
 * @returns {string} - The capitalized string.
 */
export const capitalize = (str: string) => {
  return str.replace(/\b\w/g, (char) => char.toUpperCase())
}

/**
 * Calculates the remaining time from the given date and duration in days, and returns a formatted string.
 *
 * @param {string} dateTime - The starting date and time in string format.
 * @param {number} durationDays - The duration in days to add to the starting date and time.
 * @returns {string} - A formatted string representing the remaining time in days, hours, and minutes.
 */
export const getEndTime = (dateTime: string, durationDays: number) => {
  const endTime = dayjs(dateTime).add(durationDays, "days")
  const now = dayjs()
  const totalMinutes = endTime.diff(now, "minute")
  const totalHours = endTime.diff(now, "hour")
  const totalDays = endTime.diff(now, "day")
  const remainingDays = totalDays
  const remainingHours = totalHours % 24
  const remainingMinutes = totalMinutes % 60
  // Messages
  const remainingDaysMessage = remainingDays > 0 ? `${remainingDays} day${remainingDays > 1 ? "s" : ""} ` : ""
  const remainingHoursMessage = remainingHours > 0 ? `${remainingHours} hour${remainingHours > 1 ? "s" : ""}` : ""
  const remainingMinutesMessage =
    remainingMinutes > 0 ? `${remainingMinutes} minute${remainingMinutes > 1 ? "s" : ""}` : ""
  return `${remainingDaysMessage} ${remainingHoursMessage} and ${remainingMinutesMessage}`
}

/**
 * Builds a query string from the provided filters and search query.
 *
 * @param {FilterModel} currentFilters - The current filters to be applied.
 * @param {string} searchQuery - The search query string.
 * @returns {string} - The constructed query string.
 */
export const buildQueryString = (currentFilters: FilterModel, searchQuery: string) => {
  const queryParams = []

  for (const filterKey in currentFilters) {
    const filter = currentFilters[filterKey]
    if (filter.filterType === "set") {
      const filterValues = encodeURIComponent(filter.values.join(","))
      let key = filterKey
      if (filterKey === "resourceName") {
        key = "host__name"
      }
      queryParams.push(`${toSnakeCase(key)}=${filterValues}`)
    }
  }
  if (searchQuery) {
    queryParams.push(`search=${searchQuery}`)
  }
  return queryParams.join("&")
}

/**
 * Converts a number or numeric string to its ordinal representation.
 *
 * @param {string | number} str - The number or numeric string to convert.
 * @returns {string} The ordinal representation of the input number.
 *
 * @example
 * ```typescript
 * numberToOrdinal(1); // "1st"
 * numberToOrdinal(2); // "2nd"
 * numberToOrdinal(3); // "3rd"
 * numberToOrdinal(4); // "4th"
 * numberToOrdinal(11); // "11th"
 * numberToOrdinal(21); // "21st"
 * numberToOrdinal("22"); // "22nd"
 * ```
 */
export const numberToOrdinal = (str: string | number) => {
  const string = str.toString()

  if (["11", "12", "13"].includes(string)) {
    return `${str}th`
  }

  const tail = string.charAt(str.toString().length - 1)
  switch (tail) {
    case "1":
      return `${str}st`
    case "2":
      return `${str}nd`
    case "3":
      return `${str}rd`
    default:
      return `${str}th`
  }
}

type EllipsesParams = {
  text?: string
  count: number
}

export const addEllipsis = ({ text = "N/A", count }: EllipsesParams): string =>
  text.length <= count ? text : text.slice(0, count - 3) + "..."

export const getTransformedMacAddress = (macAddress: string) => {
  const cleanedMac = macAddress.replace(/[-.:]/g, "")

  return cleanedMac.replace(/(.{2})(?=.)/g, "$1:")
}

export const parseSitesDropdown = (data: XIQSitesPayloadType) =>
  data.data.map(({ id, name }) => ({ label: name, value: id, id, name }))

export const parseSelectedSiteOptions = (data: XIQSitesPayloadType) => ({
  selectedOptions: data.data.map(({ id, name }) => ({ label: name, value: id, id, name })),
})

/**
 * Compares two arrays of objects to check if they contain the same elements,
 * regardless of order.
 *
 * @param {OptionType[]} arr1 - The first array to compare.
 * @param {OptionType[]} arr2 - The second array to compare.
 * @returns {boolean} - Returns `true` if both arrays contain the same elements, otherwise `false`.
 *
 * @example
 * const arr1 = [{ label: "ABC", value: "abc" }, { label: "XYZ", value: "xyz" }];
 * const arr2 = [{ label: "XYZ", value: "xyz" }, { label: "ABC", value: "abc" }];
 * console.log(areArraysEqual(arr1, arr2)); // true
 */
export const areArraysEqual = (arr1: OptionType[], arr2: OptionType[]): boolean => {
  if (arr1.length !== arr2.length) return false

  const sortedArr1 = arr1.map((item) => JSON.stringify(item)).sort()
  const sortedArr2 = arr2.map((item) => JSON.stringify(item)).sort()

  return JSON.stringify(sortedArr1) === JSON.stringify(sortedArr2)
}

export const parseDomainFromUrl = (url: string): string | null => {
  try {
    const hostname = new URL(url).hostname.replace(/^www\./, "")
    return hostname
  } catch (error) {
    console.error("Invalid URL:", error)
    return null
  }
}
