import { yupResolver } from "@hookform/resolvers/yup"
import clsx from "clsx"
import { ENHeading } from "en-react/dist/src/components/Heading"
import { ENProgress } from "en-react/dist/src/components/Progress"
import { useCallback, useEffect, useRef, useState } from "react"
import { Controller, useForm } from "react-hook-form"
import { useDispatch } from "react-redux"
import { CUSTOMER_ADMIN_DEVICES } from "src/constants"
import { PACKET_CAPTURE_RUN_TEST, STOP_PACKET_CAPTURE } from "src/constants/apiUrlsCraas"
import { networkLocationOptions } from "src/constants/dropdownsData"
import useENDropdownPaginatedApiCall from "src/hooks/useENDropdownPaginatedApiCall"
import { NetworkDevicesPayloadType } from "src/pages/Conditions/AddEditLocationCondition/AddEditLocationCondition.types"
import { getFilteredNetworkDevices } from "src/pages/NetworkDevices/NetworkDevices.service"
import { getSiteTreeData } from "src/pages/Sites/Sites.service"
import { postRequest } from "src/services"
import AppHeaderLayout from "src/shared/components/AppHeaderLayout"
import CircularLoader from "src/shared/components/CicularLoader"
import ErrorContainer from "src/shared/components/ErrorContainer"
import { EnSimpleInput } from "src/shared/components/FormComponents"
import EnSelect from "src/shared/components/FormComponents/EnSelect"
import EnTreeDropdown from "src/shared/components/FormComponents/EnTreeDropdown"
import ZtnaIcon, { IconNameType } from "src/shared/components/Icons/ZtnaIcon"
import PageHeader, { PageHeaderType } from "src/shared/components/PageHeader"
import ZtnaButton from "src/shared/components/ZtnaButton"
import { resetBreadCrumbs } from "src/store/ui/uiSlice"
import theme from "src/theme"
import { getTransformedMacAddress } from "src/utils"
import { OptionType } from "src/utils/utils.types"
import { addPacketCaptureValidation } from "src/utils/validations"
import { AccessPointsSelect, SwitchesSelect } from "./FormField"
import { downloadPacketCapture, getPacketCaptureStatus } from "./PacketCapture.service"
import { usePacketCaptureStyles } from "./PacketCapture.styles"
import { accessPointDropdown, switchesDropdown } from "./PacketCapture.utils"
import StopPacketCaptureModal from "./StopPacketCaptureModal"

export interface packetCaptureFormType {
  macAddress: string
  timeDuration: string
  networkLocation?: string
  site: string
  switches: OptionType[] | []
  accessPoints: OptionType[] | []
}

interface Entry {
  id: number
  create_time: string
  update_time: string
  org_id: number
  name: string
  unique_name: string
  type: string
  address?: string
  applications: string[]
  children: Entry[]
}
interface TreeNode {
  id?: number
  parentId?: number
  name?: string
  uniqueName?: string
  type?: string
  applications?: string[]
  address?: string
  children?: TreeNode[]
}

const PacketCapture: React.FC = (): JSX.Element | null => {
  const dispatch = useDispatch()
  const classes = usePacketCaptureStyles()
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [siteTreeData] = useState<TreeNode[]>([])
  const checkboxItemsRef = useRef([])
  const siteTreeDataRef = useRef(siteTreeData)
  const hierarchyIdRef = useRef<{ id: any; type: any }[][]>([])
  const [disableScrollDispatchEventInUpdated] = useState(false)
  const [evaluationData, setEvaluationData] = useState(false)
  const [stopPacket, setStopPacket] = useState(false)
  const [stoppingInProgress, setStoppingInProgress] = useState(false)
  const [packetCaptured, setPacketCaptured] = useState(false)
  const [clearEnabled, setClearEnabled] = useState(false)
  const [stopEnabled, setStopEnabled] = useState(false)
  const iconName = "noPacketCapture" as IconNameType

  const {
    control,
    handleSubmit,
    watch,
    getValues,
    trigger,
    setValue,
    reset,
    formState: { errors, isValid, touchedFields },
  } = useForm<packetCaptureFormType>({
    defaultValues: {
      macAddress: "",
      timeDuration: "",
      networkLocation: "",
      switches: [],
      site: "",
      accessPoints: [],
    },
    mode: "onChange",
    resolver: yupResolver(addPacketCaptureValidation),
  })

  const [error, setError] = useState("")
  const { macAddress, networkLocation, timeDuration } = watch()
  const intervalRef = useRef<any>(null)
  const runTestIntervalRef = useRef<any>(null)
  const stopPacketIntervalRef = useRef<any>(null)
  const [loading, setLoading] = useState(false)
  const [startTime, setStartTime] = useState<Date | null>(null)
  const [fiveMinutesLater, setFiveMinutesLater] = useState<Date | null>(null)

  const { isLoading: switchesDataLoading, loadOptions: loadSwitchesOptions } =
    useENDropdownPaginatedApiCall<NetworkDevicesPayloadType>({
      apiUrl: CUSTOMER_ADMIN_DEVICES("SWITCH"),
      searchParam: "keyword",
      paginationType: "page",
      pageParam: "pageNumber",
      limitParam: "pageSize",
      pageSize: 50,
      parser: switchesDropdown,
    })

  const { isLoading: accessPointsDataLoading, loadOptions: loadAccessPointsOptions } =
    useENDropdownPaginatedApiCall<NetworkDevicesPayloadType>({
      apiUrl: CUSTOMER_ADMIN_DEVICES("AP"),
      searchParam: "keyword",
      paginationType: "page",
      pageParam: "pageNumber",
      limitParam: "pageSize",
      pageSize: 50,
      parser: accessPointDropdown,
    })

  useEffect(() => {
    dispatch(resetBreadCrumbs())
  }, [])

  useEffect(() => {
    if (networkLocation !== "Switches") {
      setValue("switches", [], { shouldDirty: true, shouldValidate: true })
    }

    if (networkLocation !== "AP") {
      setValue("accessPoints", [], { shouldDirty: true, shouldValidate: true })
    }

    if (networkLocation !== "Sites") {
      setValue("site", "", { shouldDirty: true, shouldValidate: true })
    }
    trigger("networkLocation")
  }, [networkLocation])

  useEffect(() => {
    setLoading(true)
    clearInterval(stopPacketIntervalRef.current)
    stopPacketIntervalRef.current = null
    clearInterval(runTestIntervalRef.current)
    runTestIntervalRef.current = null

    setClearEnabled(true)
    const fetchStatus = async () => {
      try {
        let statusResponse = await getPacketCaptureStatus()
        setLoading(false)
        if (
          statusResponse?.status === "READY" ||
          statusResponse?.status === "IN_PROGRESS" ||
          statusResponse?.status === "STOPPING_IN_PROGRESS"
        ) {
          setValue("macAddress", statusResponse?.metadata?.macAddresses)
          setValue("timeDuration", statusResponse?.metadata?.durationMinutes)
          if (statusResponse?.metadata?.aps.length > 0) {
            setValue("networkLocation", "AP")
            setValue(
              "switches",
              statusResponse?.metadata?.aps.map((item: { ipAddress: any; id: any }) => ({
                label: item.ipAddress,
                value: `${item.id} | ${item.ipAddress}`,
                id: item.ipAddress,
                name: item.ipAddress,
              })),
            )
          } else if (statusResponse?.metadata?.sites.length > 0) {
            setValue("networkLocation", "Sites")
            hierarchyIdRef.current = statusResponse?.metadata?.sites.map(
              (item: { siteId: any; buildingId: any; floorId: any }) => {
                const ids = []
                if (item.siteId != null) ids.push({ id: item.siteId, type: "SITE" })
                if (item.buildingId != null) ids.push({ id: item.buildingId, type: "BUILDING" })
                if (item.floorId != null) ids.push({ id: item.floorId, type: "FLOOR" })

                return ids
              },
            )
          } else if (statusResponse?.metadata?.switches.length > 0) {
            setValue("networkLocation", "Switches")
            setValue(
              "switches",
              statusResponse?.metadata?.switches.map((item: { ipAddress: any; id: any }) => ({
                label: item.ipAddress,
                value: `${item.id} | ${item.ipAddress}`,
                id: item.ipAddress,
                name: item.ipAddress,
              })),
            )
          }
          setPacketCaptured(true)
          setIsSubmitting(true)
          if (statusResponse?.status === "STOPPING_IN_PROGRESS") {
            setStoppingInProgress(true)
            setStopEnabled(true)
            setIsSubmitting(false)
          } else {
            setStartTime(new Date(statusResponse?.startTime))
            setStopEnabled(true)
          }
          if (!intervalRef.current) {
            intervalRef.current = setInterval(fetchStatus, 60000)
          }
        } else if (statusResponse?.status === "COMPLETED" || statusResponse?.status === "STOPPED") {
          setValue("macAddress", statusResponse?.metadata?.macAddresses)
          setValue("timeDuration", statusResponse?.metadata?.durationMinutes)
          if (statusResponse?.metadata?.aps.length > 0) {
            setValue("networkLocation", "AP")
            setValue(
              "switches",
              statusResponse?.metadata?.aps.map((item: { ipAddress: any; id: any }) => ({
                label: item.ipAddress,
                value: `${item.id} | ${item.ipAddress}`,
                id: item.ipAddress,
                name: item.ipAddress,
              })),
            )
          } else if (statusResponse?.metadata?.sites.length > 0) {
            setValue("networkLocation", "Sites")
            hierarchyIdRef.current = statusResponse?.metadata?.sites.map(
              (item: { siteId: any; buildingId: any; floorId: any }) => {
                const ids = []
                if (item.siteId != null) ids.push({ id: item.siteId, type: "SITE" })
                if (item.buildingId != null) ids.push({ id: item.buildingId, type: "BUILDING" })
                if (item.floorId != null) ids.push({ id: item.floorId, type: "FLOOR" })

                return ids
              },
            )
          } else if (statusResponse?.metadata?.switches.length > 0) {
            setValue("networkLocation", "Switches")
            setValue(
              "switches",
              statusResponse?.metadata?.switches.map((item: { ipAddress: any; id: any }) => ({
                label: item.ipAddress,
                value: `${item.id} | ${item.ipAddress}`,
                id: item.ipAddress,
                name: item.ipAddress,
              })),
            )
          }
          setPacketCaptured(true)
          setIsSubmitting(false)
          setEvaluationData(true)
          setStopEnabled(false)
          setClearEnabled(false)
        } else {
          setPacketCaptured(false)
          setIsSubmitting(false)
          setStoppingInProgress(false)
          setStopEnabled(true)
          if (intervalRef.current) {
            clearInterval(intervalRef.current)
            intervalRef.current = null
          }
        }
      } catch (error) {
        setPacketCaptured(false)
        setIsSubmitting(false)
        setStoppingInProgress(false)
        setStopEnabled(true)
        setLoading(false)
        if (intervalRef.current) {
          clearInterval(intervalRef.current)
          intervalRef.current = null
        }
      }
    }

    fetchStatus()
    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current)
      }
    }
  }, [])

  useEffect(() => {
    if (startTime) {
      const calculatedTime = new Date(startTime.getTime() + 5 * 60 * 1000)
      setFiveMinutesLater(calculatedTime)
    }
  }, [startTime])

  useEffect(() => {
    const interval = setInterval(() => {
      let currentTime = new Date()
      let currentTimeUTC = new Date(
        currentTime.getUTCFullYear(),
        currentTime.getUTCMonth(),
        currentTime.getUTCDate(),
        currentTime.getUTCHours(),
        currentTime.getUTCMinutes(),
        currentTime.getUTCSeconds(),
      )
      if (fiveMinutesLater && fiveMinutesLater <= currentTimeUTC) {
        setStopEnabled(false)
        clearInterval(interval)
      } else {
        setStopEnabled(true)
      }
    }, 1000)
    return () => clearInterval(interval)
  }, [fiveMinutesLater])

  const getIpNetworkDevices = async (siteId: any) => {
    let ipNetworkDevicesList: any[] = []
    if (siteId !== null) {
      try {
        const result: any = await getFilteredNetworkDevices(0, 100, `siteId=${siteId}`, "")
        if (result?.data) {
          ipNetworkDevicesList = result.data.map((device: { ipAddress: any }) => device.ipAddress)
        }
      } catch (error) {
        console.error("Error fetching network devices:", error)
      }
    }
    return ipNetworkDevicesList
  }

  const fetchSiteData = async () => {
    const sites = await Promise.all(
      hierarchyIdRef.current?.map(async (sitesObj) => {
        let siteId = sitesObj.find((item) => item.type === "SITE")?.id ?? null
        let ipAddressList = siteId !== null ? await getIpNetworkDevices(siteId) : []
        return {
          site_id: siteId,
          site_name: findSiteName(siteId, "SITE"),
          building_id: sitesObj.find((item) => item.type === "BUILDING")?.id ?? null,
          building_name: findSiteName(sitesObj.find((item) => item.type === "BUILDING")?.id, "BUILDING"),
          floor_id: sitesObj.find((item) => item.type === "FLOOR")?.id ?? null,
          floor_name: findSiteName(sitesObj.find((item) => item.type === "FLOOR")?.id, "FLOOR"),
          ip_addresses: ipAddressList,
        }
      }),
    )
    return sites
  }

  const runTest = async () => {
    setError("")
    if (isValid) {
      setEvaluationData(false)
      setStopEnabled(true)
      setIsSubmitting(true)
      const selectedSwitches = getValues("switches")
      const selectedAccessPoints = getValues("accessPoints")
      let switches = selectedSwitches?.map((switchObj) => {
        const [id, ipAddress] = switchObj.value.split(" | ")
        return {
          id: parseInt(id),
          ip_address: ipAddress,
        }
      })
      let accessPoints = selectedAccessPoints?.map((apObj) => {
        const [id, ipAddress] = apObj.value.split(" | ")
        return {
          id: parseInt(id),
          ip_address: ipAddress,
        }
      })
      try {
        let sites = await fetchSiteData()
        if (networkLocation === "") {
          sites = []
          accessPoints = []
          switches = []
        }
        const payload = {
          sites: sites,
          aps: accessPoints,
          switches: switches,
          mac_addresses: macAddress ? [getTransformedMacAddress(macAddress)] : [],
          duration_minutes: timeDuration !== undefined ? parseInt(timeDuration) : 0,
        }
        await postRequest(PACKET_CAPTURE_RUN_TEST, payload)

        runTestIntervalRef.current = setInterval(async () => {
          try {
            let { status } = await getPacketCaptureStatus()
            if (status === "COMPLETED" || status === "STOPPED") {
              clearInterval(runTestIntervalRef.current)
              runTestIntervalRef.current = null
              setIsSubmitting(false)
              setEvaluationData(true)
              setPacketCaptured(true)
              setClearEnabled(false)
            } else if (status === "READY" || status === "IN_PROGRESS") {
              setStartTime(new Date(status?.startTime))
            }
          } catch (error) {
            clearInterval(runTestIntervalRef.current)
            runTestIntervalRef.current = null
            setIsSubmitting(false)
            setError("Error while checking status")
          }
        }, 60000)
      } catch (error: any) {
        setIsSubmitting(false)
        setError(error?.message)
      }
    }
  }

  const clearData = () => {
    reset()
    reset({
      macAddress: "",
      timeDuration: "",
      networkLocation: "",
      site: "",
      switches: [],
      accessPoints: [],
    })
    setEvaluationData(false)
    setClearEnabled(true)
    setStopEnabled(true)
    hierarchyIdRef.current = []
  }

  const downloadData = async () => {
    try {
      const response = await downloadPacketCapture()
      const url = URL.createObjectURL(response)
      const element = document.createElement("a")
      element.href = url
      element.download = "packet.zip"
      document.body.appendChild(element)
      element.click()
      URL.revokeObjectURL(url)
      URL.revokeObjectURL(url)
    } catch (err) {
      console.error("Download error: ", err)
    }
  }

  const pageHeaderProps: PageHeaderType = {
    title: "Policy Evaluation & Troubleshooting",
    marginBottom: 17.5,
  }

  const findSiteName = (idToFind: number | undefined, type: string) => {
    const traverse = (nodes: TreeNode[]): string | null => {
      for (const node of nodes) {
        if (node.id === idToFind && node.type === type) {
          return node.name || null
        }
        if (node.children) {
          const result: string | null = traverse(node.children)
          if (result) return result
        }
      }
      return null
    }
    return traverse(siteTreeDataRef.current)
  }

  function filterEntries(entries: Entry[]): Entry[] {
    return entries
      .map((entry) => {
        if (entry.type === "SITE") {
          return entry
        }
        const filteredChildren = filterEntries(entry.children)
        if (filteredChildren.length > 0) {
          return { ...entry, children: filteredChildren }
        }
        return undefined
      })
      .filter((entry) => entry !== undefined) as Entry[]
  }

  const transformTreeData = (nodes: any) => {
    const processNodes = (nodes: any) => {
      return nodes.map((node: any) => {
        const children = node.children && node.children.length > 0 ? processNodes(node.children) : []
        const transformedNode: any = {
          label: node.name ?? "",
          key: `${node.id}`,
          icon: "folder",
          defaultExpanded: true,
          defaultChecked: hierarchyIdRef.current.flat().some((tree: any) => tree.id === node.id),
          ...(children.length > 0 && { children }),
        }
        return transformedNode
      })
    }
    return nodes.length > 0 ? processNodes(nodes[0].children) : []
  }

  const lazyLoadingService = useCallback(
    (page: number): Promise<any[]> => {
      return new Promise(async (resolve, reject) => {
        try {
          const pageSize = 10
          const siteTreeData = await getSiteTreeData()
          const filteredData = await filterEntries(siteTreeData)
          siteTreeDataRef.current = JSON.parse(JSON.stringify(filteredData)) || []
          const transformedData = await transformTreeData(filteredData)
          const startIndex = (page - 1) * pageSize
          if (startIndex >= transformedData.length) {
            resolve([])
            return
          }
          resolve(transformedData.slice(startIndex, startIndex + pageSize))
        } catch (error) {
          reject([])
        }
      })
    },
    [checkboxItemsRef.current],
  )

  const onTreeDropdownChange = useCallback(
    (e: CustomEvent) => {
      const selectedCheckboxHierarchy = e.detail.selectedValues
      extractSelectedTreeStructureId(selectedCheckboxHierarchy)
    },
    [checkboxItemsRef.current],
  )
  const extractSelectedTreeStructureId = (selectedCheckboxHierarchy: any) => {
    hierarchyIdRef.current = []
    selectedCheckboxHierarchy.forEach((item: { hierarchy: string }) => {
      const selectedIds = item.hierarchy.split("_")
      const selectedIdType = fetchSiteType(selectedIds)
      hierarchyIdRef.current.push(selectedIdType)
    })
    const isHierarchyExist = selectedCheckboxHierarchy.length > 0 ? true : ""
    setValue("site", isHierarchyExist ? "site" : "")
    trigger("site")
  }
  const fetchSiteType = (selectedIds: any) => {
    const result: { id: any; type: any }[] = []
    function traverse(node: any) {
      if (node && node.id && selectedIds.includes(node.id.toString())) {
        result.push({ id: node.id, type: node.type })
      }
      if (node.children) {
        for (const child of node.children) {
          traverse(child)
        }
      }
    }
    for (const item of siteTreeDataRef.current) {
      traverse(item)
    }
    return result
  }
  const stopPacketCapturing = async () => {
    clearInterval(runTestIntervalRef.current)
    runTestIntervalRef.current = null

    setStopPacket(false)
    setIsSubmitting(false)
    setStoppingInProgress(true)
    setPacketCaptured(true)

    try {
      await postRequest(STOP_PACKET_CAPTURE)
      stopPacketIntervalRef.current = setInterval(async () => {
        try {
          let { status } = await getPacketCaptureStatus()
          if (status === "COMPLETED" || status === "STOPPED") {
            clearInterval(stopPacketIntervalRef.current)
            stopPacketIntervalRef.current = null
            setStoppingInProgress(false)
            setEvaluationData(true)
            setClearEnabled(false)
          }
        } catch (error) {
          clearInterval(stopPacketIntervalRef.current)
          stopPacketIntervalRef.current = null
          setError("Error while checking status")
        }
      }, 60000)
    } catch (error: any) {
      setStoppingInProgress(false)
      setClearEnabled(false)
    }
  }

  const closeModal = () => {
    setStopPacket(false)
  }
  return (
    <div className={classes.container}>
      <AppHeaderLayout
        slots={{
          pageHeader: <PageHeader noPortal title={pageHeaderProps.title} />,
        }}
      />
      <ErrorContainer
        message={error}
        open={!!error}
        handleClose={() => setError("")}
        errorClass={classes.errorContainer}
      />
      <div className={clsx(classes.paper, classes.border)}>
        {loading ? (
          <div className={classes.loader} data-testid="data-grid-loader">
            <CircularLoader size="xl" />
          </div>
        ) : (
          <>
            {packetCaptured ? (
              <form className={classes.marginBottom35}>
                <>
                  <div className={classes.hostingProviderContainer}>
                    <div className={classes.simpleInput}>
                      <EnSimpleInput
                        label="MAC Address"
                        isOptional
                        control={control}
                        placeholder="MAC Address"
                        name="macAddress"
                        error={errors.macAddress}
                        isDisabled={isSubmitting || stoppingInProgress}
                        dataTestId="input-macAddress"
                      />
                    </div>
                    <div>
                      <div className={classes.select}>
                        <Controller
                          name="networkLocation"
                          control={control}
                          render={({ field: { onChange, value } }): JSX.Element => (
                            <EnSelect
                              label="Network Location(s)"
                              options={networkLocationOptions}
                              isOptional
                              optionValue="value"
                              optionLabel="label"
                              value={value}
                              onChange={onChange}
                              disabled={isSubmitting || stoppingInProgress}
                              error={touchedFields.networkLocation ? errors.networkLocation : ""}
                            />
                          )}
                        />
                      </div>
                    </div>

                    {networkLocation === "Switches" ? (
                      <div className={classes.select}>
                        <SwitchesSelect
                          control={control}
                          error={errors?.switches?.message}
                          loadSwitchesOptions={loadSwitchesOptions}
                          switchesDataLoading={switchesDataLoading}
                          isSubmitting={isSubmitting || stoppingInProgress}
                        />
                      </div>
                    ) : networkLocation === "AP" ? (
                      <div className={classes.select}>
                        <AccessPointsSelect
                          control={control}
                          error={errors?.accessPoints?.message}
                          isSubmitting={isSubmitting || stoppingInProgress}
                          loadAccessPointsOptions={loadAccessPointsOptions}
                          accessPointsDataLoading={accessPointsDataLoading}
                        />
                      </div>
                    ) : networkLocation === "Sites" ? (
                      <div className={classes.select}>
                        <Controller
                          control={control}
                          name="site"
                          render={({ field: { onChange, value, onBlur, ref } }) => (
                            <EnTreeDropdown
                              checkboxItems={checkboxItemsRef.current}
                              dropdownPanelContainerSelector="#dropdown-block"
                              control={control}
                              enableDynamicPositioning
                              label="Sites"
                              name="sites-tree-dropdown"
                              placeholder="Select sites"
                              variant="primary"
                              enableSelectAll={false}
                              showResetButton={false}
                              showDoneButton={false}
                              enableSingleSelect={false}
                              enableLazyLoading={true}
                              lazyLoadingScrollOffset={10}
                              disableScrollDispatchEventInUpdated={disableScrollDispatchEventInUpdated}
                              lazyLoadingService={lazyLoadingService}
                              isParentInListSelectable={true}
                              onTreeDropdownChange={onTreeDropdownChange}
                              error={errors.site?.message}
                              isDisabled={isSubmitting || stoppingInProgress}
                              isOptional
                            />
                          )}
                        />
                      </div>
                    ) : null}
                    <div className={clsx(classes.simpleInput, classes.alignTime)}>
                      <EnSimpleInput
                        label="Time Duration"
                        control={control}
                        placeholder="Time Duration"
                        name="timeDuration"
                        error={errors.timeDuration}
                        isDisabled={isSubmitting || stoppingInProgress}
                        dataTestId="input-timeDuration"
                        units="Minutes"
                        fieldNote="Enter the capture duration time. Maximum is 30 minutes."
                      />
                    </div>
                  </div>
                </>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-end",
                    position: "relative",
                    bottom: 0,
                    right: 0,
                    gap: "10px",
                    left: "13rem",
                  }}
                >
                  <ZtnaButton
                    buttonType="tertiary"
                    dataTestid="show-all-button"
                    disabled={evaluationData === null || clearEnabled}
                    onClick={() => {
                      clearData()
                    }}
                    title="Clear"
                    style={{ width: 100, marginRight: 15 }}
                  />
                  <ZtnaButton
                    buttonType="secondary"
                    dataTestid="show-all-button"
                    disabled={stoppingInProgress || (!isValid && !isSubmitting) || stopEnabled}
                    onClick={() => {
                      setStopPacket(true)
                    }}
                    title="Stop"
                    style={{ width: 100, marginRight: 15 }}
                    startIcon={stoppingInProgress && <CircularLoader isDark />}
                  />
                  <ZtnaButton
                    buttonType="primary"
                    dataTestid="show-all-button"
                    onClick={() => {
                      handleSubmit(runTest)()
                    }}
                    title="Add"
                    disabled={!isValid || isSubmitting || stoppingInProgress}
                    style={{ width: 100 }}
                    startIcon={isSubmitting && <CircularLoader isDark />}
                  />
                </div>
                <div>
                  <ENProgress currentProgress={0} endProgress={isSubmitting ? 1 : 0} duration={4} />
                </div>
              </form>
            ) : (
              <div className={classes.blankSlate}>
                <div className={classes.blankSlateIcon}>
                  <ZtnaIcon
                    name={iconName}
                    width={15}
                    height={15}
                    size="xl"
                    style={{ color: theme.color.neutralLight[16] }}
                  />
                </div>
                <ENHeading isBold variant="sm">
                  No Packet Capture evaluation done yet
                </ENHeading>

                <div className={classes.blankSlateText} style={{ maxWidth: 350 }}>
                  <ZtnaButton
                    buttonType="primary"
                    dataTestid="initiate-button"
                    onClick={() => {
                      clearData()
                      setPacketCaptured(true)
                    }}
                    title="Initiate Packet Capture"
                    style={{ width: 100 }}
                  />
                </div>
              </div>
            )}
          </>
        )}
      </div>
      {evaluationData && (
        <div>
          <div
            className={clsx(
              classes.alignCenter,
              classes.mb12,
              classes.justifyBetween,
              classes.marginmtmdop,
              classes.border,
            )}
          >
            <div className={clsx(classes.dgrid)}>
              <div className={clsx(classes.result)}>
                <div className={classes.resultHeading}>Packet Capture Result</div>
                <div className={classes.packetDownload}>
                  <div className={classes.title}>Download the captured data from packet capture.</div>
                  <ZtnaButton
                    buttonType="primary"
                    dataTestid="show-all-button"
                    onClick={() => {
                      downloadData()
                    }}
                    title="Download Data"
                    style={{ width: 100, marginRight: 15 }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {stopPacket && <StopPacketCaptureModal onModalClose={closeModal} onConfirmation={stopPacketCapturing} />}
    </div>
  )
}

export default PacketCapture
